import { useCallback, useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ReactDatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css'; 
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Close from '@mui/icons-material/Close';
import axios from 'axios';
import { fileToKey } from '../../../../utils/storage';
import { useFirebase } from '../../../../context/Firebase';
import './createFile.scss';
import { AuthContext } from '../../../../context/AuthContext';

const UpdateFile = ({ file, onClose, updateCallback,closeFile }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(file?.name || '');
    const [status, setStatus] = useState(file?.status || '');
    const [access, setAccess] = useState(file?.access || []);
    // Convertir expirationDate en objet Date valide ou null
    const [expirationDate, setExpirationDate] = useState(file?.expirationDate ? new Date(file.expirationDate) : null);
    const { storage } = useFirebase();
    const { utilisateur } = useContext(AuthContext)

    useEffect(() => {
      if (file) {
        setName(file.name);
        setStatus(file.status || '');
        setAccess(file.access || []);
        // Assurez-vous de convertir expirationDate correctement
        setExpirationDate(file.expirationDate ? new Date(file.expirationDate) : null);
      }
    }, [file]);

    //Notification
    const createDocumentModificationNotification = async (userId, affaireId, documentName, documentId) => {
        const notification = {
          utilisateurId: userId,
          message: `Le document "${documentName}" a été modifié.`,
          documentId: documentId,
          type: 'Document Modifié',
          affaireId: affaireId, // ID de l'affaire
        };
        try {
          await axios.post('/notifications', notification);
          console.log("Notification de modification de document créée avec succès.");
        } catch (error) {
          console.error("Erreur lors de la création de la notification de modification de document", error);
        }
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
      
        // Préparation des données du fichier à mettre à jour
        const fileUpdateData = {
          name: name || file.name,
          url: await fileToKey([file], storage),
          type: file.type,
          folder: file.folder,
          expirationDate,
          access,
          status: status || undefined,
          parent: file.parent,
          affaire: file.affaire,
          tacheId: file.tacheId
        };
      
        // Mettre à jour les informations du fichier
        axios.put(`/files/${file._id}`, fileUpdateData).then(async (res) => {
          updateCallback(res.data);
          onClose();
          closeFile();
      
          // Gérer la création ou la mise à jour de la tâche associée à l'expiration
          if (expirationDate) {
            if (file.tacheId) {
              // La tâche existe déjà, la mettre à jour
              await axios.put(`/taches/${file.tacheId}`, {
                dateEcheance: expirationDate,
                dateDepart: expirationDate,
              });
            } else {
              // Aucune tâche existante, créer une nouvelle tâche
              const newTaskResponse = await axios.post('/taches', {
                affaireId: file.affaire,
                nomTache: name || file.name,
                dateEcheance: expirationDate,
                dateDepart: expirationDate,
                duration: 1,
                typeTache: 'Document',
              });
              const newTaskId = newTaskResponse.data._id;
              const affaireId = file.affaire;
              //mettre a jour l'affaire 
              const affaireResponse = await axios.get(`/affaires/${affaireId}`);
              const currentAffaire = affaireResponse.data;
              currentAffaire.taches.push(newTaskId);
              await axios.put(`/affaires/${affaireId}`, currentAffaire);
              // Mettre à jour le fichier avec l'ID de la nouvelle tâche
              await axios.put(`/files/${file._id}`, {
                tacheId: newTaskId
              });
            }
          } else if (file.tacheId) {
            // La date d'expiration a été supprimée, gérer la tâche existante
            await axios.delete(`/taches/${file.tacheId}`);
            // Optionnellement, supprimer l'ID de tâche du fichier
            await axios.put(`/files/${file._id}`, {
              tacheId: null
            });
          }
      
          // Créer une notification de modification de document
          createDocumentModificationNotification(utilisateur._id, file.affaire, name, file._id);
        }).catch(err => {
          console.error('An error occurred, please try again', err);
        }).finally(() => {
          setIsLoading(false);
        });
      };

  const handleAccessChange = (event) => {
    const valueArray = event.target.value;
    // Assurer que "ADMIN" est toujours inclus dans les valeurs sélectionnées
    if (!valueArray.includes('ADMIN')) {
        setAccess(['ADMIN', ...valueArray]);
    } else {
        setAccess(valueArray);
    }
};

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true}   PaperProps={{
        style: { height: "500px" }, // Ajuste la hauteur à 80% de la hauteur de la fenêtre
      }}>
      <DialogTitle>Modifier le fichier</DialogTitle>
      <Close onClick={onClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nom du fichier*"
          fullWidth
          variant="outlined"
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <div className="info">
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={status}
              onChange={e => setStatus(e.target.value)}
              input={<OutlinedInput label="Status" />}
            >
              <MenuItem value='WAITING'>En attente</MenuItem>
              <MenuItem value='IN_PROGRESS'>En cours</MenuItem>
              <MenuItem value='VALIDATED'>Validé</MenuItem>
              <MenuItem value='CONFIRMED'>Confirmé</MenuItem>
              <MenuItem value='ARCHIVED'>Archivé</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <ReactDatePicker
              selected={expirationDate}
              onChange={(date) => setExpirationDate(date)}
              dateFormat="dd/MM/yyyy"
              locale={fr}
              customInput={<TextField label="Date d'expiration" />}
            />
          </FormControl>
        </div>
        <div className="info">
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <InputLabel id="access-label">Accès</InputLabel>
            <Select
              labelId="access-label"
              value={access}
              onChange={handleAccessChange}  // Utiliser la nouvelle fonction de manipulation
              multiple
              input={<OutlinedInput label="Accès" />}
            >
              <MenuItem value="ADMIN" disabled>Admin</MenuItem>  // Rendre l'option Admin désactivée pour éviter la confusion
              <MenuItem value="OUVRIER_TERRAIN">Ouvrier Terrain</MenuItem>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : 'Valider les modifications'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateFile;