import './nomTacheMessage.scss'

const NomTacheMessage = ({ tache, setTache }) => {
  const handleChange = (e) => {
    setTache({ ...tache, [e.target.name]: e.target.value })
  }
  return (
    <div className="nomtachemessage">
    <span className="span-nomtachemessage">Titre/Objet :</span>
      <input
    name="nomTache"
    value={tache.nomTache}
    onChange={handleChange}
    className="input-nomtachemessage"
  /></div>
  )
}

export default NomTacheMessage
