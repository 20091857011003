import { useState } from 'react'
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Scatter } from 'recharts'

import './graphTachesParAffaire.scss'

const GraphTachesParAffaire = ({ affaireData }) => {
  const [startIndex, setStartIndex] = useState(0)
  const maxEntries = 8 // Nombre maximal d'entrées à afficher

  // Fonction pour préparer les données pour le graphique
  const prepareDataForChart = () => {
    if (affaireData && Array.isArray(affaireData.taches)) {
      // Trier les tâches par date de début réelle
      const sortedTaches = affaireData.taches.sort((a, b) =>
        new Date(a.dateDepartReelle) - new Date(b.dateDepartReelle)
      )
      // Limiter les tâches affichées en fonction de startIndex et maxEntries
      const paginatedTaches = sortedTaches.slice(startIndex, startIndex + maxEntries)

      return paginatedTaches.map((tache) => {
        // Calculer la durée réelle
        const dureeReelle = tache.chronometres.length

        // Calculer le point de comparaison
        const debut = new Date(tache.dateDepartReelle)
        const fin = new Date(tache.dateEcheanceReelle)
        const dureeTotale = fin.getTime() - debut.getTime()
        const tempsEcoule = dureeReelle * 24 * 60 * 60 * 1000 // Convertir les jours en millisecondes
        const progressionAttendue = (tempsEcoule / dureeTotale) * 100
        const pointComparaisonReel = tache.pourcentageRealisation - progressionAttendue
        let pointComparaisonAffiche

        // Si pointComparaisonReel n'est pas NaN, calculer pointComparaisonAffiche
        if (!isNaN(pointComparaisonReel)) {
          pointComparaisonAffiche = Math.max(pointComparaisonReel, 0)
        }

        return {
          nomTache: tache.nomTache,
          dureePlanifiee: tache.duration,
          dureeReelle,
          pourcentageRealisation: tache.pourcentageRealisation,
          pointComparaisonReel: isNaN(pointComparaisonReel) ? 0 : pointComparaisonReel, // Valeur réelle
          pointComparaisonAffiche
        }
      })
    } else {
      return []
    }
  }

  const data = prepareDataForChart()

  // limite les entrées
  // Fonctions pour la pagination
  const handlePrevious = () => {
    setStartIndex(Math.max(startIndex - maxEntries, 0))
  }

  const handleNext = () => {
    if (affaireData && affaireData.taches) {
      setStartIndex(Math.min(startIndex + maxEntries, affaireData.taches.length - maxEntries))
    }
  }

  console.log(data)
  return (

<div className="GraphTachesParAffaire">
    <div className="title-gtpa">
        <h2>Graphique des Tâches par Affaire</h2>
    </div>
    <div className="btn-suivant-prec-gtpa">
    <button onClick={handlePrevious} disabled={startIndex === 0}>Précédent</button>
    <button onClick={handleNext} disabled={affaireData && affaireData.taches ? startIndex + maxEntries >= affaireData.taches.length : true}>Suivant</button>
    </div>
    <div className="graph-gpta">
        <ComposedChart width={1000} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="nomTache" />
            <YAxis yAxisId="left" orientation="left" />
            <YAxis yAxisId="right" orientation="right" domain={[0, 100]} />
            <Tooltip className="custom-tooltip-gpta" content={<CustomTooltip />} />
            <Legend content={<CustomLegend />}/>
            <Bar yAxisId="left" dataKey="dureePlanifiee" fill="#8884d8" name="Durée Planifiée" className='duree-planif-gtpa'/>
            <Bar yAxisId="left" dataKey="dureeReelle" fill="#82ca9d" name="Durée Réelle" className='duree-reel-gtpa'/>
            <Bar yAxisId="right" dataKey="pourcentageRealisation" fill="#ffc658" name="Pourcentage Réalisation" className='pourcentage-reali-gtpa' />
            <Scatter yAxisId="right" dataKey="pointComparaisonAffiche" fill="red" name="Point de Comparaison Réel" className='point-compar-reel-gtpa'/>
            </ComposedChart>
            </div>
            </div>
  )
}
export default GraphTachesParAffaire

const CustomLegend = () => {
  return (
      <ul className="custom-legend-gtpa">
        <li className='duree-planif-gtpa'>Durée Planifiée</li>
        <li className='duree-reel-gtpa'>Durée Réelle</li>
        <li className='pourcentage-reali-gtpa'>Pourcentage de Réalisation</li>
        <li className='point-compar-reel-gtpa'>Point de Comparaison Réel</li>
      </ul>
  )
}

// Composant CustomTooltip
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload // Données actuelles
    return (
        <div className="custom-tooltip-gtpa">
          <p className='Nom-tache-gtpa'>Nom de la Tâche: {data.nomTache}</p>
          <p className='duree-planif-gtpa'>Durée Planifiée: {data.dureePlanifiee} jours</p>
          <p className='duree-reel-gtpa'>Durée Réelle: {data.dureeReelle} jours</p>
          <p className='pourcentage-reali-gtpa'>Pourcentage de Réalisation: {data.pourcentageRealisation}%</p>
          <p className='point-compar-reel-gtpa'>Point de Comparaison Réel: {data.pointComparaisonReel}%</p>
        </div>
    )
  }
  return null
}
