import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './affectationUtilisateur.scss';

const AffectationUtilisateur = ({ affaireId, selectedTache, date, onAffectationComplete }) => {
  // Initialisez avec les données existantes si disponibles
  const [applyToAllFutureDates, setApplyToAllFutureDates] = useState(false);
  const [selectedChef, setSelectedChef] = useState(null);
  const [selectedOuvriers, setSelectedOuvriers] = useState([]);
  const [chefOptions, setChefOptions] = useState([]);
  const [ouvrierOptions, setOuvrierOptions] = useState([]);
  const [repeatDays, setRepeatDays] = useState(0);
  const [showMiddle, setShowMiddle] = useState(false);
  // Détectez si vous êtes en mode édition basé sur l'existence d'affectations
  const isEditing = selectedTache && selectedTache.affectationsDuJour && selectedTache.affectationsDuJour.length > 0;
  
  useEffect(() => {
    const fetchUtilisateurs = async () => {
      const response = await axios.get('/utilisateurs');
      const data = response.data;

      const formatOption = (user) => ({
        value: user._id,
        label: `${user.nom} ${user.prenom}`,
        isChef: user.chefAssignes,
        isOuvrier: user.ouvrierTerrain || user.chefAssignes,
      });

      const chefsOptions = data.filter(user => user.chefAssignes).map(formatOption);
      let ouvriersOptions = data.filter(user => user.ouvrierTerrain || user.chefAssignes).map(formatOption);

      setChefOptions(chefsOptions);
      setOuvrierOptions(ouvriersOptions);

      // Initialisez le mode édition avec les données existantes
      if (isEditing) {
        const existingAffectations = selectedTache.affectationsDuJour[0]; // Prenez la première pour l'exemple
        const existingChefs = existingAffectations.chefs.map(chef => ({ value: chef.utilisateur._id, label: `${chef.utilisateur.nom} ${chef.utilisateur.prenom}` }));
        const existingOuvriers = existingAffectations.ouvriers.map(ouvrier => ({ value: ouvrier.utilisateur._id, label: `${ouvrier.utilisateur.nom} ${ouvrier.utilisateur.prenom}` }));

        setSelectedChef(existingChefs.length > 0 ? existingChefs[0] : null); // Supposons un seul chef pour simplifier
        setSelectedOuvriers(existingOuvriers);
      }
    };
    
    fetchUtilisateurs();
  }, [isEditing, selectedTache]);

//Bouton valider 
  const handleAffectation = async () => {

    const chefs = selectedChef ? [{ utilisateur: selectedChef.value, heures: 0 }] : [];
    const ouvriers = selectedOuvriers.map(ouvrier => ({ utilisateur: ouvrier.value, heures: 0 }));
    // Lors de l'envoi de la date à l'API
    const dateUTC = new Date(date).toISOString();

    // Vérifiez s'il y a un chef sélectionné, si des ouvriers sont sélectionnés sans chef
      if (!selectedChef && ouvriers.length > 0) {
        alert("Veuillez sélectionner au moins un chef d'équipe pour l'affectation.");
        return; // Ne poursuivez pas sans chef si des ouvriers sont sélectionnés
      }

          // Vérifiez si le chef est inclus dans la liste des ouvriers sélectionnés
      if (selectedChef && ouvriers.some(ouvrier => ouvrier.utilisateur === selectedChef.value)) {
        alert("Le chef d'équipe sélectionné ne peut pas être inclus dans la liste des ouvriers. Veuillez le retirer de la liste des ouvriers ou choisir un autre chef.");
        return; // Ne poursuivez pas si le chef est inclus dans la liste des ouvriers
      }
  // Vérification pour s'assurer que les options ne sont pas choisies simultanément
  if (applyToAllFutureDates && repeatDays > 0) {
    alert("Vous ne pouvez pas choisir 'Appliquer à tous les jours suivants' tout en spécifiant un nombre spécifique de jours. Veuillez choisir l'une des deux options.");
    return; // Arrêt de la fonction si les deux sont sélectionnés
  }
    const data = {
     date: dateUTC,
      applyToAllFutureDates,
      repeatDays,
      chefs,
      ouvriers
    };
  
    console.log("data", data);
    // Utilisez l'ID de la tâche pour construire l'URL.
    const url = `/taches/${selectedTache._id}/affectations`;
  
    try {
      const response = await axios.put(url, data);
      console.log(response.data);
      setShowMiddle(false);
      onAffectationComplete(); // Callback pour mettre à jour l'UI après une affectation réussie.
    } catch (error) {
      alert('Erreur lors de l\'affectation');
      console.error(error);
    }
  };

  // Gérez les sélections de l'utilisateur

const handleChefChange = (selectedOption) => {
  setSelectedChef(selectedOption);
  // Filtrer le chef sélectionné des ouvriers, s'il y est
  if (selectedOption) {
    const filteredOuvriers = selectedOuvriers.filter(option => option.value !== selectedOption.value);
    setSelectedOuvriers(filteredOuvriers);
  }
};

const handleOuvrierChange = (selectedOptions) => {
  setSelectedOuvriers(selectedOptions);
  // Si le chef actuellement sélectionné est maintenant dans les ouvriers, le désélectionner
  if (selectedChef && selectedOptions.find(option => option.value === selectedChef.value)) {
    setSelectedChef(null);
  }
};

// Réinitialiser les sélections

const resetSelections = () => {
  setSelectedChef(null);
  setSelectedOuvriers([]);
  setApplyToAllFutureDates(false);
};

  return (
    <div className="affectation-utilisateur">
      <h2>Affectation des utilisateurs à la tâche :
        <br />
         {selectedTache.nomTache}</h2>
      <div className='head-affectation-utilisateur'>
        <label>Date d'affectation a partir du</label>
        <input type="date" value={date} disabled={true} />
        <button onClick={resetSelections}>Réinitialiser les champs</button>

      </div>
      <button onClick={() => setShowMiddle(!showMiddle)}>Dupliquer cette affectation</button>
      {showMiddle && (
        <div className='middle-affectation-utilisateur'>
          <span>Choisissez de prolonger cette affectation jusqu'à la fin de la tache ou un nombre de jours spécifique. </span>
          <div className="m-middle-affectation-utilisateur">
            <input
                type="checkbox"
                checked={applyToAllFutureDates}
                onChange={() => setApplyToAllFutureDates(!applyToAllFutureDates)}
              />
            <label>Appliquer cette configuration à tous les jours ouvrables suivants</label>
          </div>
          <div className="m-middle-affectation-utilisateur">
            <input
                className='number-affectation-utilisateur'
                type="number"
                min="0"
                value={repeatDays}
                onChange={e => setRepeatDays(Math.max(1, parseInt(e.target.value) || 1))}
              />
            <label>Dupliquer pour un nombre spécifique de jours ouvrables</label>
          </div>
        </div>
      )}
     
      <div className='select-affectation-utilisateur'>
        <label>Chef d'équipe :</label>
        <Select
        options={chefOptions}
        value={selectedChef}
        onChange={handleChefChange}
        placeholder="Sélectionner un chef"
        styles={{
          container: base => ({ ...base, width: '100%' }),
          menu: provided => ({ ...provided, zIndex: 9999 }), // Augmente le z-index ici également
          menuList: (base) => ({ ...base, maxHeight: 120 })
        }}
      />
        <label>Ouvriers :</label>
        <Select
        options={ouvrierOptions}
        isMulti
        value={selectedOuvriers}
        onChange={handleOuvrierChange}
        placeholder="Sélectionner les ouvriers"
        closeMenuOnSelect={false}
        styles={{
          container: base => ({ ...base, width: '100%' }),
          menu: provided => ({ ...provided, zIndex: 9999 }), // Augmente le z-index ici également
          menuList: (base) => ({ ...base, maxHeight: 120 })
        }}
      />
      </div>
      <button onClick={handleAffectation}>Enregistrer l'affectation</button>
    </div>
  );
};

export default AffectationUtilisateur;