import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import AffaireRow from '../../components/affaireRow/AffaireRow'
import Modal from '../../components/modules/Modal/Modal'
import useModal from '../../components/modules/Modal/useModal'
import './affaires.scss'
import { Snackbar } from '@mui/material'
import useFetch from '../../hooks/useFetch'
import useSnackbar from '../../hooks/useSnackbar'
import AffaireForm from '../../components/affaireForm/AffaireForm'
import AffichageAffaireTache from '../../components/widget/AffichageAffaireTache/AffichageAffaireTache'
import { AuthContext } from '../../context/AuthContext'

const Affaires = () => {
  const location = useLocation()
  const { isModalOpen, openModal, closeModal } = useModal()
  const { open, message, showSnackbar, hideSnackbar } = useSnackbar()
  const { data: affaires, loading, error, reFetch } = useFetch('/affaires')
  const path = location.pathname.split('/')[1]
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedDetails, setSelectedDetails] = useState(null)
  const { utilisateur } = useContext(AuthContext)



  // Notification pour la creation d'une affaire
  const createNotification = async (userId, prenom,affaireNom , affaireId) => {
    const notification = {
      utilisateurId: userId, // L'utilisateur qui a créé la notification
      message: `${prenom} a créé une nouvelle affaire : ${affaireNom}`, // Le message de la notification
      affaireId: affaireId,
      type: 'Création Affaire', // Le type de la notification
    };
    try {
      await axios.post('/notifications', notification);
      console.log("Notification créée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la création de la notification", error);
    }
  };

  // Submit du formulaire
  const handleFormSubmit = async (newAffaire) => {
 
    const entrepriseId = utilisateur.entreprise.id;
    try {
      const affaireAvecEntreprise = {
        ...newAffaire,
        entrepriseUtilisatrice: entrepriseId,
      };
      // Capturez la réponse de la création de l'affaire
      const response = await axios.post('/affaires', affaireAvecEntreprise);
      // Utilisez la réponse pour obtenir l'affaire créée
      const createdAffaire = response.data;
      
      await reFetch();
      closeModal();
      showSnackbar('Affaire créée avec succès');
  
      // Créer une notification avec l'affaire créée
      await createNotification(utilisateur._id,utilisateur.prenom, createdAffaire.nomAffaire, createdAffaire._id);
    } catch (error) {
      console.error("Erreur lors de la création de l'Affaire", error);
      showSnackbar("Erreur lors de la création de l'Affaire");
    }
  };
  
  // recherche affaire
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  // Affichage des affaires
  const renderCardList = (status) => {
    const filteredAffaires = affaires
      .filter((affaire) => affaire.statut === status)
      .filter((affaire) => {
        return (
          affaire.nomAffaire.toLowerCase().includes(searchTerm.toLowerCase()) ||
          affaire.ville.toLowerCase().includes(searchTerm.toLowerCase()) ||
          affaire.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
    return (
      <table className="affaire-table">
        <tbody>
          {filteredAffaires.map((item) => (
            <AffaireRow
              key={item._id}
              id={item._id}
              nomAffaire={item.nomAffaire}
              ville={item.ville}
              refAffaire={item.refAffaire}
              description={item.description}
              imgAffaire={item.imgAffaire}
              onClick={() => (window.location.href = `/${path}/${item._id}`)}
              affaireData={item}
              onDetailClick={() => handleDetailClick(item)}
            />
          ))}
        </tbody>
      </table>
    )
  }

  // Affichage detail affaire
  const handleDetailClick = (details) => {
    setSelectedDetails(details)
  }
  const resetDetails = () => {
    setSelectedDetails(null)
  }

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur: {error.message}</div>

  const statusSections = [
    'création',
    'en préparation',
    'en cours',
    'terminé',
    'archivé'
  ]

  return (
    <div className="affaires">
      <div className="container-affaires">
        <div className="header-affaires">
          <button className='btn-header-affaires' onClick={(openModal)}>creer une affaire</button>
          <span>Rechercher Affaire</span> <input type="text" onChange={handleSearchChange} placeholder="Rechercher..."/>
        </div>
        <div className="containerAffaires">

          <div className="leftcontainerAffaires">
            <Modal
              title="Créer Nouvel Affaire"
              content={
                <AffaireForm onSubmit={handleFormSubmit} isEdit={false} />
              }
              isOpen={isModalOpen}
              onClose={closeModal}
            />
            <div className="headercontainerAffaires">
              {statusSections.map((status) => (
                <h3
                  key={status}
                  onClick={() =>
                    document.getElementById(status).scrollIntoView()
                  }
                >
                  {status}
                </h3>
              ))}
            </div>
            <div className="listcontainerAffaires">
              {statusSections.map((status) => (
                <div id={status} key={status} className="statusSection">
                  <h2 className="status-section-h2">{status}</h2>
                  <div className="carditem">{renderCardList(status)}</div>
                </div>
              ))}
            </div>
          </div>
          {selectedDetails && (
            <div className="right">
              <AffichageAffaireTache
                details={selectedDetails}
                onReset={resetDetails}
              />
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hideSnackbar}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  )
}

export default Affaires
