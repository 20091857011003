import { useState, useEffect } from 'react';
import axios from 'axios';
import useFetch from "../../../hooks/useFetch";
import './elementId.scss';
import { getHours, format, addHours, startOfDay } from 'date-fns';

// Supposons que elementId soit passé en tant que prop au composant
const ElementId = ({ elementId, closeModal, reFetch}) => {
    const { data: element, loading } = useFetch(`/elements/${elementId}`);
    const [formData, setFormData] = useState({
        nom: '',
        description: '',
        reference: '',
        etatRealisation: 'Non Commencé',
        quantite: 0,
        statut: 'Non Pris',
        statutLieu: 'Au dépot',
        statutAction: 'Non Vu',
        reminderDate: null,
        reminderHour: null
    });

    useEffect(() => {
        if(element) {
            setFormData({
                ...element,
                reminderDate: element.reminderDate ? format(new Date(element.reminderDate), 'yyyy-MM-dd') : null,
                reminderHour: element.reminderDate ? getHours(new Date(element.reminderDate)) : null
            });
        }
    }, [element]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`/elements/${elementId}`, {
                ...formData,
                reminderDate: formData.reminderDate ? addHours(startOfDay(new Date(formData.reminderDate)), formData.reminderHour || 8) : null
            });
            console.log('Modification réussie', response.data);
            closeModal();
            reFetch();
        } catch (error) {
            console.error('Erreur lors de la modification de l\'élément', error);
        }
    }

    if(loading) return <div>Chargement...</div>;

    return (
        <div className="elementId">
        <form 
        onSubmit={handleSubmit}>
            <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                placeholder="Nom"
            />
            <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
            />
            <input
                type="text"
                name="reference"
                value={formData.reference}
                onChange={handleChange}
                placeholder="Référence"
            />
            <select name="etatRealisation" value={formData.etatRealisation} onChange={handleChange}>
                <option value="Non Commencé">Non Commencé</option>
                <option value="En Cours">En Cours</option>
                <option value="Demande Informations Complementaire">Demande Informations Complémentaire</option>
                <option value="Terminé">Terminé</option>
            </select>
            <input
                type="number"
                name="quantite"
                value={formData.quantite}
                onChange={handleChange}
                placeholder="Quantité"
            />
            <select name="statut" value={formData.statut} onChange={handleChange}>
                <option value="Non Pris">Non Pris</option>
                <option value="Pris">Pris</option>
                <option value="Plus Besoin">Plus Besoin</option>
                <option value="A Recharger">A Recharger</option>
                <option value="il y a plus de stock">Il y a plus de stock</option>
            </select>
            <select name="statutLieu" value={formData.statutLieu} onChange={handleChange}>
                <option value="Au dépot">Au dépot</option>
                <option value="Fournisseur">Fournisseur</option>
                <option value="Livré sur chantier">Livré sur chantier</option>
                <option value="A Recharger">A Recharger</option>
            </select>
            <select name="statutAction" value={formData.statutAction} onChange={handleChange}>
                <option value="Non Vu">Non Vu</option>
                <option value="Vu">Vu</option>
            </select>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <input
                style={{flex: 1}}
                type="date"
                name="reminderDate"
                value={formData.reminderDate}
                onChange={handleChange}
                placeholder="Date de rappel"
                />
                <input
                style={{width: '20%'}}
                type="number"
                min={0}
                pattern="[0-9]"
                max={23}
                name="reminderHour"
                value={formData.reminderHour}
                onChange={handleChange}
                placeholder="Heure de rappel"
                />
            </div>
            <button type="submit">Valider les modifications</button>
        </form>
        </div>
    );
}

export default ElementId;