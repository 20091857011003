import { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'
import './affaireassignation.scss'

const Affaireassignation = ({ affairedata }) => {
  const [users, setUsers] = useState([])
  const [selectedTeamLead, setSelectedTeamLead] = useState([])
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
  const [teamMembers] = useState([])
  const [teamBureau, setTeamBureau] = useState([])
  const [teamConduc, setTeamConduc] = useState([])
  // const [selectedTask, setSelectedTask] = useState(null)

  useEffect(() => {
    // Récupérer la liste des utilisateurs
    axios.get('/utilisateurs').then((response) => {
      setUsers(response.data)

      // Convertir les ID de chefs d'équipe en objets pour le Select
      const initialTeamLead = affairedata.chefAssignes
        .map((id) => response.data.find((user) => user._id === id))
        .filter((user) => user) // Filtrer les utilisateurs non trouvés
        .map((user) => ({
          value: user._id,
          label: `${user.nom} ${user.prenom}`
        }))

      // Convertir les ID de chefs d'équipe en objets pour le Select
      const initialTeamMembers = affairedata.ouvrierTerrainAssignes
        .map((id) => response.data.find((user) => user._id === id))
        .filter((user) => user) // Filtrer les utilisateurs non trouvés
        .map((user) => ({
          value: user._id,
          label: `${user.nom} ${user.prenom}`
        }))

      // Convertir les ID des membres de l'équipe en objets pour le Select
      const initialTeamBureau = affairedata.bureauAssignes
        .map((id) => response.data.find((user) => user._id === id))
        .filter((user) => user) // Filtrer les utilisateurs non trouvés
        .map((user) => ({
          value: user._id,
          label: `${user.nom} ${user.prenom}`
        }))

      // Convertir les ID des membres de l'équipe en objets pour le Select
      const initialTeamConduc = affairedata.conducAssignes
        .map((id) => response.data.find((user) => user._id === id))
        .filter((user) => user) // Filtrer les utilisateurs non trouvés
        .map((user) => ({
          value: user._id,
          label: `${user.nom} ${user.prenom}`
        }))

      setSelectedTeamLead(initialTeamLead)
      setSelectedTeamMembers(initialTeamMembers)
      setTeamBureau(initialTeamBureau)
      setTeamConduc(initialTeamConduc)
    })
  }, [
    affairedata.chefAssignes,
    affairedata.ouvrierTerrainAssignes,
    affairedata.bureauAssignes,
    affairedata.conducAssignes
  ])

  // filtres pour les liste conduc et ouvrier terrain
  const handleTeamLeadChange = (selectedOptions) => {
    setSelectedTeamLead(selectedOptions)
    updateOptionsAfterTeamLeadChange(selectedOptions)
  }

  const handleTeamMembersChange = (selectedOptions) => {
    const existingLeadIds = selectedTeamLead.map((lead) => lead.value)
    const newSelectedOptions = selectedOptions.filter(
      (option) => !existingLeadIds.includes(option.value)
    )

    if (newSelectedOptions.length !== selectedOptions.length) {
      alert(
        "Un utilisateur ne peut pas être à la fois chef d'équipe et membre de l'équipe. Veuillez d'abord retirer l'utilisateur de l'autre liste."
      )
      setSelectedTeamMembers(newSelectedOptions)
    } else {
      setSelectedTeamMembers(selectedOptions)
    }
  }

  const updateOptionsAfterTeamLeadChange = (selectedTeamLeads) => {
    const selectedLeadIds = selectedTeamLeads.map((option) => option.value)
    setSelectedTeamMembers((prevMembers) =>
      prevMembers.filter((member) => !selectedLeadIds.includes(member.value))
    )
  }

  // const handleTaskChange = (selectedOption) => {
  //   setSelectedTask(selectedOption)
  // }

  const isIdPresent = (array, id) => {
    return array.some((item) => item.value === id)
  }

  const assignTeam = async () => {
    try {
      // Vérifier si des ID sont en double entre les deux listes
      const doubleIds = selectedTeamLead.some((lead) =>
        isIdPresent(teamMembers, lead.value)
      )

      if (doubleIds) {
        alert(
          "Un utilisateur ne peut pas être à la fois chef d'équipe et membre de l'équipe."
        )
        return
      }

      // Extraire uniquement les ID des utilisateurs sélectionnés
      const chefAssignesIds = selectedTeamLead.map((lead) => lead.value)
      const ouvrierTerrainAssignesIds = selectedTeamMembers.map(
        (member) => member.value
      )

      const teamData = {
        chefAssignes: chefAssignesIds,
        ouvrierTerrainAssignes: ouvrierTerrainAssignesIds,
        bureauAssignes: teamBureau.map((member) => member.value),
        conducAssignes: teamConduc.map((member) => member.value)
      }

      // Envoyer les données d'affectation au serveur
      await axios.put(`/affaires/${affairedata._id}`, teamData)
      alert('Affectation réussie')
    } catch (error) {
      console.error("Erreur lors de l'affectation de l'équipe", error)
      alert("Erreur lors de l'affectation de l'équipe")
    }
  }

  console.log('1', selectedTeamLead)
  console.log('2', selectedTeamMembers)

  return (
    <div className="assignmentContainer">
      <div className="selectGroup">
        <h3>{"Chefs d'équipe :"}</h3>
        <Select
          options={users
            .filter((user) => user.chefAssignes)
            .map((user) => ({
              value: user._id,
              label: `${user.nom} ${user.prenom}`
            }))}
          onChange={handleTeamLeadChange}
          value={selectedTeamLead}
          placeholder="Sélectionner le ou les chef(s) d'équipe"
          isMulti
          closeMenuOnSelect={false}
        />
      </div>

      <div className="selectGroup">
        <h3>{"Membres d'équipe :"}</h3>
        <Select
          options={users
            .filter((user) => user.ouvrierTerrain)
            .map((user) => ({
              value: user._id,
              label: `${user.nom} ${user.prenom}`
            }))}
          onChange={handleTeamMembersChange}
          value={selectedTeamMembers}
          placeholder="Sélectionner le ou les membres d'équipe"
          isMulti
          closeMenuOnSelect={false}
        />
      </div>

      <div className="selectGroup">
        <h3>Personnel de bureau :</h3>
        <Select
          options={users
            .filter((user) => user.bureauAssignes)
            .map((user) => ({
              value: user._id,
              label: `${user.nom} ${user.prenom}`
            }))}
          onChange={setTeamBureau}
          value={teamBureau}
          placeholder="Sélectionner la ou les personnes du bureau"
          isMulti
          closeMenuOnSelect={false}
        />
      </div>

      <div className="selectGroup">
        <h3>Conducteurs de travaux :</h3>
        <Select
          options={users
            .filter((user) => user.conducAssignes)
            .map((user) => ({
              value: user._id,
              label: `${user.nom} ${user.prenom}`
            }))}
          onChange={setTeamConduc}
          value={teamConduc}
          placeholder="Sélectionner le(s) conducteur(s) de travaux"
          isMulti
          closeMenuOnSelect={false}
        />
      </div>

      <button onClick={assignTeam}>{"Assigner l'équipe"}</button>
    </div>
  )
}

export default Affaireassignation
