import { useState } from 'react'

import './entrepriseForm.scss'

const EntrepriseForm = ({ entrepriseData, onSubmit, isEdit }) => {
  const [entreprise, setEntreprise] = useState({
    nom: entrepriseData?.nom || '',
    adresse: entrepriseData?.adresse || '',
    codePostal: entrepriseData?.codePostal || '',
    ville: entrepriseData?.ville || '',
    telephone: entrepriseData?.telephone || '',
    mail: entrepriseData?.mail || '',
    logo: entrepriseData?.logo || ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setEntreprise({ ...entreprise, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(entreprise)
  }

  return (

    <form onSubmit={handleSubmit} className="containerentrepriseForm">
      <h3>Réglage Entreprise :</h3>
      <div className="block-contain-entreprise">
      <span>{"Nom de l'entreprise :"}</span>
      <input type="text" name="nom" placeholder="Nom" value={entreprise.nom} onChange={handleChange} />
      </div>
      <div className="block-contain-entreprise">
      <span>{"Adresse de l'entreprise :"}</span>
      <input type="text" name="adresse" placeholder="Adresse" value={entreprise.adresse} onChange={handleChange} />
      </div>
      <div className="block-contain-entreprise">
      <span>Code postal :</span>
      <input type="text" name="codePostal" placeholder="Code Postal" value={entreprise.codePostal} onChange={handleChange} />
      </div>
      <div className="block-contain-entreprise">
      <span>Ville :</span>
      <input type="text" name="ville" placeholder="Ville" value={entreprise.ville} onChange={handleChange} />
      </div>
      <div className="block-contain-entreprise">
      <span>Téléphone :</span>

      <input type="tel" name="telephone" placeholder="Téléphone" value={entreprise.telephone} onChange={handleChange} />
      </div>
      <div className="block-contain-entreprise">
      <span>Mail :</span>
      <input type="email" name="mail" placeholder="Mail" value={entreprise.mail} onChange={handleChange} />
      </div>
      <div className="block-contain-entreprise">
      <input type="text" name="logo" placeholder="URL du logo" value={entreprise.logo} onChange={handleChange} />
      </div>

      <button type="submit">{isEdit ? 'Valider les modifications' : "Créer l'entreprise"}</button>
    </form>
  )
}

export default EntrepriseForm
