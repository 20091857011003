import { useEffect, useState, useCallback } from 'react'
import useFetch from '../../../hooks/useFetch'
import Planning from '../../planning/Planning'
import TacheFormPlanning from '../../tachePlanning/tacheFormPlanning/TacheFormPlanning'
import './affichageTachePlanningNew.scss'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Rnd } from 'react-rnd'
import ListeAFaire from '../../listeForm/listeAFaire/ListeAFaire'
import TachesPauseForm from '../TachesPauseForm/TachesPauseForm'
import TacheAPrendre from '../../tachePlanning/tacheAPrendre/TacheAPrendre'
import axios from 'axios'
import TacheIdProgression from '../TacheIdProgression/TacheIdProgression'
import PlanningEntreprise from '../../../pages/planningEntreprise/PlanningEntreprise'
import TacheAndDecalage from '../TacheAndDecalage/TacheAndDecalage'
import ListeManager from '../../ListeCheck/ListeManager/ListeManager'
import ListeDetails from '../../ListeCheck/ListeDetails/ListeDetails'

const AffichageTachePlanningNew = ({ affaireId }) => {
  const { data: tachesdata, reFetch } = useFetch(`/taches/byAffaire/${affaireId}`);
  const [selectedTache, setSelectedTache] = useState(null);
  const [formToShow, setFormToShow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [hauteurMax, setHauteurMax] = useState('500px')

  console.log("tachesdata", tachesdata)
  // Ajuster la hauteur max de la fenêtre
  useEffect(() => {
    const ajusterHauteur = () => {
      // Exemple : définir la hauteur max à 70% de la hauteur de la fenêtre
      const nouvelleHauteur = window.innerHeight * 0.7
      setHauteurMax(`${nouvelleHauteur}`)
    }
    // Ajuster la hauteur lors du montage du composant
    ajusterHauteur()
    // Ajuster la hauteur à chaque redimensionnement de la fenêtre
    window.addEventListener('resize', ajusterHauteur)
    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => window.removeEventListener('resize', ajusterHauteur)
  }, []) // Le tableau vide signifie que cet effet ne s'exécute qu'au montage

  // modale
  // Déterminez la taille initiale en fonction du formulaire à afficher
  const getWindowSize = () => {
    switch (formToShow) {
      case 'TacheForm':
        return { width: '500px', height: '500px' }
      case 'FormAPrendre':
        return { width: '500px', height: '600px' }
      case 'FormAFaire':
        return { width: '600px', height: '700px' }
      case 'PauseForm':
        return { width: '500px', height: '500px' }
      case 'ProgressTache':
        return { width: '600px', height: '200px' }
      case 'ListeDetails':
        return { width: '600px', height: '500px' }
      default:
        return { width: '320px', height: '200px' } // Taille par défaut
    }
  }

  // Déterminez la taille initiale en fonction du formulaire à afficher...
  const initialSize = getWindowSize()

  // Obtenir le titre du formulaire en fonction du formulaire à afficher
  const getTitle = () => {
    switch (formToShow) {
      case 'TacheForm':
        return "Gestion de Tâche";
      case 'FormAPrendre':
        return "Matériel à Prendre";
      case 'FormAFaire':
        return "Liste des Tâches à Faire";
      case 'PauseForm':
        return "Pause Tâche";
      case 'ProgressTache':
        return "Progression de la Tâche";
      case 'PlanningEntreprise':
        return "Planning de l'Entreprise";
      default:
        return "Fenêtre";
    }
  }

  // Gestionnaire pour mettre à jour la position après le déplacement
  const onDragStop = (e, d) => {
    setPosition({ x: d.x, y: d.y })
  }

  // dialogue
  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleTacheSelection = (tacheId) => {
    const tacheSelectionnee = tachesdata.find(tache => tache._id === tacheId)
    setSelectedTache(tacheSelectionnee)
    handleClickOpenDialog() // Ouvrir le dialogue ici
  }

  const handleFormChange = (formType) => {
    setFormToShow(formType)
    handleCloseDialog()
  }

  const handleCloseForm = () => {
    setFormToShow(null)
  }

  // bouton
  const handleAddTaskClick = () => {
    setSelectedTache({
      affaireId,
      nomTache: '',
      description: '',
      typeTache: 'Chantier',
      dateDepart: null,
      dateEcheance: null,
      duration: '',
      durationAvecWeekend: ''
    })
    setFormToShow('TacheForm')
  }

  //bouton liste 
  const [currentListeId, setCurrentListeId] = useState(null)
  const handleListeClick = (listeId) => {
    setCurrentListeId(listeId)
    setFormToShow('ListeDetails')
  }




  const handleSupprimerTache = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) {
        try {
            await axios.delete(`/taches/${selectedTache._id}`);
            alert('Tâche supprimée avec succès.');
            handleCloseDialog(); // Fermer le dialogue après la suppression
            reFetch(); 
        } catch (error) {
            console.error('Erreur lors de la suppression de la tâche :', error);
            alert('Erreur lors de la suppression de la tâche.');
        }
    }
};

  return (
        <div className="AffichageTachePlanningNew">
            <div className="top-atpn">
              <div className="btn-base-atpn">
                <button className="btn-top-atpn" onClick={() => handleAddTaskClick()}>Ajouter une tâche</button>
                <button className="btn-top-atpn" onClick={() => setFormToShow('FormAPrendre')}>Afficher la liste à prendre</button>
                <button className="btn-top-atpn" onClick={() => setFormToShow('FormAFaire')}>Afficher la liste à faire</button>  
                </div>
                <div className="liste-btn-atpn">
                <ListeManager affaireId={affaireId} onListeClick={handleListeClick}/>
                </div>         
            </div>

            <div className="planning-atpn"style={{ maxHeight: hauteurMax }}>
            <Planning mode="tache" toutesLesTaches={tachesdata} onTacheSelect={handleTacheSelection} />
            </div>
            <div className="btn-ajout-tache-atpn">
                <button className="btn-tache-atpn" onClick={() => handleAddTaskClick()}>+</button>
                <span>ajouter une tache</span>
            </div>
            {selectedTache && (
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Choisir une Action</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Sélectionnez une action pour la tâche {selectedTache?.nomTache}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleFormChange('TacheForm')}>Modifier la tâche</Button>
                        <Button onClick={() => handleFormChange('PauseForm')}>Mettre en pause</Button>
                        <Button onClick={() => handleFormChange('ProgressTache')}>Progression</Button>
                       {/* <Button onClick={() => handleFormChange('FormAPrendre')}>Ajouter du matériel à prendre</Button>*/}
                        <Button onClick={handleSupprimerTache} color="secondary">Supprimer la tâche</Button>
                    </DialogActions>
                </Dialog>
            )}

            {formToShow && (
        <Rnd
        size={{ width: initialSize.width, height: initialSize.height }}
        position={position}
        onDragStop={onDragStop}
        dragHandleClassName="form-header"
        style={{ zIndex: 1000 }}
      >
                <div className="form-container" style={{ width: '100%', height: '100%' }}>
                <div className="form-header">
                  <span className="form-title">{getTitle()}</span>
                  <button className="close-button" onClick={handleCloseForm}>×</button>
                </div>
                <div className="form-body" style={{ width: '100%', height: '100%' }}>
                        {formToShow === 'TacheForm' && (
                            <TacheFormPlanning affaireId={affaireId} selectedTache={selectedTache} refreshTaches={reFetch} onFormSubmit={handleCloseForm}/>
                        )}
                        {formToShow === 'FormAPrendre' && (
                            <TacheAPrendre affaireId={affaireId} />
                        )}
                        {formToShow === 'FormAFaire' && (
                            <ListeAFaire affaireId={affaireId} />
                        )}
                        {formToShow === 'PauseForm' && (
                            <TacheAndDecalage affaireId={affaireId} onFormSubmit={handleCloseForm} selectedTache={selectedTache} refreshTaches={reFetch}/>
                        )}
                        {formToShow === 'ProgressTache' && (
                            <TacheIdProgression  affaireId={affaireId} onFormSubmit={handleCloseForm} selectedTache={selectedTache} refreshTaches={reFetch}/>
                        )}
                        {formToShow === 'ListeDetails' && currentListeId && (
                          <ListeDetails listeId={currentListeId} />
                        )}
                    </div>
                </div>
                </Rnd>
            )}
        </div>
  )
} 

export default AffichageTachePlanningNew
