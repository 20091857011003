import { Typography, Box } from '@mui/material'
import './ligneMois.scss'

const LigneMois = ({ months, dayWidth }) => {
  // Assurez-vous que 'months' est bien un tableau
  if (!Array.isArray(months)) {
    return <Typography color="error">Erreur : Les données des mois ne sont pas valides.</Typography>
  }
  const monthNames = {
    janvier: 0,
    février: 1,
    mars: 2,
    avril: 3,
    mai: 4,
    juin: 5,
    juillet: 6,
    août: 7,
    septembre: 8,
    octobre: 9,
    novembre: 10,
    décembre: 11
  }
  const getDaysInMonth = (monthYear) => {
    const [month, year] = monthYear.split(' ')
    const monthIndex = monthNames[month.toLowerCase()]

    const date = new Date(year, monthIndex, 1)
    const days = []

    while (date.getMonth() === monthIndex) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }

    return days
  }

  return (
        <Box className="ligneMoisContainer">
        {months.map((monthYear, index) => {
          const daysInMonth = getDaysInMonth(monthYear).length
          const monthWidth = daysInMonth * dayWidth
          const isEven = index % 2 === 0
          const backgroundColor = isEven ? '#fff' : '#e0e0e0' // Couleur claire pour les mois pairs, plus foncée pour les impairs

          return (
                <div key={index} className="monthContainer" style={{ width: `${monthWidth}px`, backgroundColor }}>
                    <Typography
                        variant="body2"
                        className="mois"
                    >
                        {monthYear}
                    </Typography>
                </div>
          )
        })}
    </Box>
  )
}

export default LigneMois
