import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Switch, FormControlLabel, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './tacheProgression.scss';

const TacheProgression = ({ taches, reFetch, affaireId }) => {
  const [tachesEtat, setTachesEtat] = useState({});
  const [filtre, setFiltre] = useState('');
  const [typeFiltre, setTypeFiltre] = useState('');

  // Types de tâches disponibles pour le filtrage
  const typesAutorises = ['Chantier', 'Bureau', 'Administratif', 'Technique', 'sous traitant'];

  useEffect(() => {
    if (!taches || taches.length === 0) {
      setTachesEtat({});
    } else {
      const etatsInitiaux = taches.reduce((acc, tache) => ({
        ...acc,
        [tache._id]: {
          pourcentageRealisation: tache.pourcentageRealisation,
          fait: tache.pourcentageRealisation === 100
        }
      }), {});
      setTachesEtat(etatsInitiaux);
    }
  }, [taches]);

  const handleTacheUpdate = async (tacheId, pourcentage, isFait) => {
    const confirmation = window.confirm('Confirmez-vous la modification ?');
    if (!confirmation) return;
  
    let update = isFait
      ? { pourcentageRealisation: 100, fait: true }
      : { pourcentageRealisation: pourcentage, fait: false };
  
    try {
      // Mise à jour de la tâche spécifique
      await axios.put(`/taches/${tacheId}`, update);
  
      // Recalcul du pourcentage global de réalisation de l'affaire
      const tachesResponse = await axios.get(`/taches/byAffaire/${affaireId}`);
      const taches = tachesResponse.data.filter(tache => tache.typeTache === 'Chantier');
      
      let totalPourcentage = 0;
      let totalDuree = 0;
      taches.forEach(tache => {
        if (tache.duration != null) {
          totalPourcentage += tache.pourcentageRealisation * tache.duration;
          totalDuree += tache.duration;
        }
      });
      const pourcentageRealisationAffaire = totalDuree > 0 ? totalPourcentage / totalDuree : 0;
  
      // Mise à jour de l'affaire avec le nouveau pourcentage global de réalisation
      await axios.put(`/affaires/phoneEnd/${affaireId}`, {
        pourcentageRealisation: pourcentageRealisationAffaire,
      });
  
      // Mettre à jour l'état local après la mise à jour réussie de la tâche
      setTachesEtat((prev) => ({
        ...prev,
        [tacheId]: { ...prev[tacheId], ...update },
      }));
  
      // Rafraîchir les données pour refléter les changements
      reFetch();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche', error);
    }
  };

// Filtre les tâches pour inclure uniquement celles dont le type est autorisé
const tachesFiltrees = taches.filter(tache =>
  typesAutorises.includes(tache.typeTache) && // Ajout de cette condition pour filtrer par types autorisés
  (typeFiltre ? tache.typeTache === typeFiltre : true) &&
  (tache.nomTache.toLowerCase().includes(filtre.toLowerCase()) || 
   tache.description.toLowerCase().includes(filtre.toLowerCase()))
);

  return (
    <div className="tacheprogression">
      <div className="top-tacheprogression">
      <TextField
        label="Rechercher une tâche"
        variant="outlined"
        fullWidth
        onChange={(e) => setFiltre(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <FormControl fullWidth>
        <InputLabel>Type de tâche</InputLabel>
        <Select
          value={typeFiltre}
          label="Type de tâche"
          onChange={(e) => setTypeFiltre(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">
            <em></em>
          </MenuItem>
          {typesAutorises.map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      {tachesFiltrees.map((tache) => (
        <div key={tache._id} className="choose-progress-tp">
          <h3>Avance de la tache : {tache.nomTache}</h3>
          {[
            { label: '0%', value: 0 },
            { label: '0 à 25%', value: 25 },
            { label: '25 à 50%', value: 50 },
            { label: '50 à 75%', value: 75 },
            { label: '75 à 100%', value: 99 }
          ].map((item) => (
            <Button
              className="progress-btn-tp"
              key={item.value}
              variant={tachesEtat[tache._id]?.pourcentageRealisation === item.value ? 'contained' : 'outlined'}
              onClick={() => handleTacheUpdate(tache._id, item.value, item.value === 100)}
            >
              {item.label}
            </Button>
          ))}
          <FormControlLabel
            className="progress-switch-tp"
            control={
              <Switch
              className="progress-switch-tp-int"
                key={tache._id}
                checked={tachesEtat[tache._id]?.fait ?? false}
                onChange={(e) => handleTacheUpdate(tache._id, e.target.checked ? 100 : 99, e.target.checked)}
              />
            }
            label="Fait"
          />
        </div>
      ))}
    </div>
  )
}

export default TacheProgression
