import React from 'react';
import HandymanIcon from '@mui/icons-material/Handyman';
import InfoIcon from '@mui/icons-material/Info';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import './ligneNom.scss';

const LigneNom = ({ tache }) => {
  return (
    <div className="tacheInfo-tachespl">
      <div className="nomTache-tachespl">
        {tache.typeTache === "Chantier" && <HandymanIcon style={{ marginRight: '5px' }} className='icon-ligne-nompl' />}
        {tache.typeTache === "Observation" && <InfoIcon style={{ marginRight: '5px' }} className='icon-ligne-nompl'/>}
        {tache.typeTache === "Document" && <FolderOpenIcon style={{ marginRight: '5px' }} className='icon-ligne-nompl'/>}
        {tache.nomTache}
      </div>
    </div>
  );
}

export default LigneNom;