import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../../components/sidebar/Sidebar.jsx'
import UserPresentation from '../../components/User/UserPresentation/UserPresentation.jsx'
import UserFormOnglet from '../../components/User/userFormOnglet/UserFormOnglet'
import { userIdMenu } from '../../variables/sidebar/topMenu.jsx'
import './usersId.scss'
import useFetch from '../../hooks/useFetch'
import TimeSheet from '../../components/TimeSheet/TimeSheet'

const UsersId = () => {
  const { id } = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const { data: userData } = useFetch(`/utilisateurs/${id}`)
  const menu = userIdMenu(id)

  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  console.log('1', id)
  console.log('2', userData)
  return (
    <div className="usersid">
      <div className="containerusersId">
      <div className="sidebar">
          <Sidebar menu={menu} userId={id} />
        </div>

        <div className="userIdContainer">

        <div className="custom-tabs">
            <div
              className={`tab ${selectedTab === 0 ? 'active' : ''}`}
              onClick={() => handleTabChange(0)}
            >
              Accueil
            </div>
            <div
              className={`tab ${selectedTab === 1 ? 'active' : ''}`}
              onClick={() => handleTabChange(1)}
            >
              Modification
            </div>
            {/*<div
              className={`tab ${selectedTab === 2 ? 'active' : ''}`}
              onClick={() => handleTabChange(2)}
            >
              {"Feuille d'heures"}
            </div>*/}
            </div>
            <div className='titleuserIdContainer'>VOUS ETES SUR LA FEUILLE DE : {userData.nom}{' '}{userData.prenom}</div>
            <div className="test">
                {selectedTab === 0 && <UserPresentation user={userData} />}
                {selectedTab === 1 && <UserFormOnglet user={userData} />}
                {selectedTab === 2 && <TimeSheet userId={id} utilisateur={userData} />}
            </div>

        </div>
      </div>
    </div>
  )
}

export default UsersId
