import './descriptionContain.scss'

const DescriptionContain = ({ tache, setTache }) => {
  const handleChange = (e) => {
    setTache({ ...tache, [e.target.name]: e.target.value })
  }
  return (
    <div className="DescriptionContain">
            <textarea
    name="description"
    value={tache.description}
    onChange={handleChange}
    className="input-description-contain"
  />
    </div>
  )
}

export default DescriptionContain
