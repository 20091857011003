import { useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import axios from 'axios'
import './tacheAFaire.scss'

const TacheAFaire = ({ affaireId }) => {
  const [formData, setFormData] = useState({
    affaireId,
    nomTache: '',
    description: '',
    typeTache: 'Chantier',
    dateDepart: '',
    dateEcheance: '',
    duration: '',
    nombreDeUsers: ''
  })
  const [editingTacheId, setEditingTacheId] = useState(null)
  const {
    data: taches,
    loading,
    error,
    reFetch
  } = useFetch(`/taches/byAffaire/${affaireId}`)

  const handleChange = (e) => {
    const { name, value } = e.target
    const newFormData = { ...formData, [name]: value }

    // Si 'dateDepart' est modifié, réinitialiser 'dateEcheance', et vice versa
    if (name === 'dateDepart' && value) {
      newFormData.dateEcheance = ''
    } else if (name === 'dateEcheance' && value) {
      newFormData.dateDepart = ''
    }

    setFormData(newFormData)
  }

  const resetForm = () => {
    setFormData({
      affaireId,
      nomTache: '',
      description: '',
      typeTache: 'Chantier',
      dateDepart: '',
      dateEcheance: '',
      duration: '',
      nombreDeUsers: ''
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const method = editingTacheId ? 'put' : 'post'
    const url = editingTacheId ? `/taches/${editingTacheId}` : '/taches'
    const action = editingTacheId ? 'mise à jour' : 'création'

    try {
      const response = await axios[method](url, formData)
      const newTacheId = response.data._id

      if (!editingTacheId && affaireId) {
        try {
          // Récupérer les tâches actuelles de l'affaire
          const response = await axios.get(`/affaires/${affaireId}`)
          const tachesActuelles = response.data.taches || []

          // Ajouter le nouvel ID de tâche à la liste
          tachesActuelles.push(newTacheId)

          // Mettre à jour l'affaire avec la nouvelle liste de tâches
          await axios.put(`/affaires/${affaireId}`, {
            taches: tachesActuelles
          })
        } catch (error) {
          console.error('Erreur lors de la mise à jour des tâches de l\'affaire', error)
        }
      }

      setEditingTacheId(null)
      resetForm()
      await reFetch()
    } catch (err) {
      console.error(`Erreur lors de la ${action} de la tâche`, err)
    }
  }

  const handleEditClick = (tache) => {
    setEditingTacheId(tache._id)
    setFormData({ ...tache })
  }

  const renderTaches = () => {
    if (!taches) return null

    const sortedTaches = taches.sort((a, b) => {
      if (a.dateEcheance && b.dateEcheance) {
        return new Date(b.dateEcheance) - new Date(a.dateEcheance)
      }
      return new Date(b.createdAt) - new Date(a.createdAt)
    })

    return sortedTaches.map((tache) => (
      <div key={tache._id} className="tache">
        <h3>{tache.nomTache}</h3>
        <p>Description: {tache.description}</p>
        <p>Type: {tache.typeTache}</p>
        <p>
          Date de départ:{' '}
          {tache.dateDepart
            ? new Date(tache.dateDepart).toLocaleDateString()
            : 'Non spécifiée'}
        </p>
        <p>
          {"Date d'échéance:"}{' '}
          {tache.dateEcheance
            ? new Date(tache.dateEcheance).toLocaleDateString()
            : 'Non spécifiée'}
        </p>
        <p>Durée: {tache.duration} jours</p>
        <p>{"Nombre d'utilisateurs:"} {tache.nombreDeUsers}</p>
        {editingTacheId === null && (
          <button onClick={() => handleEditClick(tache)}>Modifier</button>
        )}
      </div>
    ))
  }

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur: {error.message}</div>

  return (
    <div className="tacheAFaire">
      <h1>Gestion des Tâches</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="nomTache"
          value={formData.nomTache}
          onChange={handleChange}
          placeholder="Nom de la tâche"
        />
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Description"
        />
        <select
          name="typeTache"
          value={formData.typeTache}
          onChange={handleChange}
        >
          <option value="Chantier">Chantier</option>
          <option value="Bureau">Bureau</option>
          <option value="Administratif">Administratif</option>
          <option value="Technique">Technique</option>
        </select>
        <input
          type="date"
          name="dateDepart"
          value={formData.dateDepart}
          onChange={handleChange}
          disabled={formData.dateEcheance} // Désactiver si 'dateEcheance' est rempli
        />
        <input
          type="date"
          name="dateEcheance"
          value={formData.dateEcheance}
          onChange={handleChange}
          disabled={formData.dateDepart} // Désactiver si 'dateDepart' est rempli
        />
        <input
          type="number"
          name="duration"
          value={formData.duration}
          onChange={handleChange}
          placeholder="Durée (jours)"
        />
        <input
          type="number"
          name="nombreDeUsers"
          value={formData.nombreDeUsers}
          onChange={handleChange}
          placeholder="Nombre d'utilisateurs"
        />
        <button type="submit">
          {editingTacheId ? 'Valider les modifications' : 'Ajouter la tâche'}
        </button>
      </form>
      <div className="liste-taches">{renderTaches()}</div>
    </div>
  )
}

export default TacheAFaire
