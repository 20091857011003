import { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import DestinataireInput from '../boiteDEnvoie/destinataireInput/DestinataireInput'
import './boiteDeReponseMessage.scss'

const BoiteDeReponseMessage = ({ onClose, commentaireReponse, reFetch }) => {
  console.log('22', commentaireReponse)
  const { utilisateur } = useContext(AuthContext)

  // Initialisation du commentaire de réponse
  const initialComment = `\n\n\n\n\nDe : ${commentaireReponse.utilisateurId.nom} ${commentaireReponse.utilisateurId.prenom} <${commentaireReponse.utilisateurId.email}>\n\n${commentaireReponse.commentaire}`
  const [comment, setComment] = useState({
    tacheId: commentaireReponse.tacheId,
    utilisateurId: utilisateur._id,
    // mettre tout les utilisateurs si c'est repondre a tous
    usersId: [commentaireReponse.utilisateurId._id],
    commentaire: initialComment
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await axios.post('/comments/messagerie/reponse', comment)
      console.log('Commentaire ajouté :', response.data)
      onClose()
    } catch (error) {
      console.error('Erreur lors de l\'envoi du commentaire :', error)
    }
  }

  if (!commentaireReponse) {
    return <div>Chargement...</div>
  }
  console.log('23', comment)
  return (
    <div className="BoiteDeReponseMessage">
      <div className="header-bdrm">
        <button className="btn-valid-bdrm" onClick={handleSubmit}>
          Envoyer
        </button>
        <div className="rightchoix-bdrm">a droite ici</div>
      </div>
      <div className="body-bdrm">
        <div className="destinataire-em">
          <span>À:</span>
          <DestinataireInput tache={comment} setTache={setComment} />
        </div>
        <textarea
        className="textarea-bdrm"
          value={comment.commentaire}
          onChange={(e) =>
            setComment({ ...comment, commentaire: e.target.value })
          }
        />
      </div>
    </div>
  )
}

export default BoiteDeReponseMessage
