import { Typography } from '@mui/material'
import './ligneJour.scss'

const LigneJour = ({ months, dayWidth }) => {
  const monthNames = {
    janvier: 0,
    février: 1,
    mars: 2,
    avril: 3,
    mai: 4,
    juin: 5,
    juillet: 6,
    août: 7,
    septembre: 8,
    octobre: 9,
    novembre: 10,
    décembre: 11
  }

  const getDaysInMonth = (monthYear) => {
    const [month, year] = monthYear.split(' ')
    const monthIndex = monthNames[month.toLowerCase()]

    const date = new Date(year, monthIndex, 1)
    const days = []

    while (date.getMonth() === monthIndex) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }

    return days
  }

  const isWeekend = (date) => {
    const day = date.getDay()
    return day === 0 || day === 6 // Dimanche (0) ou Samedi (6)
  }

  const isHoliday = (date) => {
    // Logique pour déterminer si c'est un jour férié en France
    // ...
  }

  return (
    <div className="ligneJourContainer-tachespl">
    {months.map((monthYear, index) => {
      const daysOfMonth = getDaysInMonth(monthYear)
      return (
            <div key={index} className="monthDays-tachespl">
                {daysOfMonth.map((date, dayIndex) => (
                    <Typography
                        key={dayIndex}
                        variant="body2"
                        className={`jour-tachespl ${isWeekend(date) ? 'weekend-tachespl' : ''} ${isHoliday(date) ? 'holiday-tachespl' : ''}`}
                        style={{ width: `${dayWidth}px` }}
                    >
                        {date.getDate()}
                    </Typography>
                ))}
            </div>
      )
    })}
  </div>
  )
}
export default LigneJour
