import Sidebar from '../../components/sidebar/Sidebar'
import { calandarMenu } from '../../variables/sidebar/topMenu'
import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './calandar.scss'

const localizer = momentLocalizer(moment)

const Calandar = () => {
  const tasks = [
    {
      idTache: 1,
      nomTache: 'Tache 1',
      descriptionTache: 'Description 1',
      dateDepartReelle: '2023-12-10T00:00:00.000+00:00',
      dateEcheanceReelle: '2023-12-11T00:00:00.000+00:00',
      avancement: 0,
      idProjet: 1,
      idUtilisateur: 1,
      idStatut: 1
    },
    {
      idTache: 2,
      nomTache: 'Tache 2',
      descriptionTache: 'Description 2',
      dateDepartReelle: '2023-12-01T00:00:00.000+00:00',
      dateEcheanceReelle: '2023-12-02T00:00:00.000+00:00',
      avancement: 0,
      idProjet: 1,
      idUtilisateur: 1,
      idStatut: 1
    },
    {
      idTache: 3,
      nomTache: 'Tache 3',
      descriptionTache: 'Description 3',
      dateDepartReelle: '2023-12-10T00:00:00.000+00:00',
      dateEcheanceReelle: '2023-12-13T00:00:00.000+00:00',
      avancement: 0,
      idProjet: 1,
      idUtilisateur: 1,
      idStatut: 1
    }

  ]

  const events = tasks.map(task => ({
    title: task.nomTache,
    start: new Date(task.dateDepartReelle),
    end: new Date(task.dateEcheanceReelle),
    allDay: true
  }))

  return (
  <div className="calandar">
    <div className="full-container-calandar">
      <div className="sidebar">
        <Sidebar menu={calandarMenu}/>
      </div>
      <div className="container-calandar">
        <div className="title-calandar">
        <h1>Mon Calendrier:</h1>
        </div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          messages={messages}
          components={{
            toolbar: CustomToolbar
          }}
          style={{ height: 500 }}
        />
      </div>
    </div>
  </div>
  )
}
export default Calandar

// traduction francais du calendrier
const messages = {
  allDay: 'Toute la journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Événement', // Or 'Rendez-vous'
  noEventsInRange: 'Il n\'y a pas d\'événements dans cette période.',
  showMore: total => `+ ${total} événement(s) supplémentaire(s)`
}

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV')
  }

  const goToNext = () => {
    toolbar.onNavigate('NEXT')
  }

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY')
  }

  const goToDayView = () => {
    toolbar.onView('day')
  }

  const goToWeekView = () => {
    toolbar.onView('week')
  }

  const goToMonthView = () => {
    toolbar.onView('month')
  }

  const label = () => {
    const date = moment(toolbar.date)

    switch (toolbar.view) {
      case 'day':
        return <span><b>{date.format('DD MMMM YYYY')}</b></span>
      case 'week':
        return <span><b>{date.startOf('week').format('DD MMMM')} - {date.endOf('week').format('DD MMMM YYYY')}</b></span>
      case 'month':
        return <span><b>{date.format('MMMM YYYY')}</b></span>
      default:
        return null
    }
  }

  return (
    <div className="btn-group-calandar">
            <button className="btn-aujourdui-calandar" onClick={goToCurrent}>{"Aujourd'hui"}</button>
      <button className="btn-go-calandar" onClick={goToBack}>&#8249;</button>
      <span className="label-calandar">{label()}</span>
      <button className="btn-go-calandar" onClick={goToNext}>&#8250;</button>
      <button className="btn-choose-calandar" onClick={goToDayView}>Jour</button>
      <button className="btn-choose-calandar" onClick={goToWeekView}>Semaine</button>
      <button className="btn-choose-calandar" onClick={goToMonthView}>Mois</button>

    </div>
  )
}
