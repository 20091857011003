import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./selectAndGoAffaire.scss";

const SelectAndGoAffaire = () => {
    const [affaires, setAffaires] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAffaires = async () => {
            try {
                const { data } = await axios.get('/affaires');
                const filteredData = data.filter(affaire => 
                    ['création', 'en préparation', 'en cours', 'terminé', 'archivé', 'a entrer au planning', 'en attente', 'en pause'].includes(affaire.statut)
                ).map(affaire => ({
                    value: affaire._id,
                    label: affaire.nomAffaire
                }));
                setAffaires(filteredData);
            } catch (error) {
                console.error('Erreur lors du chargement des affaires', error);
            }
        };

        fetchAffaires();
    }, []);

    const handleAffaireChange = (selectedOption) => {
        navigate(`/affaires/${selectedOption.value}`);
    };

    return (
        <div className="SelectAndGoAffaire">
            <h3>Sélectionner une affaire et s'y rendre :</h3>
            <Select
                options={affaires}
                onChange={handleAffaireChange}
                placeholder="Rechercher une affaire..."
                className="select-affaire"
                isClearable
                isSearchable
            />
        </div>
    );
};

export default SelectAndGoAffaire;
