import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import './reset.scss'

import Logo from '../../assets/images/logo.jpg'

const Reset = () => {
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()

  const navigate = useNavigate()

  const token = useMemo(() => searchParams.get('token'), [searchParams])
  useEffect(() => {
    if (!token) navigate('/login')
  }, [token])

  const handleClick = async (e) => {
    if (password.length < 8) {
      return alert('Le mot de passe doit contenir au moins 8 caractères')
    }
    if (password !== confirmPassword) {
      return alert('Les mots de passe de correspondent pas')
    }
    setIsLoading(true)
    try {
      await axios.post('/auth/reset', { token, password })
      navigate('/login')
    } catch (err) {
      alert('Une erreur est survenue, merci de rafraichir la page et de réessayer')
      setIsLoading(false)
    }
  }

  return (
    <div className="login">
      <div className="lContainer">
        <img src={Logo} alt="logo" />
        <input
          type="password"
          placeholder="password"
          id="password"
          value={password}
          onChange={e => setPassword(e.currentTarget.value)}
          className="lInput"
        />
        <input
          type="password"
          placeholder="confirm password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.currentTarget.value)}
          className="lInput"
        />
        <button disabled={isLoading} onClick={handleClick} className="lButton">
          Modifier mon mot de passe
        </button>
        <NavLink to="/login">
          Revenir à la page de login
        </NavLink>
      </div>
    </div>
  )
}

export default Reset
