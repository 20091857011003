import React from 'react';
import { useNavigate } from 'react-router-dom'; // Remplacez useHistory par useNavigate
import CommentIcon from '@mui/icons-material/Comment';
import BusinessIcon from '@mui/icons-material/Business';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const NotificationItem = ({ notification }) => {
    const navigate = useNavigate();
  
    // Fonction pour générer un message interactif basé sur le type de notification
    const generateMessage = () => {
        let baseMessage = ""; // Initialiser le message de base
      
        switch (notification.type) {
          case 'Nouveau Commentaire':
            const docNameForComment = notification.documentId && notification.documentId.name ? notification.documentId.name : "un document";
            const parentFolderNameComment = notification.documentId && notification.documentId.parent ? ` dans le dossier "${notification.documentId.parent.name}"` : "";
            baseMessage = `${notification.utilisateurId.prenom} ${notification.utilisateurId.nom} a ajouté un nouveau commentaire sur le document : ${docNameForComment}${parentFolderNameComment} de l'affaire : ${notification.affaireId.nomAffaire}.`;
            break;
          case 'Création Affaire':
            baseMessage = `${notification.utilisateurId.prenom} ${notification.utilisateurId.nom} a créé une nouvelle affaire : ${notification.affaireId.nomAffaire}.`;
            break;
          case 'Document Modifié':
            const docNameForModification = notification.documentId && notification.documentId.name ? notification.documentId.name : "un document";
            const parentFolderNameModification = notification.documentId && notification.documentId.parent ? ` dans le dossier "${notification.documentId.parent.name}"` : "";
            baseMessage = `${notification.utilisateurId.prenom} ${notification.utilisateurId.nom} a modifié le document : ${docNameForModification}${parentFolderNameModification} de l'affaire : ${notification.affaireId.nomAffaire}.`;
            break;
        }
        return baseMessage;
      };
  
    const navigateToNotification = () => {
      // Logique de navigation identique à celle fournie précédemment
      switch(notification.type) {
        case 'Nouveau Commentaire':
            navigate(`/affaires/${notification.affaireId._id}`);
            break;
        case 'Document Modifié':
          navigate(`/affaires/${notification.affaireId._id}`);
          break;
        case 'Création Affaire':
          navigate(`/affaires/${notification.affaireId._id}`);
          break;
      }
    };
  
    // Déterminez l'icône à utiliser en fonction du type de notification
    let icon;
    switch(notification.type) {
      case 'Nouveau Commentaire':
        icon = <CommentIcon />;
        break;
      case 'Création Affaire':
        icon = <BusinessIcon />;
        break;
      case 'Document Modifié':
        icon = <InsertDriveFileIcon />;
        break;
    }
  
    const message = generateMessage(); // Générer le message personnalisé
  
    return (
      <div onClick={navigateToNotification} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}>
        {icon}
        <span>{message}</span> {/* Afficher le message généré */}
      </div>
    );
  };
  
  export default NotificationItem;