import { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import axios from 'axios'
import './envoyerMessage.scss'
import NomTacheMessage from '../nomTacheMessage/NomTacheMessage'
import DestinataireInput from '../destinataireInput/DestinataireInput'
import RefAffaire from '../refAffaire/RefAffaire'
import DescriptionContain from '../descriptionContain/DescriptionContain'
import AjoutDateEcheance from '../ajoutDateEcheance/AjoutDateEcheance'
import BtnUrgent from '../btnUrgent/BtnUrgent'
import NbrJoursRappel from '../ndrJoursRappel/NdrJoursRappel'

const initialState = {
  userId: '',
  nomTache: '',
  dateRapelle: null,
  affaireId: null,
  suivreTacheId: null,
  priorite: 'Moyenne',
  typeTache: '',
  nombreDeJourAvantRapelle: null,
  description: '',
  usersId: []
}

const EnvoyerMessage = ({ onClose, typeTache = 'messagerie' }) => {
  const { utilisateur } = useContext(AuthContext)
  const [affaireSelectionnee, setAffaireSelectionnee] = useState(null)
  const [tache, setTache] = useState({
    ...initialState,
    userId: utilisateur._id,
    typeTache // Utilisation de la prop avec une valeur par défaut
  })

  const handleAffaireSelection = (affaire) => {
    setAffaireSelectionnee(affaire)
    setTache((prev) => ({ ...prev, affaireId: affaire.id }))
  }

  // Fonction pour supprimer l'affaire sélectionnée
  const handleRemoveAffaire = () => {
    setTache((prev) => ({ ...prev, affaireId: null }))
    setAffaireSelectionnee(null)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    // Validation des champs obligatoires
    if (!tache.nomTache || tache.usersId.length === 0) {
      alert(
        'Le nom de la tâche et au moins un destinataire sont obligatoires.'
      )
      return
    }
    // url en fonction de la props typeTache
    const urlMap = {
      messagerie: '/taches/createMessage',
      'a prendre': '/taches/createTask',
      option2: '/taches/urlForOption2',
      option3: '/taches/urlForOption3'
      // Ajoutez d'autres mappages selon vos besoins
    }
    const url = urlMap[typeTache]
    // Traitement de la date de rappel
    if (tache.nombreDeJourAvantRapelle) {
      const dateDeRappel = new Date()
      dateDeRappel.setDate(
        dateDeRappel.getDate() + parseInt(tache.nombreDeJourAvantRapelle)
      )
      tache.dateRapelle = dateDeRappel
    }

    try {
      console.log('Tâche à envoyer:', tache)
      // Envoi de la tâche
      const responseTache = await axios.post(url, tache)
      const tacheCreee = responseTache.data
      console.log('Tâche créée:', tacheCreee)
      // Réinitialisation de l'état
      setTache({ ...initialState, userId: utilisateur._id })
    } catch (error) {
      console.error("Erreur lors de l'envoi de la tâche", error)
    }
    onClose()
  }

  return (
    <div className="EnvoyerMessage">
      <div className="header-em">
        <button className="btn-valid-em" onClick={handleSubmit}>
          Envoyer
        </button>
        <div className="rightchoix-em">
          <div className="right-left-em">
            <RefAffaire
              tache={tache}
              setTache={setTache}
              onAffaireSelect={handleAffaireSelection}
            />
            <AjoutDateEcheance tache={tache} setTache={setTache} />
          </div>
          <div className="right-right-em">
            <NbrJoursRappel tache={tache} setTache={setTache} />
            <BtnUrgent tache={tache} setTache={setTache} />
          </div>
        </div>
      </div>
      <div className="body-em">
        <div className="destinataire-em">
          <span>À:</span>
          <DestinataireInput tache={tache} setTache={setTache} />
        </div>
        <NomTacheMessage tache={tache} setTache={setTache} />
        {affaireSelectionnee && (
          <div className="affaire-option-em">
            <div className="affaire-details">
              <h3>Affaire Sélectionnée : {affaireSelectionnee.nom}</h3>
              <button
                onClick={handleRemoveAffaire}
                className="remove-affaire-btn"
              >
                x
              </button>
            </div>
          </div>
        )}
        <div className="contain-desc-em">
          <DescriptionContain tache={tache} setTache={setTache} />
        </div>
      </div>
    </div>
  )
}

export default EnvoyerMessage
