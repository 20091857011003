import React from 'react'
import { Typography } from '@mui/material'
import './ligneTacheAffaire.scss'

const LigneTacheAffaire = ({
  affaire,
  startDate,
  endDate,
  dayWidth,
  months,
  onAffaireSelect
}) => {
  // Vérifier la validité des dates
  console.log('11', affaire, startDate, endDate)
  // Vérifier la validité des dates globales
  if (!(startDate instanceof Date && !isNaN(startDate)) || !(endDate instanceof Date && !isNaN(endDate))) {
    return <Typography color="error">Données de date non valides pour la planification</Typography>
  }

  // Déterminer les dates de début et de fin en fonction de l'état de l'affaire
  let affaireStartDate, affaireEndDate
  switch (affaire.etatDate) {
    case 'date non modifier':
      affaireStartDate = new Date(new Date(affaire.dateDebutReelle).setDate(new Date(affaire.dateDebutReelle).getDate() - 1))
      affaireEndDate = new Date(new Date(affaire.dateFinReelle).setDate(new Date(affaire.dateFinReelle).getDate() - 1))
      break
    case 'date estimatif':
      affaireStartDate = new Date(affaire.dateEstimatifDepart)
      affaireEndDate = new Date(affaire.dateEstimatifFin)
      break
    case 'a valider':
      affaireStartDate = new Date(affaire.modificationAValiderDepart)
      affaireEndDate = new Date(affaire.modificationAValiderFin)
      break
    default:
      affaireStartDate = null // ou une autre date par défaut
      affaireEndDate = null // ou une autre date par défaut
  }

  // calcul du nombre de jour
  const monthNames = {
    janvier: 0,
    février: 1,
    mars: 2,
    avril: 3,
    mai: 4,
    juin: 5,
    juillet: 6,
    août: 7,
    septembre: 8,
    octobre: 9,
    novembre: 10,
    décembre: 11
  }

  const getDaysInMonth = (monthYear) => {
    const [month, year] = monthYear.split(' ')
    const monthIndex = monthNames[month.toLowerCase()]

    const date = new Date(year, monthIndex, 1)
    const days = []

    while (date.getMonth() === monthIndex) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }

    return days
  }

  const isWeekend = (date) => {
    const day = date.getDay()
    return day === 0 || day === 6 // Dimanche (0) ou Samedi (6)
  }
  // Calculer la position et la durée du surlignage

  const dateDebutAffaire = new Date(affaireStartDate)
  const dateFinAffaire = new Date(affaireEndDate)

  // Vérifier que la tâche est dans la plage de dates
  if (dateFinAffaire < startDate || dateDebutAffaire > endDate) {
    return null // La tâche n'est pas dans la plage de dates du planning
  }

  // Calculez le nombre total de jours affichés sur le planning
  // const totalDays = (endDate - startDate) / (24 * 60 * 60 * 1000)

  // Calculez le décalage à partir de la date de début en pixels
  // const startDayOffset = (dateDebutAffaire - startDate) / (24 * 60 * 60 * 1000)
  // const leftOffset = startDayOffset * dayWidth // Convertissez en pixels

  // Calculez la durée de la tâche en pixels
  const durationInDays = Math.ceil((dateFinAffaire - dateDebutAffaire) / (24 * 60 * 60 * 1000))
  // const barWidth = durationInDays * dayWidth // Convertissez en pixels
  const isDayInTask = (date) => {
    return date >= dateDebutAffaire && date <= dateFinAffaire
  }

  console.log('12', durationInDays, startDate, endDate)

  // Remonter l'information
  const handleClick = () => {
    console.log('Affaire cliquée ID:', affaire._id)
    onAffaireSelect(affaire._id)
  }

  // Calculez les segments pour chaque jour en fonction du pourcentage de réalisation
  // Vert: Réalisé, Orange: Proche de la réalisation, Rouge: Non réalisé
  const calculateSegmentColor = (date, affaire) => {
    if (isWeekend(date)) return 'weekend'

    const startDate = new Date(affaireStartDate)
    const endDate = new Date(affaireEndDate)
    const totalDurationDays = (endDate - startDate) / (24 * 60 * 60 * 1000) + 1 // Inclure la fin de la journée
    const pourcentageParJour = 100 / totalDurationDays
    const jourActuelIndex = (date - startDate) / (24 * 60 * 60 * 1000)
    const pourcentageActuelJour = jourActuelIndex * pourcentageParJour

    // Marge pour la zone en cours (orange)
    const margePourcentage = 10 // Exemple : 10% de la réalisation totale avant/après le pourcentage actuel

    // Calcul pour ajuster les transitions entre les couleurs
    if (affaire.pourcentageRealisation >= 100) {
      return 'green' // Affaire terminée
    } else if (affaire.pourcentageRealisation === 0) {
      return 'red' // Affaire non commencée
    } else {
      // Affaire en cours
      if (pourcentageActuelJour < affaire.pourcentageRealisation) {
        return 'green' // Partie réalisée
      } else if (pourcentageActuelJour <= affaire.pourcentageRealisation + margePourcentage) {
        // Si le jour actuel est proche du pourcentage de réalisation, utiliser orange
        return 'orange'
      } else {
        return 'red' // Partie non réalisée
      }
    }
  }

  return (
          <div className="ligneTacheContainer" onClick={handleClick}>
          <div className="barreTache">
            {months.flatMap(monthYear => getDaysInMonth(monthYear)).map((date, index) => {
              // Appliquez le style en fonction de l'état d'avancement de la tâche
              const segmentColor = calculateSegmentColor(date, affaire, durationInDays)
              return (
                <div
                  key={index}
                  className={`jourTache ${isDayInTask(date) ? `highlight ${segmentColor}` : ''} ${isWeekend(date) ? 'weekend' : ''}`}
                  style={{ width: `${dayWidth}px` }}
                />
              )
            })}
          </div>
        </div>
  )
}
export default LigneTacheAffaire
