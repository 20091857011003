import ReactModal from 'react-modal';
import './modalZindex.scss'; // Assurez-vous que le chemin est correct

ReactModal.setAppElement('#root'); // ou tout autre élément racine de votre application

const Modal = ({ title, content, isOpen, onClose }) => {
  return (
    <ReactModal 
      isOpen={isOpen} 
      onRequestClose={onClose}
      overlayClassName="modal-overlay" // Classe personnalisée pour l'overlay
      className="modal-content-custom" // Classe personnalisée pour le contenu
    >
      <div className="modal-header">
        <h2>{title}</h2>
        <button onClick={onClose}>X</button>
      </div>
      <div className="modal-content">
        {content}
      </div>
    </ReactModal>
  );
}

export default Modal;