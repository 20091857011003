import Sidebar from '../../components/sidebar/Sidebar'
import { rapportsfdjMenu } from '../../variables/sidebar/topMenu'

import './rapportsfdj.scss'

const Rapportsfdj = () => {
  return (
        <div className="rapportsfdj">
          <div className="container">
            <div className="sidebar">
              <Sidebar menu={rapportsfdjMenu}/>
            </div>
            <div className="containerRapportsfdj">
              <h1>Rapportsfdj</h1>
            </div>
          </div>
        </div>
  )
}

export default Rapportsfdj
