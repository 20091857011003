import { useState } from 'react'
import './ndrJoursRappel.scss'

const NdrJoursRappel = ({ tache, setTache }) => {
  const [showSelector, setShowSelector] = useState(false)
  const [selectedDays, setSelectedDays] = useState(0)

  const handleRappelChange = (e) => {
    setSelectedDays(e.target.value)
    setTache({ ...tache, nombreDeJourAvantRapelle: e.target.value })
  }

  const handleCreateRappel = () => {
    setShowSelector(true)
  }

  const handleCancelRappel = () => {
    setShowSelector(false)
    setSelectedDays(0)
    setTache({ ...tache, nombreDeJourAvantRapelle: null })
  }

  return (
        <div className="NdrJoursRappel">
            {showSelector
              ? (
                <>
                    <select
                        value={selectedDays}
                        onChange={handleRappelChange}
                        className="select-jours-rappel"
                    >
                        {Array.from({ length: 151 }, (_, i) => (
                            <option key={i} value={i}>{i} jours</option>
                        ))}
                    </select>
                    {selectedDays > 0 && (
                        <div className="rappel-details">
                            <span>Rappel dans {selectedDays} jours</span>
                            <button onClick={handleCancelRappel} className="cancel-rappel-btn">x</button>
                        </div>
                    )}
                </>
                )
              : (
                <button onClick={handleCreateRappel} className="create-rappel-btn">
                    Créer un rappel
                </button>
                )}
        </div>
  )
}

export default NdrJoursRappel
