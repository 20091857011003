import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import './listeManager.scss';

function ListeManager({ affaireId, onListeClick }) {
  const [openCreate, setOpenCreate] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [titre, setTitre] = useState('');
  const [listes, setListes] = useState([]);
  const [isReference, setIsReference] = useState(false);
  const [listesEntreprise, setListesEntreprise] = useState([]);

  // Charger les listes associées à l'affaire au montage du composant

  useEffect(() => {
    const fetchListes = async () => {
      try {
        const response = await axios.get(`/listeCheck/affaireId/${affaireId}`);
        console.log("Réponse API pour listes:", response.data);
        setListes(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des listes', error);
      }
    };
    fetchListes();
  }, [affaireId]);

  const handleClickOpenCreate = () => { 
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleCreate = async () => {
    if (titre) {
      try {
        if (isReference) {
            // Création d'une liste de référence sans l'associer à une affaire spécifique
            const response = await axios.post('/listeCheck', { titre, isReference });
            // Après la création de la liste de référence, cloner cette liste pour l'affaire courante
            const cloneResponse = await axios.post('/listeCheck/clone', { listeId: response.data._id, newAffaireId: affaireId });
            setListes([...listes, cloneResponse.data]);
        } else {
            // Création d'une liste normale associée directement à une affaire
            const response = await axios.post('/listeCheck', { titre, affaire: affaireId });
            setListes([...listes, response.data]);
        }
        setTitre('');
        setIsReference(false);
      } catch (error) {
        console.error('Erreur lors de la création de la liste', error);
      }
    }
    setOpenCreate(false);
};

  const handleClickOpenImport = async () => {
    //importé les liste de l'entreprise 
    try {
        const response = await axios.get('/listeCheck');
        setListesEntreprise(response.data);
        } catch (error) {
        console.error('Erreur lors de la récupération des listes', error);
        }
    setOpenImport(true);
    };

  const handleImport = async (listeId) => {
    try {
      const response = await axios.post('/listeCheck/clone', { listeId, newAffaireId: affaireId });
      setListes([...listes, response.data]);
    } catch (error) {
      console.error('Erreur lors de importation de la liste', error);
    }
    setOpenImport(false);
  };

  return (
    <div className='listeManager-contain'>
      <Tooltip title="Créer une nouvelle liste">
        <IconButton onClick={handleClickOpenCreate} color="primary">
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    <Dialog open={openCreate} onClose={handleCloseCreate}>
      <DialogTitle>Créer une nouvelle liste</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="titre"
          label="Titre de la liste"
          type="text"
          fullWidth
          variant="standard"
          value={titre}
          onChange={(e) => setTitre(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCreate}>Annuler</Button>
        <Button onClick={handleCreate}>Créer</Button>
      </DialogActions>
    </Dialog>

      <Tooltip title="Importer une liste">
        <IconButton onClick={handleClickOpenImport} color="primary">
          <ImportExportIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={openImport} onClose={() => setOpenImport(false)}>
  <DialogTitle>Importer une liste</DialogTitle>
  <DialogContent>
    <List>
      {listesEntreprise.map((liste) => (
        <ListItem button key={liste._id} onClick={() => handleImport(liste._id)}>
          <ListItemText primary={liste.titre} />
        </ListItem>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenImport(false)}>Annuler</Button>
  </DialogActions>
</Dialog>
        <div className="bouton-overflow">
        {listes.map((liste) => {
            const totalElements = liste.elements.length;
            const elementsRealises = liste.elements.filter(e => e.fait).length;
            return (
            <Badge
                key={liste._id}
                color="primary"
                badgeContent={`${elementsRealises}/${totalElements}`}
                sx={{ ".MuiBadge-badge": { right: 5, top: 5, border: `2px solid`, borderColor: `background.paper`, padding: '0 4px' } }}
            >
                <button onClick={() => onListeClick(liste._id)}>
                {liste.titre}
                </button>
            </Badge>
            );
        })}
        </div>
    </div>
  );
}

export default ListeManager;
