import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, List, ListItem, IconButton, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import "./manageReferenceList.scss";
import Modal from '../../modules/ModalZindex/ModalZindex';

function ManageReferenceList({ listeId }) {
    const [open, setOpen] = useState(false);
    const [listeDetails, setListeDetails] = useState({ titre: '', elements: [] });
    const [formData, setFormData] = useState({ titreElement: '', description: '', fait: false, dateRealisation: '' });
    const [showForm, setShowForm] = useState(false);
    const [editElement, setEditElement] = useState(null);

    useEffect(() => {
        if (open) {
            fetchListeDetails();
        }
    }, [open, listeId]);

    const fetchListeDetails = async () => {
      try {
        const response = await axios.get(`/listeCheck/${listeId}`);
        setListeDetails(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des détails de la liste', error);
      }
    };
  
    const handleShowForm = (element = null) => {
        if (element) {
          const formattedDate = element.dateRealisation ? format(new Date(element.dateRealisation), 'yyyy-MM-dd') : '';
          setFormData({
            titreElement: element.titreElement || '',
            description: element.description || '',
            fait: element.fait || false,
            dateRealisation: formattedDate
          });
          setEditElement(element._id);
        } else {
          setFormData({ titreElement: '', description: '', fait: false, dateRealisation: '' });
          setEditElement(null);
        }
        setShowForm(true);
      };
  
      const handleSubmit = async (event) => {
        event.preventDefault();
        const method = editElement ? 'put' : 'post';
        const url = `/listeCheck/${listeId}/elements${editElement ? `/${editElement}` : ''}`;
        try {
          await axios[method](url, formData);
          fetchListeDetails();
          setEditElement(null); // Réinitialiser le formulaire
          setFormData({ titreElement: '', description: '', fait: false, dateRealisation: '' });
        } catch (error) {
          console.error('Erreur lors de la mise à jour de élément', error);
        }
      };

      const handleChange = (event) => {
        setListeDetails(prevState => ({ ...prevState, titre: event.target.value }));
    };
    
    const handleChangeTitle = async () => {
        console.log('listeDetails', listeDetails.titre);
        try {
            const response = await axios.put(`/listeCheck/updateTitle/${listeId}`, { titre: listeDetails.titre });
            console.log('Mise à jour réussie', response.data);
            fetchListeDetails();  // Assurez-vous que cette fonction recharge correctement les données
        } catch (error) {
            console.error('Erreur lors de la mise à jour du titre de la liste', error);
        }
    };

const handleDeleteClick = async (elementId) => {
console.log('elementId', elementId);
// Afficher la boîte de dialogue de confirmation
if (window.confirm("Voulez-vous vraiment supprimer cet élément ?")) {
    try {
        await axios.delete(`/listeCheck/${listeId}/elements/${elementId}`);
        fetchListeDetails(); // Recharger la liste après suppression
    } catch (error) {
        console.error('Erreur lors de la suppression de l\'élément', error);
    }
} else {
    console.log("Suppression annulée");
}
};



const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
return (
    <div>
        <IconButton onClick={handleOpen}  color="primary">
            <EditIcon />
        </IconButton>
        <Modal
            isOpen={open}
            onClose={handleClose}
            title="Modifier la Liste de Référence"
            content={
                <div className='liste-details-manag'>
<TextField
    label="Titre de la liste"
    type="text"
    fullWidth
    value={listeDetails.titre}
    onChange={handleChange}
    onBlur={handleChangeTitle}
/>
                    <Button variant="contained" color="primary" onClick={() => handleChangeTitle()}>
                        modifier le titre 
                    </Button>
                    <Button onClick={() => handleShowForm()} variant="outlined" color="primary">
                        Ajouter un nouvel élément
                    </Button>
                    {showForm && (
                        <div className="form-container-manag">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Titre de l'élément"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.titreElement}
                                    onChange={(e) => setFormData({ ...formData, titreElement: e.target.value })}
                                />
                                <TextField
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                                <Button type="submit" variant="contained" color="primary">
                                    {editElement ? 'Modifier' : 'Ajouter'}
                                </Button>
                                <Button onClick={() => setShowForm(false)} variant="text">Annuler</Button>
                            </form>
                        </div>
                    )}
                    <div className="elements-list-manag">
                        {listeDetails.elements.map((element) => (
                            <div key={element._id} className="element-item-manag">
                                <div className="element-header-manag">
                                    <span className='titre-element-manag'>{element.titreElement || 'Sans titre'}</span>
                                    <div className="actions-manag">
                                        <IconButton onClick={() => handleShowForm(element)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(element._id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                {element.description && <p className="description-manag">{element.description}</p>}
                            </div>
                        ))}
                    </div>
                </div>
            }
        />
    </div>
);
}

export default ManageReferenceList;