import { useContext, useState, useEffect } from 'react'
import Switch from '@mui/material/Switch'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import './affaireSuivreSwitch.scss'

const AffaireSuivreSwitch = ({ affaireData, reFetch }) => {
  const { utilisateur } = useContext(AuthContext)
  const [isFollowing, setIsFollowing] = useState(false)

  useEffect(() => {
    const isUserFollowing = affaireData.usersAssignes?.includes(utilisateur?._id)
    setIsFollowing(isUserFollowing)
  }, [affaireData.usersAssignes, utilisateur])

  const handleSwitchChange = async () => {
    try {
      const response = await axios.put(`/affaires/switchaffaire/${affaireData._id}`, {
        userId: utilisateur._id,
        action: isFollowing ? 'remove' : 'add'
      })

      if (response.status === 200) {
        // Mettre à jour l'état de l'affaire avec les données reçues de l'API
        reFetch(response.data)
      }

      setIsFollowing(!isFollowing)
    } catch (err) {
      console.error("Erreur lors de la mise à jour du suivi de l'affaire: ", err)
    }
  }

  return (
    <Switch
      checked={isFollowing}
      onChange={handleSwitchChange}
    />
  )
}

export default AffaireSuivreSwitch
