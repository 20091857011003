import { useState, useEffect } from 'react'
import './expiringFiles.scss'
import axios from 'axios'
import { addDays, format } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { NavLink } from 'react-router-dom'

export default function ExpiringFiles () {
  const [files, setFiles] = useState([])
  const [expirationDate, setExpirationDate] = useState(addDays(new Date(), 7))

  useEffect(() => {
    axios.get('/files/expiringSoonAndNoArchived', { params: { expirationDate } })
      .then(res => setFiles(res.data))
  }, [expirationDate])

  return <div className="container-expiringFiles">
    <div className="title-expiringFiles">
      <h2>Fichiers prochainement expirés:</h2>
    </div>
    <div className='actions-expiringFiles'>
      <DatePicker
        label="Date d'expiration"
        value={expirationDate}
        onChange={v => setExpirationDate(v)}
      />
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Affaire</th>
            <th>{"Date d'expiration"}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {files.map(file => <tr key={file.id}>
            <td>{file.name}</td>
            <td><NavLink to={`/affaires/${file.affaire._id}`}>{file.affaire.nomAffaire}</NavLink></td>
            <td>{format(new Date(file.expirationDate), 'dd/MM/yyyy')}</td>
            <td><a href={file.url} target="_blank" rel="noreferrer">Ouvrir</a></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>
}
