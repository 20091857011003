import { useState } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Card from '../../components/card/Card'
import useFetch from '../../hooks/useFetch'
import { RolesPermissionsMenu } from '../../variables/sidebar/topMenu'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import './rolesPermissions.scss'
import UserList from '../../components/User/userList/UserList'
import { Switch, FormControlLabel } from '@mui/material'

const RolesPermissions = () => {
  const { data: users, loading, error } = useFetch('/utilisateurs')
  const [isCardView, setIsCardView] = useState(false)

  const handleCardClick = (id) => {
    window.location.href = `/users/${id}`
  }

  const renderCardList = () => {
    return (
      users &&
      users.map((item) => (
        <Card
          key={item._id}
          id={item._id}
          title={item.nom}
          subtitle={item.prenom}
          image={
            item.avatar
              ? (
              <img src={item.avatar} alt={item.nom} />
                )
              : (
              <PermIdentityIcon />
                )
          }
          description={item.desc}
          onClick={handleCardClick}
        />
      ))
    )
  }

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur: {error.message}</div>

  return (
    <div className="rolePermissions">
      <div className="container">
        <div className="sidebar">
          <Sidebar menu={RolesPermissionsMenu()} />
        </div>

        <div className="containerRolePermissions">
          <div className="viewTogglerolePermissions">
            <FormControlLabel
              control={
                <Switch
                  checked={isCardView}
                  onChange={() => setIsCardView(!isCardView)}
                />
              }
              label={isCardView ? 'Card' : 'List'}
            />
          </div>
          <div className="renderrolePermissions">
          {isCardView
            ? (
            <div className="cardView">{renderCardList()}</div>
              )
            : (
            <div className="listView">
              <UserList />
            </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RolesPermissions
