import React from 'react';
import axios from 'axios';
import { Button, Switch, FormControlLabel } from '@mui/material';
import './tacheIdProgression.scss';

const TacheIdProgression = ({ affaireId, selectedTache, refreshTaches, onFormSubmit }) => {

  const handleTacheUpdate = async (tacheId, pourcentage, isFait) => {
    const confirmation = window.confirm('Confirmez-vous la modification ?');
    if (!confirmation) return;

    let update = isFait ? { pourcentageRealisation: 100, fait: true } : { pourcentageRealisation: pourcentage, fait: false };

    try {
      await axios.put(`/taches/${tacheId}`, update);

      const tachesResponse = await axios.get(`/taches/byAffaire/${affaireId}`);
      const taches = tachesResponse.data.filter(tache => tache.typeTache === 'Chantier');

      let totalPourcentage = 0;
      let totalDuree = 0;
      taches.forEach(tache => {
        if (tache.duration != null) {
          totalPourcentage += tache.pourcentageRealisation * tache.duration;
          totalDuree += tache.duration;
        }
      });
      const pourcentageRealisationAffaire = totalDuree > 0 ? totalPourcentage / totalDuree : 0;

      await axios.put(`/affaires/phoneEnd/${affaireId}`, { pourcentageRealisation: pourcentageRealisationAffaire });

      refreshTaches();
      onFormSubmit();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche', error);
    }
  };

  return (
    <div className="TacheIdProgression">
      {selectedTache && (
        <div key={selectedTache._id} className="choose-progress-tp">
          <h3>Avance de la tache : {selectedTache.nomTache}</h3>
          {[{ label: '0%', value: 0 }, { label: '0 à 25%', value: 25 }, { label: '25 à 50%', value: 50 }, { label: '50 à 75%', value: 75 }, { label: '75 à 100%', value: 99 }].map((item) => (
            <Button key={item.value} variant={selectedTache.pourcentageRealisation === item.value ? 'contained' : 'outlined'} onClick={() => handleTacheUpdate(selectedTache._id, item.value, item.value === 100)}>
              {item.label}
            </Button>
          ))}
          <FormControlLabel
            control={<Switch checked={selectedTache.fait ?? false} onChange={(e) => handleTacheUpdate(selectedTache._id, e.target.checked ? 100 : 99, e.target.checked)} />}
            label="Fait"
          />
        </div>
      )}
    </div>
  );
};

export default TacheIdProgression;