import RoofingIcon from '@mui/icons-material/Roofing'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SettingsIcon from '@mui/icons-material/Settings'
import BusinessIcon from '@mui/icons-material/Business'
import HandymanIcon from '@mui/icons-material/Handyman'
import ListAltIcon from '@mui/icons-material/ListAlt'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Badge } from '@mui/material';
import axios from 'axios'
import './topbar.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import pathToRegexp from 'path-to-regexp'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'

const Topbar = ({ setTheme }) => {
  const location = useLocation()
  const { utilisateur, logout } = useContext(AuthContext)
  const navigate = useNavigate()

  const isPathActive = (path) => {
    const regex = pathToRegexp(path)
    return regex.test(location.pathname)
  }

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  // Pour Delogue
  const handleLogout = () => {
    logout()
    // Efface l'utilisateur du local storage
    localStorage.removeItem('utilisateur')
    navigate('/login')
  }

  // fonction en polling pour recuperer le nombre de message non lu
  const [unreadChatCount, setUnreadChatCount] = useState(0);

  useEffect(() => {
    const fetchUnreadChatCount = async () => {
      try {
        const response = await axios.get('/messages/countUnreadMessages'); 
        setUnreadChatCount(response.data.unreadChatCount); // Mettre à jour l'état avec le nombre de chats non lus
      } catch (error) {
        console.error("Failed to fetch unread messages count:", error);
      }
    };

    fetchUnreadChatCount();
    const interval = setInterval(fetchUnreadChatCount, 300000); // Actualiser toutes les 5 minutes
    return () => clearInterval(interval); // Nettoyage lors du démontage du composant
  }, []);

  console.log("unreadChatCount:", unreadChatCount)

  return (
    <div className="topbar-container">
      <div className="icon-topbar">
        <Tooltip title="menu general" placement="right-start">
          <Link to="/">
            <RoofingIcon
              className={`${isPathActive('/') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        <Tooltip title="affaires" placement="right-start">
          <Link to="/affaires">
            <BusinessIcon
              className={`${isPathActive('/affaires') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        <Tooltip title="taches" placement="right-start">
          <Link to="/taches">
            <HandymanIcon
              className={`${isPathActive('/taches') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        {/*<Tooltip title="Calendrier" placement="right-start">
        <Link to={utilisateur ? `/calendar/${utilisateur._id}` : '#'}>
          <CalendarMonthIcon
            className={`${isPathActive('/calendar') ? 'active-top-bar' : ''} img-top-bar`}
          />
        </Link>
  </Tooltip>*/}
        <Tooltip title="colaborateurs" placement="right-start">
          <Link to="/users">
            <PermIdentityIcon
              className={`${isPathActive('/users') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        <Tooltip title="notifications" placement="right-start">
          <Link to="/notifications">
            <NotificationsNoneIcon
              className={`${
                isPathActive('/notifications') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        {/*<Tooltip title="journal" placement="right-start">
          <Link to="/journal">
            <ReceiptLongIcon
              className={`${
                isPathActive('/journal') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
              </Tooltip>
        <Tooltip title="chat" placement="right-start">
          <Link to="/chat">
            <ChatBubbleOutlineIcon
              className={`${isPathActive('/chat') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>*/}
        <Tooltip title="planning" placement="right-start">
          <Link to="/planning">
            <ListAltIcon
              className={`${isPathActive('/planning') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        <Tooltip title="planning Entreprise" placement="right-start">
          <Link to="/planningentreprise">
            <ListAltIcon
              className={`${isPathActive('/planningentreprise') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
       {/* <Tooltip title="parametres" placement="right-start">
          <SettingsIcon onClick={toggleMenu} className="img-top-bar" />
      </Tooltip>*/}
                <Badge color="error" 
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            badgeContent={unreadChatCount}
            invisible={unreadChatCount === 0}
            sx={{ ".MuiBadge-badge": { right: -3, top: 13, border: `2px solid`, borderColor: `background.paper`, padding: '0 4px' } }}
            >
        <Tooltip title="messagerie" placement="right-start">
          <Link to="/messagerie">
            <ForumOutlinedIcon
              className={`${isPathActive('/messagerie') ? 'active-top-bar' : ''} img-top-bar`}
            />
          </Link>
        </Tooltip>
        </Badge>
      </div>
      <div className="userconnect">
  {/* Affichage conditionnel du nom et du prénom de l'utilisateur si celui-ci est connecté */}
  {utilisateur
    ? <>
      <div className="titleuserco">Mon Espace :</div>
      <div className="userconnect__name">
        <span style={{ marginLeft: '4px' }}>{utilisateur.nom}</span>
        <span style={{ marginLeft: '4px' }}>{utilisateur.prenom}</span>
      </div>

      {/* Icône de déconnexion qui apparaît seulement si l'utilisateur est connecté */}
      <PowerSettingsNewIcon onClick={handleLogout} className="img-top-bar" />
    </>
    : <div>Non connecté</div>
      }
</div>
      {isOpen && (
        <div className="dropdown-menu">
          <button onClick={() => setTheme('green-theme')}>Vert</button>
          <button onClick={() => setTheme('blue-theme')}>Bleu</button>
          <button onClick={() => setTheme('white-theme')}>Blanc</button>
        </div>
      )}
    </div>
  )
}

export default Topbar
