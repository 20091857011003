import { useState, useMemo, useContext } from 'react'
import axios from 'axios'

import './containReceptionMessagerie.scss'
import { AuthContext } from '../../../context/AuthContext'

const ContainReceptionMessagerie = ({ comments, onSelectMessage }) => {
  const [filtreActif, setFiltreActif] = useState('tous')
  const { utilisateur } = useContext(AuthContext)

  const commentairesTriees = useMemo(() => {
    let commentairesFiltres = []
    if (filtreActif === 'nonLu') {
      commentairesFiltres = comments.commentsNonLu
    } else if (filtreActif === 'lu') {
      commentairesFiltres = comments.commentsLu
    } else {
      commentairesFiltres = [...comments.commentsNonLu, ...comments.commentsLu]
    }
    return commentairesFiltres.sort(
      (a, b) => new Date(b.dateCommentaire) - new Date(a.dateCommentaire)
    )
  }, [comments, filtreActif])

  const handleClickOnMessage = async (commentaire) => {
    console.log('handleClickOnMessage', utilisateur._id, commentaire.tacheId, commentaire._id)
    try {
      // Mettre à jour le statut du commentaire et obtenir les détails mis à jour
      const response = await axios.put(
        `/utilisateurs/messagerie/${utilisateur._id}/taches/${commentaire.tacheId}/commentaires/${commentaire._id}/updateStatut`
      )
      const commentaireDetails = response.data

      console.log('Commentaire détails:', commentaireDetails)
      onSelectMessage(commentaireDetails)
    } catch (error) {
      console.error('Erreur lors de la récupération des détails du commentaire', error)
    }
  }

  return (
    <div className="containReceptionMessagerie">
      <div className="filtres">
        <button onClick={() => setFiltreActif('tous')}>Tous</button>
        <button onClick={() => setFiltreActif('nonLu')}>Non Lus</button>
        <button onClick={() => setFiltreActif('lu')}>Lus</button>
      </div>
      <div className="liste-taches-crm">
        {commentairesTriees.map((commentaire) => (
          <div
            key={commentaire._id}
            className={`tache-class-crm ${commentaire.vue ? 'lu' : 'non-lu'}`}
            onClick={() => handleClickOnMessage(commentaire)}
          >
            <span className="user-tache-crm">
              {commentaire.utilisateurId.nom} {commentaire.utilisateurId.prenom}
            </span>
            <div className="nom-et-desc-crm">
              <span className="nom-tache-crm">{commentaire.objetComment}</span>
              <span className="description-tache-crm">{commentaire.commentaire}</span>
            </div>
            <span className="date-tache-crm">
              {new Date(commentaire.dateCommentaire).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' })}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContainReceptionMessagerie
