import SearchIcon from '@mui/icons-material/Search'

import './topBarPage.scss'

const TopBarPage = ({ affaire }) => {
  const isActive = (etat) => {
    const active = affaire && affaire.etat === etat
    return active
  }

  return (
    <div className="topBarPage">
        <div className="left">
            <h1>Gestion de Projet</h1>
        </div>
      <div className="middle">
        <button className={isActive('En préparation') ? 'active' : ''}>En préparation</button>
        <button className={isActive('En cours') ? 'active' : ''}>En cours</button>
        <button className={isActive('Complété') ? 'active' : ''}>Complété</button>
      </div>
        <div className="right">
        <div className="search">
        <SearchIcon className="searchimg" />
        <input
          type="text"
          placeholder="Recherche..."
          className="custom-input"
        />
      </div>
        </div>
    </div>

  )
}

export default TopBarPage
