import { useState, useEffect } from 'react'
import axios from 'axios'
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import './graphiqueAffaireId.scss'

const GraphiqueAffaireId = ({ affaireId }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const chargerDonnees = async () => {
      try {
        const response = await axios.get(`/rapports/affaire/graphique/${affaireId}`)
        setData(response.data)
      } catch (error) {
        console.error('Erreur lors de la récupération des données du graphique', error)
      }
    }
    chargerDonnees()
  }, [affaireId])

  return (
    <ComposedChart width={800} height={400} data={data}>
      <XAxis dataKey="date" />
      <YAxis yAxisId="left" />
      <YAxis yAxisId="right" orientation="right" />
      <Tooltip />
      <Legend />
      <Bar yAxisId="left" dataKey="heuresTravaillees" fill="#8884d8" name="Heures Travaillées" />
      <Bar yAxisId="left" dataKey="tachesCompletees" fill="#82ca9d" name="Tâches Exécutées" />
      <Line yAxisId="right" type="monotone" dataKey="progression" stroke="#ff7300" name="Avancement" />
    </ComposedChart>
  )
}

export default GraphiqueAffaireId
