import { useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../../components/sidebar/Sidebar'
import { affairePlanningIdMenu } from '../../variables/sidebar/topMenu'
import './affairePlanningId.scss'
import useFetch from '../../hooks/useFetch'
import { useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import AffichageTachePlanning from '../../components/tachePlanning/affichageTachePlanning/AffichageTachePlanning'

const AffairePlanningId = () => {
  const { id } = useParams()
  const { data: affairedata } = useFetch(`/affaires/${id}`)
  const { data: tachesdata, reFetch } = useFetch(`/taches/byAffaire/${id}`)
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(0)

  const handleGoBack = () => {
    navigate(-1)
  }
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  // laisse ca ici pour le moment
  const menu = affairePlanningIdMenu(id, handleGoBack)
  return (
    <div className="AffairePlanningId">
      <div className="container">
        <div className="sidebar">
          <Sidebar menu={menu} />
        </div>
        <div className="containerAffairePlanningId">
            <div className="topcontainerAffairePlanningId">
            <div className='titleAffairePlanningId'>VOUS ETES SUR LA FEUILLE DE : {affairedata.nomAffaire}</div>
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
                <Tab label="tache planning" />
            </Tabs>

            </div>
            <div className="test">
            <Box>
                {selectedTab === 0 && <AffichageTachePlanning affaireId={id} taches={tachesdata} reFetch={reFetch}/>}
            </Box>

            </div>

            </div>
      </div>
    </div>
  )
}

export default AffairePlanningId
