import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import "./tacheAndDecalage.scss"

const TacheAndDecalage = ({ affaireId, selectedTache, refreshTaches, onFormSubmit }) => {
    const [decalageJours, setDecalageJours] = useState('')
    const [dateArret, setDateArret] = useState('')

      // Fonction pour formater la date en JJ-MM-AAAA
  const formatDate = (date) => {
    const d = new Date(date)
    const day = '' + d.getDate()
    const month = '' + (d.getMonth() + 1)
    const year = d.getFullYear()

    return [day.padStart(2, '0'), month.padStart(2, '0'), year].join('-')
  }

    // Utiliser la date du jour comme valeur par défaut
    useEffect(() => {
        const todayFormatted = formatDate(new Date())
        setDateArret(todayFormatted)
      }, [])

      const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
          selectedTache,
          dateArret, // La date peut être modifiée par l'utilisateur
          dateModification: formatDate(new Date()), // Toujours la date du jour
          decalageJours,
        }
    
        try {
            await axios.put(`/taches/planning/tachedecalage/${selectedTache._id}`, data)

      refreshTaches() // Rafraîchir la liste des tâches
      await updateAffaireDates(affaireId)
      onFormSubmit() // Fermer le formulaire
    } catch (error) {
      console.error("Erreur lors de l'appel API", error)
    }
  }
  // Fonction pour mettre à jour les dates de l'affaire
  const updateAffaireDates = async (affaireId) => {
    try {
      const reponse = await axios.get(`/affaires/datesreelavectaches/${affaireId}`)
      console.log("Mise à jour des dates de l'affaire", reponse.data)
    } catch (error) {
      console.error(`Erreur lors de la mise à jour des dates de l'affaire ${affaireId}`, error)
    }
  }

  return (
    <div className='TacheAndDecalage'>

<label>Jours de décalage : Indiquez le nombre de jours (positif ou négatif). Les tâches suivantes seront également décalées.</label>
<input type="number" value={decalageJours} onChange={(e) => setDecalageJours(e.target.value)} />
<button onClick={handleSubmit}>Soumettre</button>
    </div>
  )
}

export default TacheAndDecalage