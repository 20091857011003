import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import useFetch from '../../../hooks/useFetch';
import Modal from '../../modules/ModalZindex/ModalZindex';
import useModal from '../../modules/Modal/useModal';
import ElementId from '../../elements/elementId/ElementId';
import './tachesNonCommence.scss';

const TachesNonCommence = () => {
  const { utilisateur } = useContext(AuthContext);
  const { data: listeAFaire, loading ,reFetch} = useFetch(`/affaires/listes/afaire/affaires`);
  const [filtreAffaire, setFiltreAffaire] = useState('');
  const { isModalOpen, openModal, closeModal } = useModal();
  const [activeElementId, setActiveElementId] = useState(null);
  const navigate = useNavigate();

  const handleElementClick = (elementId) => {
    setActiveElementId(elementId);
    openModal();
  };

  if (loading) return <div>Chargement...</div>;

  const affairesFiltrees = listeAFaire.filter(affaire =>
    affaire.nomAffaire.toLowerCase().includes(filtreAffaire.toLowerCase()) &&
    affaire.listes.some(liste => liste.elements.some(element => element.etatRealisation === 'Non Commencé'))
  );

  const modalContent = (
    <ElementId elementId={activeElementId} closeModal={closeModal} reFetch={reFetch}/>
  );

  return (
    <div className="TachesNonCommence">
      <div className="header-TachesNonCommence">
        <h1>Les tâches À Faire :</h1>
        <input
          type="text"
          placeholder="Filtrer par nom d'affaire"
          value={filtreAffaire}
          onChange={(e) => setFiltreAffaire(e.target.value)}
        />
      </div>
      <div className="container-TachesNonCommence">
        {affairesFiltrees.map(affaire => (
          <div key={affaire._id} className="affaire-details">
            <h2 onClick={() => navigate(`/affaires/${affaire._id}`)}>
              {affaire.nomAffaire}
            </h2>
            {affaire.listes.map(liste => (
              liste.elements.map(element => element.etatRealisation === 'Non Commencé' && (
                <div key={element._id} className="element-details" onClick={() => handleElementClick(element._id)}>
                  <div className="top-element-details">
                    <p className="nom-element">Nom: <span className="element-value">{element.nom}</span></p>
                    <p className="etat-element etat-non-commence">Etat: {element.etatRealisation}</p>
                  </div>
                  {element.description && (
                    <div className="bottom-element-details">
                      <p className="nom-element">Description: <span className="element-value">{element.description}</span></p>
                    </div>
                  )}
                </div>
              ))
            ))}
          </div>
        ))}
      </div>
      <Modal
        title="Détail de l'élément"
        content={modalContent}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
}

export default TachesNonCommence;
