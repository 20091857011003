import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';

function DownloadAsReference({ listeId , listeDetails }) {
  const [open, setOpen] = useState(false);
  const [titre, setTitre] = useState('');

  console.log("listeDetails", listeDetails)
  console.log("listeId", listeId)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTitre('');  // Reset the title when closing the dialog
  };
  
  const handleCreate = async () => {
    console.log("titre:", titre);
    console.log("listeDetails:", listeDetails);
  
    if (titre && listeDetails && Array.isArray(listeDetails) && listeDetails.length > 0) {
      try {
        // Préparer les éléments pour le clonage en ne conservant que les champs nécessaires
        const elementsToClone = listeDetails.map(el => ({
          titreElement: el.titreElement,
          description: el.description
        }));
  
        console.log("elementsToClone:", elementsToClone);
  
        // Appel API pour créer une liste de référence
        const response = await axios.post('/listeCheck/createReference', {
          titre,
          elements: elementsToClone,
          isReference: true
        });
        
        if (response.status === 201) {
          console.log("Liste de référence créée avec succès:", response.data);
          handleClose(); // Ferme le dialogue
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          alert("Erreur : Une liste de référence avec ce titre existe déjà pour cette entreprise. Veuillez choisir un autre titre.");
        } else {
          console.error('Erreur lors de la création de la liste de référence', error);
        }
      }
    } else {
      console.error("Les données nécessaires ne sont pas disponibles ou incomplètes.");
    }
  };
  

  return (
    <div>
      <IconButton onClick={handleClickOpen} color="primary" aria-label="download as reference">
        <FileDownloadIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Donner un titre à la liste de référence</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="titre"
            label="Titre de la liste"
            type="text"
            fullWidth
            value={titre}
            onChange={(e) => setTitre(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleCreate} color="primary">Créer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DownloadAsReference;
