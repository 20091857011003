import Sidebar from '../../components/sidebar/Sidebar'
import { notificationsMenu } from '../../variables/sidebar/topMenu'
import ExpiringFiles from '../Home/components/expiringFiles'

import './notifications.scss'

const Notifications = () => {
  return (
        <div className="notifications">
          <div className="container-notifications">
            <div className="sidebar">
              <Sidebar menu={notificationsMenu}/>
            </div>
            <div className="containerNotifications">
              <h1>Notifications</h1>
              <ExpiringFiles />
            </div>
          </div>
        </div>
  )
}

export default Notifications
