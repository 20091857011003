import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Checkbox, FormControlLabel, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import './listeDetails.scss';
import ManageReferenceList from '../ManageReferenceList/ManageReferenceList';
import DownloadAsReference from '../DownloadAsReference/DownloadAsReference';
import Tooltip from '@mui/material/Tooltip';
import DeleteListButton from '../DeleteListButton/DeleteListButton';

function ListeDetails({ listeId, refreshListes }) {
        const [listeDetails, setListeDetails] = useState({ titre: '', elements: [] , referenceListId: ''});
        const [formData, setFormData] = useState({ titreElement: '', description: '', fait: false, dateRealisation: '' });
        const [showForm, setShowForm] = useState(false);
        const [editElement, setEditElement] = useState(null);
      
        useEffect(() => {
          fetchListeDetails();
        }, [listeId]);
      
        const fetchListeDetails = async () => {
          try {
            const response = await axios.get(`/listeCheck/${listeId}`);
            setListeDetails(response.data);
          } catch (error) {
            console.error('Erreur lors de la récupération des détails de la liste', error);
          }
        };
      
        const handleShowForm = (element = null) => {
            if (element) {
              const formattedDate = element.dateRealisation ? format(new Date(element.dateRealisation), 'yyyy-MM-dd') : '';
              setFormData({
                titreElement: element.titreElement || '',
                description: element.description || '',
                fait: element.fait || false,
                dateRealisation: formattedDate
              });
              setEditElement(element._id);
            } else {
              setFormData({ titreElement: '', description: '', fait: false, dateRealisation: '' });
              setEditElement(null);
            }
            setShowForm(true);
          };
      
          const handleSubmit = async (event) => {
            event.preventDefault();
            const method = editElement ? 'put' : 'post';
            const url = `/listeCheck/${listeId}/elements${editElement ? `/${editElement}` : ''}`;
            try {
              await axios[method](url, formData);
              fetchListeDetails();
              await refreshListes();
              setEditElement(null); // Réinitialiser le formulaire
              setFormData({ titreElement: '', description: '', fait: false, dateRealisation: '' });
            } catch (error) {
              console.error('Erreur lors de la mise à jour de élément', error);
            }
          };


  const handleDeleteClick = async (elementId) => {
    console.log('elementId', elementId);
    // Afficher la boîte de dialogue de confirmation
    if (window.confirm("Voulez-vous vraiment supprimer cet élément ?")) {
        try {
            await axios.delete(`/listeCheck/${listeId}/elements/${elementId}`);
            fetchListeDetails(); // Recharger la liste après suppression
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'élément', error);
        }
    } else {
        console.log("Suppression annulée");
    }
};

  return (
    <div className="liste-details">
      <h2>{listeDetails.titre}</h2>
      <div className="top-liste-details">
      <Button onClick={() => handleShowForm()} variant="outlined" color="primary">
        Ajouter un nouvel élément
      </Button>
      <div className="right-liste-details">
      {listeDetails.referenceListId && (
  <Tooltip title="Modifier la liste référence">
    <div>
      <ManageReferenceList listeId={listeDetails.referenceListId} />
    </div>
  </Tooltip>
)}
<Tooltip title="Faire de cette liste une liste référence">
  <div>
    <DownloadAsReference listeId={listeId} listeDetails={listeDetails.elements} />
  </div>
</Tooltip>
<Tooltip title="Supprimer cette liste">
  <div>
    <DeleteListButton listeId={listeId} listeDetails={listeDetails.elements} />
  </div>
</Tooltip>
          </div>
          </div>
      {showForm && (
        <div className="form-container">
          <form onSubmit={(e) => {
            handleSubmit(e, formData, setFormData);
            setShowForm(false);
          }}>
            <TextField
              label="Titre de l'élément"
              variant="outlined"
              fullWidth
              value={formData.titreElement}
              onChange={(e) => setFormData({ ...formData, titreElement: e.target.value })}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.fait}
                  onChange={(e) => setFormData({ ...formData, fait: e.target.checked })}
                />
              }
              label="Fait"
            />
            <TextField
              type="date"
              label="Date de réalisation"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={formData.dateRealisation}
              onChange={(e) => setFormData({ ...formData, dateRealisation: e.target.value })}
            />
                <Button type="submit" variant="contained" color="primary">
                {editElement ? 'Modifier' : 'Ajouter'}
                </Button>
            <Button onClick={() => setShowForm(false)} variant="text">Annuler</Button>
          </form>
        </div>
      )}
      <div className="elements-list">
        {listeDetails.elements.map((element) => (
          <div key={element._id} className="element-item">
            <div className="element-header">
              <Checkbox checked={element.fait} disabled />
              <span className='titre-element'>{element.titreElement || 'Sans titre'}</span>
              {element.dateRealisation && (
                <span className="date-realisation">
                  {new Date(element.dateRealisation).toLocaleDateString()}
                </span>
              )}
              <div className="actions">
                <IconButton onClick={() => handleShowForm(element)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(element._id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
            {element.description && <p className="description">{element.description}</p>}
          </div>
        ))}
      </div>
    </div>
  );
}


export default ListeDetails;
