import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';

function DeleteListButton({ listeId }) {
  // Fonction pour gérer la suppression de la liste
  const handleDelete = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette liste ? Cette action est irréversible.")) {
      try {
        // Appel API pour supprimer la liste
        const response = await axios.delete(`/listeCheck/${listeId}`);
        if (response.status === 200) {
          console.log("Liste supprimée avec succès");
            // Recharger la page pour afficher les changements
            window.location.reload();
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de la liste', error);
      }
    }
  };

  return (
    <Tooltip title="Supprimer cette liste">
      <IconButton onClick={handleDelete} color="error" aria-label="delete">
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}

export default DeleteListButton;
