import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import { exportPDF } from '../Export/pdf/ExportPdf/ExportPdf'
import { AuthContext } from '../../context/AuthContext'
import 'react-datepicker/dist/react-datepicker.css'
import './timeSheet.scss'

const TimeSheet = ({ userId, utilisateur }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [userActivities, setUserActivities] = useState([])
  const [totalHours, setTotalHours] = useState(0)
  const { utilisateur: contextUser } = useContext(AuthContext)
  // const [dateRangeType, setDateRangeType] = useState('semaine')
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek())
  const [selectionMode, setSelectionMode] = useState('custom')

  function getCurrentWeek () {
    const currentDate = new Date()
    const startDate = new Date(currentDate.getFullYear(), 0, 1)
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) + 1
    return Math.ceil(days / 7)
  }

  const fetchData = async () => {
    if (!userId) return
    const formattedStartDate = startDate.toISOString().split('T')[0]
    const formattedEndDate = endDate.toISOString().split('T')[0]
    console.log(
      'Fetching data for dates:',
      formattedStartDate,
      'to',
      formattedEndDate
    )

    try {
      const response = await axios.get(
        `/utilisateurs/rapports/${userId}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      console.log('Response data:', response.data)

      const activitiesWithHours = response.data.map((activity) => {
        const filteredTeamMemberHours = activity.teamMemberHours.filter(
          (hour) => hour.utilisateurId === userId
        )
        console.log(
          'Filtered teamMemberHours for userId:',
          userId,
          filteredTeamMemberHours
        )

        const processedTeamMemberHours = filteredTeamMemberHours.map((hour) => {
          const hoursWorked = calculateWorkingHours(hour.arrivee, hour.depart)
          return {
            ...hour,
            workedHours: hoursWorked,
            workedHoursFormatted: convertHoursToHHMM(hoursWorked)
          }
        })
        return { ...activity, teamMemberHours: processedTeamMemberHours }
      })

      console.log('Final activitiesWithHours:', activitiesWithHours)
      setUserActivities(activitiesWithHours)
      calculateTotalHours(activitiesWithHours)
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des activités de l'utilisateur",
        error
      )
    }
  }

  const calculateWorkingHours = (startTime, endTime) => {
    const start = new Date(startTime)
    const end = new Date(endTime)
    let diff = (end - start) / (1000 * 60 * 60)
    if (diff > 6) diff -= 1 // Deduct lunch time
    return diff > 0 ? diff : 0
  }

  const convertHoursToHHMM = (hoursDecimal) => {
    const hours = Math.floor(hoursDecimal)
    const minutes = Math.floor((hoursDecimal - hours) * 60)
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`
  }

  const calculateTotalHours = (activities) => {
    const total = activities.reduce((acc, activity) => {
      const activityTotal = activity.teamMemberHours.reduce(
        (accHour, hour) => accHour + hour.workedHours,
        0
      )
      return acc + activityTotal
    }, 0)
    setTotalHours(convertHoursToHHMM(total))
  }

  const handleExportPDF = () => {
    exportPDF(userActivities, contextUser.entreprise, utilisateur, startDate, endDate)
  }

  const calculateDateRange = () => {
    let start, end
    if (selectionMode === 'semaine') {
      const firstDayOfYear = new Date(selectedYear, 0, 1)
      const days = (selectedWeek - 1) * 7
      start = new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() + days))
      end = new Date(start)
      end.setDate(end.getDate() + 6)
    } else if (selectionMode === 'mois') {
      start = new Date(selectedYear, selectedMonth, 1)
      end = new Date(selectedYear, selectedMonth + 1, 0)
    } else {
      // Pas besoin de calculer pour la sélection personnalisée
      return
    }
    setStartDate(start)
    setEndDate(end)
  }

  const handleValidation = () => {

    if (selectionMode !== 'custom') {
      calculateDateRange();
    }
    // Assurez-vous que les fonctions appelées ici synchronisent correctement les dates avant d'appeler fetchData.
    fetchData();
  };

  console.log('userActivities', userActivities)

  const renderActivitiesTable = () => {
    return (
      <div className="tableau-time-user">
        <table className="table-time-user">
          <thead className="thead-time-user">
            <tr className="tr-time-user">
              <th className="th-time-user">Date</th>
              <th className="th-time-user">{"Nom de l'Affaire"}</th>
              <th className="th-time-user">{"Heure d'Arrivée"}</th>
              <th className="th-time-user">Heure de Départ</th>
              <th className="th-time-user">Heures Travaillées</th>
            </tr>
          </thead>
          <tbody className="tbody-time-user">
            <tr className="tr-time-user">
              <td colSpan="3" className="td-time-user">
                Période : {startDate.toLocaleDateString()} -{' '}
                {endDate.toLocaleDateString()}
              </td>
              <td colSpan="2" className="td-time-user">
                Total des heures : {totalHours} h
              </td>
            </tr>
            {userActivities.map((activity) =>
              activity.teamMemberHours.map((hour) => (
                <tr key={hour._id} className="tr-result-time-user">
                  <td className="td-result-time-user">
                    {new Date(activity.date).toLocaleDateString()}
                  </td>
                  <td className="td-result-time-user">
                    {activity.affaireId.nomAffaire}
                  </td>
                  <td className="td-result-time-user">
                    {new Date(hour.arrivee).toLocaleTimeString()}
                  </td>
                  <td className="td-result-time-user">
                    {new Date(hour.depart).toLocaleTimeString()}
                  </td>
                  <td className="td-result-time-user">
                    {convertHoursToHHMM(
                      calculateWorkingHours(hour.arrivee, hour.depart)
                    )}{' '}
                    h
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="timesheet">
      <div className="top-contain-timesheet">
        <div className="title-time-user">
          <h2>
            {"Activité de l'Utilisateur:"} {utilisateur.nom} {utilisateur.prenom}
          </h2>
        </div>
        <div className="contain-timesheet">
          <div className="left-contain-timesheet">
          <span>Définissez la période à visualiser :</span>
          <div className="select-choix-timesheet">
            <select onChange={(e) => setSelectionMode(e.target.value)}>
              <option value="custom">Par plage personnalisée</option>
              <option value="semaine">Par Semaine</option>
              <option value="mois">Par Mois</option>
            </select>
          </div>
          {selectionMode === 'custom' && (
            <div className="date-time-user">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd-MM-yyyy"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd-MM-yyyy"
              />
            </div>
          )}
          {selectionMode === 'mois' && (
            <div className="select-custom-mois-timesheet">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              >
                {[...Array(12).keys()].map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month).toLocaleString('fr', { month: 'long' })}
                  </option>
                ))}
              </select>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              >
                {[...Array(5).keys()].map((i) => {
                  const year = new Date().getFullYear() - i
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {selectionMode === 'semaine' && (
            <div className="select-custom-semaine-timesheet">
              <input
                type="number"
                value={selectedWeek}
                onChange={(e) => setSelectedWeek(parseInt(e.target.value))}
                min="1"
                max="52"
              />
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              >
                {[...Array(5).keys()].map((i) => {
                  const year = new Date().getFullYear() - i
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          </div>
          <div className="right-contain-timesheet">
            <button onClick={handleValidation} className="btn-time-user">
              Valider
            </button>
            <button onClick={handleExportPDF} className="btn-time-user">
              Exporter en PDF
            </button>
          </div>
        </div>
      </div>
      {renderActivitiesTable()}
    </div>
  )
}

export default TimeSheet
