import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import "./timesheet.scss";
import { AuthContext } from '../../context/AuthContext';
import useFetch from '../../hooks/useFetch';
import exportToPDF from '../../components/Export/pdf/ExportPdf/FeuilleHExportPdf';

const timesheet = () => {
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek())
  const [selectionMode, setSelectionMode] = useState('custom')
  const [totalHours, setTotalHours] = useState(0)
  const [userActivities, setUserActivities] = useState([])
  const [editingId, setEditingId] = useState(null); // ID de l'activité en cours de modification
  const [newHours, setNewHours] = useState({});
  const { utilisateur: { entreprise } } = useContext(AuthContext)
  const { data: entreprisedata, error } = useFetch(`/entreprises/${entreprise.id}`)
  const { data: utilisateurdata } = useFetch(`/utilisateurs/${selectedUser}`)


  function getCurrentWeek () {
    const currentDate = new Date()
    const startDate = new Date(currentDate.getFullYear(), 0, 1)
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) + 1
    return Math.ceil(days / 7)
  }

  useEffect(() => {
    const chargerUtilisateurs = async () => {
      try {
        //  catégories désirées :
        const categories = 'ouvrierTerrain,chefAssignes';
        const reponse = await axios.get(`/utilisateurs?categories=${categories}`);
        
        setUtilisateurs(reponse.data);
      } catch (erreur) {
        console.error('Il y a eu un problème avec la requête Axios', erreur);
      }
    };
   
    chargerUtilisateurs();
  }, []);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, selectedUser]);
  
  const fetchData = async () => {
    try {
      const response = await axios.get(`/taches/affectees/phone/${selectedUser}`);
      const tachesAffectees = response.data;
  console.log('Tâches affectées:', tachesAffectees)
      // Créer un tableau pour stocker les activités de l'utilisateur
      let activitesUtilisateur = [];
  
      tachesAffectees.forEach(tache => {
        if (Array.isArray(tache.affectations)) {
          tache.affectations.forEach(affectation => {
          // Vérifiez si l'affectation concerne la plage de dates
          if (new Date(affectation.date) >= startDate && new Date(affectation.date) <= endDate) {
            const roles = affectation.chefs.concat(affectation.ouvriers);
            const roleUtilisateur = roles.find(role => role.utilisateur._id === selectedUser);
            if (roleUtilisateur) {
              // Créer un objet d'activité pour chaque date d'affectation de l'utilisateur
              const activite = {
                date: affectation.date, // Utilisez directement la date de l'affectation
                nomAffaire: tache.nomAffaire,
                nomTache: tache.nomTache,
                heures: roleUtilisateur.heures,
                idTache: tache._id,
                idAffaire: tache.affaireId,
                zone: tache.zone,
                idUtilisateur: selectedUser
              };
              activitesUtilisateur.push(activite);
            }
          }
        
      });
    } else {
      console.warn('Aucune affectation trouvée ou tache.affectations n\'est pas un tableau pour la tâche:', tache);
    }
  });
  
      // Trier les activités par date
      activitesUtilisateur.sort((a, b) => new Date(a.date) - new Date(b.date));
  
      // Mettre à jour le state avec les activités filtrées et formatées
      setUserActivities(activitesUtilisateur);
    } catch (erreur) {
      console.error('Erreur lors de la récupération des données:', erreur);
    }
  };
  
  //choix des date par raport au choix 
  const calculateDateRange = () => {
    let start, end
    if (selectionMode === 'semaine') {
      const firstDayOfYear = new Date(selectedYear, 0, 1)
      const days = (selectedWeek - 1) * 7
      start = new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() + days))
      end = new Date(start)
      end.setDate(end.getDate() + 6)
    } else if (selectionMode === 'mois') {
      start = new Date(selectedYear, selectedMonth, 1)
      end = new Date(selectedYear, selectedMonth + 1, 0)
    } else {
      // Pas besoin de calculer pour la sélection personnalisée
      return
    }
    setStartDate(start)
    setEndDate(end)
  }

  const handleValidation = () => {
    if (!selectedUser) {
      // Affichez une alerte demandant à l'utilisateur de sélectionner un utilisateur
      alert('Veuillez sélectionner un utilisateur.');
      return; // Arrêtez l'exécution de la fonction ici pour ne pas poursuivre sans sélection
    }
  
    if (selectionMode !== 'custom') {
      calculateDateRange();
    }
    // Assurez-vous que les fonctions appelées ici synchronisent correctement les dates avant d'appeler fetchData.
    fetchData();
  };


  // Fonction pour mettre à jour les heures de l'utilisateur
  const handleSave = async (combinedId) => {
    const [idTache, date] = combinedId.split('_');
    const timeValue = newHours[combinedId];
    const [hours, minutes] = timeValue.split(':').map(Number);
    const dureeEnSecondes = (hours * 3600) + (minutes * 60);
  
    //preparation des données pour la mise à jour de la tâche
    const tacheUpdateData = {
      dureeEnSecondes: dureeEnSecondes,
      teamMembers:  [selectedUser],
      date: date
  };
    try {
      console.log('Données de mise à jour de la tâche:', tacheUpdateData)
      const response = await axios.put(`/taches/update/affectationsPhone/${idTache}`, tacheUpdateData);
      console.log('Tâche mise à jour:', response.data);
      setEditingId(null); // Quitter le mode édition
       // Mettre à jour l'état local pour refléter les heures mises à jour
       const updatedActivities = userActivities.map(activity => {
        if (activity.idTache === idTache && new Date(activity.date).toISOString().slice(0, 10) === date) {
          return {
            ...activity,
            heures: dureeEnSecondes / 3600 // Conversion en heures
          };
        }
        return activity;
      });
      setUserActivities(updatedActivities); // Mise à jour de l'état avec les activités modifiées

    } catch (error) {
      console.error('Erreur lors de la mise à jour de la tâche:', error);
      // Gérer l'erreur ici...
    }
  };


  // Fonction pour calculer le total des heures
  const totalHeures = () => {
    const totalSecondes = userActivities.reduce((total, activity) => total + activity.heures * 3600, 0);
    const heures = Math.floor(totalSecondes / 3600);
    const minutes = Math.floor((totalSecondes % 3600) / 60);
    return `${heures}h ${minutes}m`;
  };


  // Fonction pour générer une plage de dates
  const generateDateRange = (start, end) => {
    let arr = [];
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr.filter(day => day.getDay() !== 0 && day.getDay() !== 6); // Exclut les samedis (6) et dimanches (0)
  };


// Fonction pour générer les jours sans activité
  const daysWithNoActivity = () => {
    const allDays = generateDateRange(startDate, endDate);
    const activeDays = userActivities.map(activity => new Date(activity.date).toDateString());

    return allDays.filter(day => !activeDays.includes(day.toDateString()))
                  .map(day => ({
                    date: day,
                    message: "Aucune affectation présente ce jour-là",
                    isActivity: false
                  }));
  };
// Fonction pour combiner les activités et les jours sans activité
  const combinedActivities = () => {
    const noActivities = daysWithNoActivity();
    const combined = [...userActivities.map(activity => ({
      ...activity,
      date: new Date(activity.date),
      isActivity: true
    })), ...noActivities];

    combined.sort((a, b) => a.date - b.date);
    return combined;
  };


  console.log('l\'utilisateur:', utilisateurs)
  console.log("entreprise", entreprisedata)
  return (
    <div className='timesheet-page'>
        <div className="contain-timesheet-page">
          <div className="top-timesheet-page">
            <FormControl fullWidth style={{ marginTop: 4 }}>
            <InputLabel id="user-label">Utilisateur</InputLabel>
            <Select
              labelId="user-label"
              value={selectedUser}
              onChange={e => setSelectedUser(e.target.value)}
              input={<OutlinedInput label="Utilisateur" />}
            >
              {utilisateurs.map(user => <MenuItem key={user._id} value={user._id}>{`${user.prenom} ${user.nom}`}</MenuItem>)}
            </Select>
            </FormControl>
          </div>
          <div className="left-contain-timesheet-page">
          <span>Définissez la période à visualiser :</span>
          <div className="select-choix-timesheet-page">
            <select onChange={(e) => setSelectionMode(e.target.value)}>
              <option value="custom">Par plage personnalisée</option>
              <option value="semaine">Par Semaine</option>
              <option value="mois">Par Mois</option>
            </select>
          </div>
          {selectionMode === 'custom' && (
            <div className="date-time-user">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd-MM-yyyy"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd-MM-yyyy"
              />
            </div>
          )}
          {selectionMode === 'mois' && (
            <div className="select-custom-mois-timesheet">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              >
                {[...Array(12).keys()].map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month).toLocaleString('fr', { month: 'long' })}
                  </option>
                ))}
              </select>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              >
                {[...Array(5).keys()].map((i) => {
                  const year = new Date().getFullYear() - i
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          {selectionMode === 'semaine' && (
            <div className="select-custom-semaine-timesheet">
              <input
                type="number"
                value={selectedWeek}
                onChange={(e) => setSelectedWeek(parseInt(e.target.value))}
                min="1"
                max="52"
              />
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              >
                {[...Array(5).keys()].map((i) => {
                  const year = new Date().getFullYear() - i
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          </div>
          <div className="right-contain-timesheet">
            <button onClick={handleValidation} className="btn-time-user">
              Valider
            </button>
            <button className="btn-time-user" onClick={() => exportToPDF(userActivities, entreprisedata, utilisateurdata, selectedWeek, startDate, endDate)}>Exporter en PDF</button>
          </div>
        </div>
        <div className="contain-table-timesheet-page">
        <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Nom de l'affaire</th>
            <th>Nom de la tâche</th>
            <th>Zone</th>
            <th>Heures</th>
          </tr>
        </thead>
        <tbody>
          {combinedActivities().map((item, index) => (
            <tr key={index}>
              <td>{item.date.toLocaleDateString()}</td>
              {item.isActivity ? (
                <>
                  <td>{item.nomAffaire}</td>
                  <td>{item.nomTache}</td>
                  <td>{item.zone}</td>
                  <td>
                    {editingId === `${item.idTache}_${item.date.toISOString().slice(0, 10)}` ? (
                      <input
                        type="time"
                        value={newHours[`${item.idTache}_${item.date.toISOString().slice(0, 10)}`] || `${Math.floor(item.heures).toString().padStart(2, '0')}:${Math.round((item.heures % 1) * 60).toString().padStart(2, '0')}`}
                        onChange={(e) => setNewHours({
                          ...newHours,
                          [`${item.idTache}_${item.date.toISOString().slice(0, 10)}`]: e.target.value
                        })}
                      />
                    ) : (
                      `${Math.floor(item.heures)}h ${Math.round((item.heures % 1) * 60).toString().padStart(2, '0')}m`
                    )}
                  </td>
                  <td>
                    {editingId === `${item.idTache}_${item.date.toISOString().slice(0, 10)}` ? (
                      <button onClick={() => handleSave(`${item.idTache}_${item.date.toISOString().slice(0, 10)}`)}>Valider</button>
                    ) : (
                      <button onClick={() => setEditingId(`${item.idTache}_${item.date.toISOString().slice(0, 10)}`)}>Modifier</button>
                    )}
                  </td>
                </>
              ) : (
                <td colSpan="4">{item.message}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
        <div className="total-hours-display">
          <span>Total des heures : {totalHeures()}</span>
        </div>
    </div>
  )
}

export default timesheet