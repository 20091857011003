import { useEffect, useState } from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import './tacheListCondencer.scss'

const TacheListCondencer = ({ taches, onEdit, onUpdateFilteredTaches }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTypes, setSelectedTypes] = useState([])
  const [sortCriteria, setSortCriteria] = useState('dateDepartCroissant')
  const [statusFilter, setStatusFilter] = useState('toutes')
  const [filteredTaches, setFilteredTaches] = useState([])
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleTypeChange = (event) => {
    setSelectedTypes(event.target.value)
  }

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value)
  }

  const sortTaches = (taches) => {
    switch (sortCriteria) {
      case 'dateDepartCroissant':
        return taches.sort(
          (a, b) => new Date(a.dateDepart) - new Date(b.dateDepart)
        )
      case 'dateDepartDecroissant':
        return taches.sort(
          (a, b) => new Date(b.dateDepart) - new Date(a.dateDepart)
        )
      case 'dureeCroissante':
        return taches.sort((a, b) => a.duration - b.duration)
      case 'dureeDecroissante':
        return taches.sort((a, b) => b.duration - a.duration)
      default:
        return taches
    }
  }

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value)
  }

  useEffect(() => {
    const newFilteredTaches = sortTaches(
      taches.filter((tache) => {
        const statusMatch =
          (statusFilter === 'faites' && tache.statut.fait) ||
          (statusFilter === 'nonFaites' && !tache.statut.fait) ||
          statusFilter === 'toutes'
        const nameMatch = tache.nomTache
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
        const typeMatch =
          selectedTypes.length === 0 || selectedTypes.includes(tache.typeTache)

        return statusMatch && nameMatch && typeMatch
      })
    )

    setFilteredTaches(newFilteredTaches)
  }, [taches, searchTerm, selectedTypes, sortCriteria, statusFilter])

  useEffect(() => {
    onUpdateFilteredTaches(filteredTaches)
  }, [filteredTaches, onUpdateFilteredTaches])

  function formatDate (dateString) {
    if (!dateString) return ''
    const date = new Date(dateString)
    if (isNaN(date)) return ''

    let day = date.getDate().toString()
    let month = (date.getMonth() + 1).toString()
    const year = date.getFullYear()

    day = day.length < 2 ? '0' + day : day
    month = month.length < 2 ? '0' + month : month

    return `${day}-${month}-${year}`
  }

  return (
    <div className="TacheListCondencer">
      <div className="topTacheListCondencer">
        <div className="searchTacheListCondencer">
          <TextField
            id="search-field"
            label="Recherche"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-field-tlc"
          />

          <FormControl margin="normal" className="form-control">
            <InputLabel id="type-select-label">Type de Tâche</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              multiple
              value={selectedTypes}
              onChange={handleTypeChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {[
                'A Prendre',
                'Chantier',
                'Bureau',
                'Administratif',
                'Technique',
                'sous traitant'
              ].map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* Filtre par Statut */}

        <div className="TacheListCondencer">
          <select onChange={handleSortChange} defaultValue="">
            <option value="" disabled>
              Trier les tâches
            </option>
            <option value="dateDepartCroissant">
              Date de départ croissante
            </option>
            <option value="dateDepartDecroissant">
              Date de départ décroissante
            </option>
            <option value="dureeCroissante">Durée croissante</option>
            <option value="dureeDecroissante">Durée décroissante</option>
          </select>

          <select onChange={handleStatusFilterChange} defaultValue="toutes">
            <option value="toutes">Toutes les tâches</option>
            <option value="faites">Tâches faites</option>
            <option value="nonFaites">Tâches non faites</option>
          </select>
        </div>
      </div>

      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Nom</th>
              <th style={{ width: '15%' }}>Type</th>
              <th style={{ width: '20%' }}>Date Dép</th>
              <th style={{ width: '20%' }}>Durée</th>
              <th style={{ width: '20%' }}>Nbr</th>
              <th style={{ width: '20%' }}>Réal</th>
              <th style={{ width: '20%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTaches.map((tache) => (
              <tr key={tache._id}>
                <td className="nom-tache-tlc" style={{ width: '40%' }}>
                  {tache.nomTache}
                </td>
                <td style={{ width: '15%' }}>{tache.typeTache}</td>
                <td style={{ width: '20%' }}>{formatDate(tache.dateDepart)}</td>
                <td style={{ width: '20%' }}>{tache.duration}</td>
                <td style={{ width: '20%' }}>{tache.nombreDeUsers}</td>
                <td>
                  <input type="checkbox" checked={tache.fait} readOnly />
                </td>
                <td style={{ width: '20%' }}>
                  <button className="btn-modif-tlc" onClick={() => onEdit(tache)}>Modifier</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TacheListCondencer
