import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import AddCommentIcon from '@mui/icons-material/AddComment'

import './sideBarMessagerie.scss'
import ModalEnvoyerMessage from '../../boiteDEnvoie/modalEnvoyerMessage/ModalEnvoyerMessage'
import EnvoyerMessage from '../../boiteDEnvoie/envoyerMessage/EnvoyerMessage'

const SideBarMessagerie = ({
  setActiveTab,
  activeTab,
  resetSelectedMessage
}) => {
  // sidebar
  const [isOpen, setIsOpen] = useState(true)

  // modal envoyer message
  const [openEnvoyerMessage, setOpenEnvoyerMessage] = useState(false)
  const handleOpenEnvoyerMessage = () => setOpenEnvoyerMessage(true)
  const handleCloseEnvoyerMessage = () => setOpenEnvoyerMessage(false)

  // sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }
  const handleTabChange = (newTab) => {
    setActiveTab(newTab)
    resetSelectedMessage() // Réinitialiser selectedMessage lors du changement d'onglet
  }

  return (
    <div className="conatine-side-mess">
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <IconButton onClick={toggleSidebar} className="menu-button">
          <MenuIcon />
        </IconButton>
        <ul className="sidebar-menu">
          <li onClick={handleOpenEnvoyerMessage}>
            <ChatBubbleOutlineIcon />
            <span>Nouveau message</span>
          </li>
          <li
            className={activeTab === 'boiteReception' ? 'active' : ''}
            onClick={() => handleTabChange('boiteReception')}
          >
            <QuestionAnswerIcon />
            <span>Boîte de réception</span>
          </li>
          <li
            className={activeTab === 'messageEnvoyer' ? 'active' : ''}
            onClick={() => handleTabChange('messageEnvoyer')}
          >
            <AddCommentIcon />
            <span>Envoyés</span>
          </li>
        </ul>
      </div>
      <ModalEnvoyerMessage
        open={openEnvoyerMessage}
        handleClose={handleCloseEnvoyerMessage}>
        <EnvoyerMessage onClose={handleCloseEnvoyerMessage} />
      </ModalEnvoyerMessage>
    </div>
  )
}

export default SideBarMessagerie
