import React from 'react'
import axios from 'axios'
import { Snackbar } from '@mui/material'
import useSnackbar from '../../hooks/useSnackbar'
import AffaireForm from '../affaireForm/AffaireForm'

const AffaireFormOnglet = ({affaire, reFetch}) => {
    const { open, message, showSnackbar, hideSnackbar } = useSnackbar()

    const handleUpdateAffaire = async (updatedAffaireData) => {
        // Logique pour mettre à jour l'affaire via une requête PUT
        try {
          await axios.put(`/affaires/${affaire._id}`, updatedAffaireData)
          showSnackbar('Modification Affaire Réussis')
          await reFetch() // Recharger les données de l'affaire après la mise à jour
        } catch (err) {
          console.error("Erreur lors de la mise à jour de l'affaire", err)
          showSnackbar('Erreur lors de la Modification')
        }
      }

  return (
    <div className='AffaireFormOnglet'>
        {affaire && (
            <AffaireForm
            affaireData={affaire}
            onSubmit={handleUpdateAffaire}
            isEdit={true}
            />
        )}
    
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={hideSnackbar}
            message={message}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
    </div>
  )
}

export default AffaireFormOnglet