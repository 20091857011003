import axios from 'axios'
import { useLocation } from 'react-router-dom'
import useFetch from '../../../hooks/useFetch'
import './userFormOnglet.scss'
import UserForm from '../userForm/UserForm'
import useSnackbar from '../../../hooks/useSnackbar'
import { Snackbar } from '@mui/material'

const UserFormOnglet = () => {
  const location = useLocation()
  const userId = location.pathname.split('/')[2]

  const {
    data: user,
    loading,
    error,
    reFetch
  } = useFetch(`/utilisateurs/${userId}`)
  const { open, message, showSnackbar, hideSnackbar } = useSnackbar()

  const handleUpdateUser = async (updatedUserData) => {
    // Logique pour mettre à jour l'utilisateur via une requête PUT
    try {
      await axios.put(`/utilisateurs/${userId}`, updatedUserData)
      showSnackbar('Modification Utilisateur Réussis')
      await reFetch() // Recharger les données de l'utilisateur après la mise à jour
    } catch (err) {
      console.error("Erreur lors de la mise à jour de l'utilisateur", err)
      showSnackbar('Erreur lors de la Modification')
    }
  }
  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur : {error.message}</div>

  console.log(user)
  return (

        <div className="UserFormOnglet">
          {user && (
            <UserForm
              userData={user}
              onSubmit={handleUpdateUser}
              isEdit={true}
            />
          )}

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={hideSnackbar}
            message={message}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
        </div>

  )
}

export default UserFormOnglet
