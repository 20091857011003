import { useEffect, useState } from 'react';
import './expiringFilesRecap.scss';
import axios from 'axios';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';

const ExpiringFilesRecap =({ date }) =>{
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchExpiringFiles = async () => {
      try {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const response = await axios.get('/files/expiringSoonAndNoArchived', { params: { expirationDate: formattedDate } });
        console.log('response', response.data)
        setFiles(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des fichiers expirant bientôt', error);
      }
    };

    fetchExpiringFiles();
  }, [date]);

  return (
    <div className="container-ExpiringFilesRecap">
      <div className="title-ExpiringFilesRecap">
        <h2>Fichiers prochainement expirés:</h2>
      </div>
      <table>
  <thead>
    <tr>
      <th>Nom</th>
      <th>Affaire</th>
      <th>Date d'expiration</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {files.length > 0 ? (
      files.map(file => (
        <tr key={file.id}>
          <td>{file.name}</td>
          <td><NavLink to={`/affaires/${file.affaire._id}`}>{file.affaire.nomAffaire}</NavLink></td>
          <td>{format(new Date(file.expirationDate), 'dd/MM/yyyy')}</td>
          <td><a href={file.url} target="_blank" rel="noreferrer">Ouvrir</a></td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="4" style={{ textAlign: "center" }}>Aucun document expiré et non archivé à la date sélectionnée.</td>
      </tr>
    )}
  </tbody>
</table>
    </div>
  );
}

export default ExpiringFilesRecap;
