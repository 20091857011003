import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import useFetch from '../../../hooks/useFetch'
import './listeAFaire.scss'
import ModalZindex from '../../modules/ModalZindex/ModalZindex'
import { fileToKey } from '../../../utils/storage'
import useModal from '../../modules/Modal/useModal'
import { useFirebase } from '../../../context/Firebase'
import ElementId from '../../elements/elementId/ElementId'
import { addHours, format, startOfDay } from 'date-fns'

const ListeAFaire = ({ affaireId }) => {
  const { data: listesdata, reFetch } = useFetch(`/affaires/listes/affaire/${affaireId}`)
  const listeAFaire = listesdata?.find((liste) => liste.type === 'À Faire')
  const [selectedElement, setSelectedElement] = useState(null)
  const { isModalOpen, openModal, closeModal } = useModal();
  const { storage } = useFirebase()
  const [formData, setFormData] = useState({
    nom: '',
    description: '',
    reference: '',
    quantite: 0,
    listeId: '',
    file: null,
    reminderDate: null,
    reminderHour: null
  })

  useEffect(() => {
    if (listeAFaire) {
      setFormData((formData) => ({
        ...formData,
        listeId: listeAFaire._id
      }))
    }
  }, [listeAFaire])

  const handleChange = (e) => {
    const { name, value, files } = e.target

    if (name === 'file' && files[0]) {
      setFormData(prevState => ({ ...prevState, [name]: files[0] }));
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }));
    }
  }
  
  const fileInputRef = useRef()
  const handleSubmit = async (e) => {
    e.preventDefault()
    const endpoint = selectedElement ? `/elements/${selectedElement._id}` : '/elements'
    const method = selectedElement ? 'put' : 'post'
    try {
      const fileUrl = await fileToKey(formData.file, storage) 
      await axios({
        method,
        url: endpoint,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          ...formData,
          file: fileUrl,
          reminderDate: formData.reminderDate ? addHours(startOfDay(new Date(formData.reminderDate)), formData.reminderHour || 8) : null
        }
      })
      await reFetch()
      setSelectedElement(null)
      setFormData({
        nom: '',
        description: '',
        reference: '',
        quantite: 0,
        listeId: formData.listeId,
        file: null,
        reminderDate: '',
        reminderHour: 0
      })
      fileInputRef.current.value = "";
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }
  //btn modifier modal 

  const handleElementClick = (elementId) => {
    setSelectedElement(elementId);
    openModal(); // Utilisez la fonction du hook useModal pour ouvrir la modal
  };
    // Préparer le contenu de la modal avec le composant ElementId
    const modalContent = (
      <ElementId elementId={selectedElement} closeModal={closeModal} reFetch={reFetch}/>
    );

  const handleDeleteElement = async (elementId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) {
      try {
        await axios.delete(`/elements/${elementId}`)
        await reFetch() // Rafraîchit les données pour refléter la suppression dans l'interface utilisateur.
      } catch (error) {
        console.error('Erreur lors de la suppression', error)
      }
    }
  }

  //couleur etat
  const getEtatClass = (etat) => {
    switch (etat) {
      case 'Non Commencé':
        return 'etat-non-commence'
      case 'En Cours':
        return 'etat-en-cours'
      case 'Demande Informations Complementaire':
        return 'etat-demande-info'
      case 'Terminé':
        return 'etat-termine'
      default:
        return '' // Ne pas appliquer de classe spécifique si aucun cas ne correspond
    }
  }

  return (
    <div className="listeAFaire">
      <div className="form-laf">
        <h2>Liste à faire</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            placeholder="Nom"
          />
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
          />
          <input
            type="text"
            name="reference"
            value={formData.reference}
            onChange={handleChange}
            placeholder="Référence"
          />
          <input
            type="number"
            name="quantite"
            value={formData.quantite}
            onChange={handleChange}
            placeholder="Quantité"
          />
          <input
            ref={fileInputRef} 
            type="file"
            name="file"
            onChange={handleChange}
            placeholder="Fichier"
          />
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
            <input
              style={{flex: 1}}
              type="date"
              name="reminderDate"
              value={formData.reminderDate}
              onChange={handleChange}
              placeholder="Date de rappel"
            />
            <input
              style={{width: '20%'}}
              type="number"
              min={0}
              pattern="[0-9]"
              max={23}
              name="reminderHour"
              value={formData.reminderHour}
              onChange={handleChange}
              placeholder="Heure de rappel"
            />
          </div>
          <button type="submit">Ajouter</button>
        </form>
      </div>
      <div className="contain-laf">
        <ul>
          {listeAFaire?.elements?.length > 0
            ? listeAFaire.elements.map((element) => (
                <li key={element._id}>
                  <div className="ligne-complete-listeafaire">   
                    <div className="haut-ligne-listeafaire">
                  <div className="contain-li-laf">
                    {`${element.nom}`}
                    {element.reminderDate ? <div className="reminder">(Rappel : {`${format(new Date(element.reminderDate), 'dd/MM/yyyy à HH')}h`})</div> : null}
                  </div>
                  <div className="btn-li-lap">
                    {element.file 
                      ? <a href={element.file} target='_blank' rel="noreferrer">
                          <button className="info">Ouvrir le fichier</button>
                        </a>
                      : null
                    }
                    <button className="green" onClick={() => handleElementClick(element._id)}>Modifier</button>
                    <button className="red" onClick={() => handleDeleteElement(element._id)}>x</button>
                  </div>
                  </div>
                  <p className={getEtatClass(element.etatRealisation)}  style={{ fontSize: '0.7rem' }}>Etat: {element.etatRealisation}</p>
                  </div>
                </li>))
            : <p>Aucun élément pour le moment.</p>}
        </ul>
      </div>
      <ModalZindex
        title="Détail de l'élément"
        content={modalContent}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  )
}
export default ListeAFaire
