import useFetch from '../../../hooks/useFetch'
import GraphTachesParAffaire from '../graphTachesParAffaire/GraphTachesParAffaire'
import './tachesSuivisParAffaire.scss'

const TachesSuivisParAffaire = ({ affaireId }) => {
  const { data: affairedata } = useFetch(`/affaires/dataAffaire/rapportsTaches/${affaireId}`)

  console.log('affairedata:', affairedata)
  return (
    <div>TachesSuivisParAffaire

        <GraphTachesParAffaire affaireData={affairedata} />
    </div>
  )
}

export default TachesSuivisParAffaire
