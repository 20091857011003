import { jsPDF as JsPDF } from 'jspdf'

// Ajoutez ces fonctions dans votre fichier
const calculateWorkingHours = (startTime, endTime) => {
  const start = new Date(startTime)
  const end = new Date(endTime)
  let diff = (end - start) / (1000 * 60 * 60) // Convertir en heures
  if (diff > 6) diff -= 1 // Retirer 1h si plus de 6h de travail
  return diff > 0 ? diff : 0 // S'assurer que la valeur est positive
}

const convertHoursToHHMM = (hoursDecimal) => {
  const hours = Math.floor(hoursDecimal)
  const minutes = Math.floor((hoursDecimal - hours) * 60)
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const exportPDF = (userData, headerData, utilisateur, startDate, endDate) => {
  const doc = new JsPDF()

  // Ajout de l'en-tête
  if (headerData.logo) {
    doc.addImage(headerData.logo, 'JPEG', 10, 10, 40, 20)
  }
  doc.setFontSize(10)
  doc.text(headerData.nom, 60, 15)
  doc.text(headerData.adresse, 60, 20)
  doc.text(`Tél: ${headerData.telephone} | Email: ${headerData.mail}`, 60, 25)

  // Ajout du titre
  const titre = `Feuille d'heure de ${utilisateur.nom} ${utilisateur.prenom}`
  doc.setFontSize(18) // Augmenter la taille du titre
  doc.text(titre, 105, 35, null, null, 'center') // Centre le titre

  // Sous-titre pour la période
  const periode = `Période : ${startDate.toLocaleDateString()} à ${endDate.toLocaleDateString()}`
  doc.setFontSize(12)
  doc.text(periode, 105, 45, null, null, 'center') // Centre le sous-titre

  // En-têtes de la table
  doc.setFontSize(10)
  doc.text('Date', 10, 55)
  doc.text("Nom de l'Affaire", 30, 55)
  doc.text("Heure d'Arrivée", 70, 55)
  doc.text('Heure de Départ', 90, 55)
  doc.text('Heures Travaillées', 110, 55)

  // Ligne de séparation
  doc.line(10, 57, 200, 57)

  let yPosition = 65 // Position Y de départ pour les données
  let totalHours = 0
  userData.forEach(activity => {
    activity.teamMemberHours.forEach(hour => {
      doc.text(new Date(activity.date).toLocaleDateString(), 10, yPosition)
      doc.text(activity.affaireId.nomAffaire, 30, yPosition)
      doc.text(new Date(hour.arrivee).toLocaleTimeString(), 70, yPosition)
      doc.text(new Date(hour.depart).toLocaleTimeString(), 90, yPosition)
      doc.text(hour.workedHoursFormatted + ' h', 110, yPosition)
      yPosition += 10 // Incrémente la position y pour la prochaine activité

      // Ajouter les heures travaillées au total
      const hoursWorked = calculateWorkingHours(hour.arrivee, hour.depart)
      totalHours += hoursWorked
    })
  })

  // Convertir le total en format HH:MM
  const formattedTotalHours = convertHoursToHHMM(totalHours)

  // Ajouter une ligne pour le total des heures
  doc.setFontSize(12)
  doc.text(`Total des heures travaillées pour la période : ${formattedTotalHours} h`, 10, yPosition)

  // Enregistrement du PDF
  doc.save('rapport.pdf')
}
