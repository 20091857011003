import { useState } from 'react'
import useFetch from '../../../../hooks/useFetch'
import './destinataireInput.scss'

const DestinataireInput = ({ tache, setTache }) => {
  const { data: utilisateurs } = useFetch('/utilisateurs')
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const handleInputChange = (event) => {
    setInputValue(event.target.value)

    if (event.target.value.length > 1) {
      const filteredUsers = utilisateurs.filter(
        (u) => u.nom.toLowerCase().includes(event.target.value.toLowerCase()) ||
                 u.prenom.toLowerCase().includes(event.target.value.toLowerCase())
      )
      setSuggestions(filteredUsers)
    } else {
      setSuggestions([])
    }
  }

  const handleSelectUser = (userId) => {
    if (!tache.usersId.includes(userId)) {
      setTache((prev) => ({
        ...prev,
        usersId: [...prev.usersId, userId]
      }))
    }
    setInputValue('')
    setSuggestions([])
  }

  const handleRemoveUser = (userId) => {
    setTache((prev) => ({
      ...prev,
      usersId: prev.usersId.filter(id => id !== userId)
    }))
  }

  return (
<div className="DestinataireInput">
  <div className="input-suggestion-di">
    <div className="input-tags">
      {tache.usersId.map((id) => {
        const user = utilisateurs.find((u) => u._id === id)
        return (
          <span key={id} className="tag">
            {user ? `${user.nom} ${user.prenom}` : ''}
            <button onClick={() => handleRemoveUser(id)}>x</button>
          </span>
        )
      })}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={tache.usersId.length === 0 ? "Commencez à taper le nom d'un utilisateur" : 'Ajouter un destinataire ici ...'}
        className="input-champ-di"
      />
    </div>
    <div className="suggestions">
      {suggestions.map((utilisateur) => (
        <div key={utilisateur._id} onClick={() => handleSelectUser(utilisateur._id)}>
          {utilisateur.nom} {utilisateur.prenom}
        </div>
      ))}
    </div>
  </div>
</div>

  )
}
export default DestinataireInput
