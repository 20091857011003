import { useEffect, useState } from 'react'
import axios from 'axios'
import './aPrendreOneTacheId.scss'

const APrendreOneTacheId = ({ tache, reFetch }) => {
  const [materielListe, setMaterielListe] = useState([])
  const [selectedMateriel, setSelectedMateriel] = useState({
    statutlieu: 'Au dépot'
  })
  const [editingMaterielId, setEditingMaterielId] = useState(null)

  useEffect(() => {
    if (tache && tache.materielAPrendre) {
      setMaterielListe(tache.materielAPrendre)
    }
  }, [tache])
  if (!tache) {
    return <div>Aucune tâche sélectionnée</div>
  }

  const handleMaterielChange = (field, value) => {
    setSelectedMateriel({ ...selectedMateriel, [field]: value })
  }

  const handleAddOrUpdateMateriel = async (event) => {
    event.preventDefault()
    console.log('Mise à jour du matériel', selectedMateriel)

    let updatedListe
    if (editingMaterielId !== null) {
      console.log("Modification de l'élément existant à l'indice", editingMaterielId)
      updatedListe = materielListe.map((item, index) =>
        index === editingMaterielId ? { ...selectedMateriel } : item
      )
    } else {
      console.log("Ajout d'un nouvel élément")
      updatedListe = [...materielListe, { ...selectedMateriel }]
    }

    console.log('Liste mise à jour', updatedListe)

    try {
      const response = await axios.put(`/taches/materielAPrendre/${tache._id}`, {
        ...tache,
        materielAPrendre: updatedListe
      })
      console.log('Réponse du serveur', response.data)

      setMaterielListe(updatedListe)
      setSelectedMateriel(null)
      setEditingMaterielId(null)
    } catch (error) {
      console.error('Erreur lors de la mise à jour', error)
    }
  }

  const handleEditMateriel = (index) => {
    setSelectedMateriel(materielListe[index])
    setEditingMaterielId(index)
  }

  const handleDeleteMateriel = async (index) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      const updatedListe = materielListe.filter((_, i) => i !== index)
      setMaterielListe(updatedListe)

      try {
        await axios.put(`/taches/materielAPrendre/${tache._id}`, {
          ...tache,
          materielAPrendre: updatedListe
        })
      } catch (error) {
        console.error('Erreur lors de la mise à jour', error)
      }
    }
  }

  console.log(materielListe)
  console.log('selectedMateriel', selectedMateriel)
  const { nomTache, description, typeTache } = tache

  return (
    <div className="aPrendreOneTacheId">
      <div className="topaPrendreOneTacheId">
        <div className="tacheInfo">
          <h2>A prendre pour : {nomTache}</h2>
          <p>Description : {description}</p>
          <p>Type de Tâche : {typeTache}</p>
        </div>
        <form onSubmit={handleAddOrUpdateMateriel}>
          <div className="materielForm">
            <input
              type="text"
              value={selectedMateriel?.nom || ''}
              onChange={(e) => handleMaterielChange('nom', e.target.value)}
              placeholder="Nom du matériel"
            />
            <input
              type="number"
              name="duration"
              value={selectedMateriel?.quantite || ''}
              onChange={(e) => handleMaterielChange('quantite', e.target.value)}
              placeholder="Quantité"
              min="0"
            />
            <select
              value={selectedMateriel?.statutlieu || ''}
              onChange={(e) => handleMaterielChange('statutlieu', e.target.value)}
            >
              <option value="Au dépot">Au dépot</option>
              <option value="Fournisseur">Fournisseur</option>
              <option value="Livré sur chantier">Livré sur chantier</option>
              <option value="A Recharger">À Recharger</option>
            </select>
            <button type="submit" className="btn-submittap">
              {editingMaterielId ? 'Modifier' : 'Envoyer'}
            </button>
          </div>
        </form>
      </div>

        <div className="materielListBody">
          {materielListe.length > 0
            ? (
                materielListe.map((materiel, index) => (
              <div key={index} className="materielListItem">
                <span>{materiel.nom}</span>
                <span>{materiel.quantite}</span>
                <span>{materiel.statutlieu}</span>
                <button onClick={() => handleEditMateriel(index)}>
                  Modifier
                </button>
                <button onClick={() => handleDeleteMateriel(index)}>X</button>
              </div>
                ))
              )
            : (
            <div className="noMateriel">
              Aucun matériel à prendre pour cette tâche
            </div>
              )}
        </div>
        </div>
  )
}

export default APrendreOneTacheId
