import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import useFetch from '../../hooks/useFetch';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NotificationItem from './components/NotificationItem';

const JournalDeBord = () => {
  const { utilisateur } = useContext(AuthContext);
  const { data: notifications, loading, error } = useFetch('/notifications');
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      const filtered = notifications.filter(notification => {
        const notificationDate = new Date(notification.dateCreation);
        return selectedDate && notificationDate.toDateString() === selectedDate.toDateString();
      });
      setFilteredNotifications(filtered);
    }
  }, [notifications, selectedDate]);

  return (
    <div className='JournalDeBord'>
      <h2>Journal de Bord</h2>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        inline // This makes the calendar always open
      />

      {loading && <p>Chargement...</p>}
      {error && <p>Une erreur est survenue.</p>}

      <h3>{selectedDate ? selectedDate.toLocaleDateString() : 'Date inconnue'}</h3>
      {filteredNotifications.length > 0 ? (
        filteredNotifications.map((notification) => (
          <NotificationItem key={notification._id} notification={notification} />
        ))
      ) : (
        <p>Aucune notification pour cette période.</p>
      )}
    </div>
  );
};

export default JournalDeBord;
