import { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import Close from '@mui/icons-material/Close'

const CreateFolder = ({ affaireId, currentFolderId, callback }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(prev => !prev)
  }

  return <>
    <Button variant='contained' onClick={handleClose} color='action'>
      Créer un dossier
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault()
          const data = Object.fromEntries((new FormData(event.currentTarget)).entries())
          console.log(data)
          axios.post('/files', {
            name: data.name,
            folder: true,
            parent: currentFolderId,
            affaire: affaireId
          }).then((res) => {
            callback(res.data)
            handleClose()
          }).catch(err => {
            console.log(err)
            alert('An error occured please try again')
          })
        }
      }}
      fullWidth={true}
    >
      <DialogTitle>Créer un dossier</DialogTitle>
      <Close onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
      <DialogContent style={{ paddingTop: 0 }}>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Nom du dossier"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit">Créer le dossier</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default CreateFolder
