import { useNavigate } from 'react-router-dom'

import useFetch from '../../../hooks/useFetch'
import BusinessIcon from '@mui/icons-material/Business'
import './affichageAffaireTache.scss'

const AffichageAffaireTache = ({ details, onReset }) => {
  const navigate = useNavigate()
  const isTache = details.nomTache !== undefined // Vérifie si c'est une tâche
  const affaireUrl = isTache ? `/affaires/${details.affaireId}` : `/affaires/${details._id}`
  const { data: affaireData } = useFetch(affaireUrl)

  // Handler pour naviguer vers la page détaillée
  const handleVoirPlus = () => {
    navigate(affaireUrl)
  }

  if (!details) return null

  // Fonction pour formater les dates
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`
  }
  const photoContainerClass = isTache ? 'affaire-photo-tache-datc' : 'affaire-photo-affaire-datc'
  return (
        <div className="details-affaire-tache-container">
          <button className="close-button-datc" onClick={onReset}>X</button>
          <div className={photoContainerClass}>
            {details.imgAffaire ? <img src={affaireData.imgAffaire} alt="Affaire" /> : <BusinessIcon className='img-mui-datc'/>}
          </div>
          <div className="affaire-details-datc">
            <h3>{affaireData.nomAffaire}</h3>
            <p>Client : {affaireData.client}</p>
            <p>Adresse : {affaireData.adresse}, {affaireData.codePostal} {affaireData.ville}</p>
            <p>Date de début : {formatDate(affaireData.dateDebutReelle)}</p>
            <p>Date de fin : {formatDate(affaireData.dateFinReelle)}</p>
            <p>Zone : {affaireData.zone}</p>
            <p>Statut : {affaireData.statut}</p>
            <p>Avancement : {affaireData.pourcentageRealisation}%</p>

            {isTache && (
              <>
                <h4>Tâche : {details.nomTache}</h4>
                <p>Date de départ : {formatDate(details.dateDepartReelle)}</p>
                <p>{"Date d'échéance :"} {formatDate(details.dateEcheanceReelle)}</p>
                <p>Avancement de la tâche : {details.pourcentageRealisation}%</p>
              </>
            )}

            <button className='btn-voir-affiare-datc' onClick={handleVoirPlus}>{"Acceder à l'Affaire"}</button>
          </div>
        </div>
  )
}

export default AffichageAffaireTache
