import { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import './affaireRapports.scss'

const AffaireRapports = ({ affaireId }) => {
  const [rapports, setRapports] = useState([])
  const [selectedRapport, setSelectedRapport] = useState(null)

  useEffect(() => {
    const fetchRapports = async () => {
      try {
        const response = await axios.get(`/rapports/affaire/${affaireId}`)
        console.log('response.data', response.data)
        setRapports(response.data)
      } catch (error) {
        console.error('Erreur lors de la récupération des rapports', error)
      }
    }
    fetchRapports()
  }, [affaireId])

  const handleSelectRapport = (rapportId) => {
    const rapport = rapports.find((r) => r._id === rapportId)
    setSelectedRapport(rapport)
  }

  return (
    <div className="affaireRapports">
      <div className="rapportList">
        {rapports.length > 0
          ? (
              rapports.map((rapport) => (
            <div
              key={rapport._id}
              className={`rapportItem ${
                selectedRapport && selectedRapport._id === rapport._id
                  ? 'selected'
                  : ''
              }`}
              onClick={() => handleSelectRapport(rapport._id)}
            >
              Rapport du {new Date(rapport.date).toLocaleDateString()}
            </div>
              ))
            )
          : (
          <div className="mesaffaireRapports">
            {"Aucun rapport n'est encore actuellement édité pour cette affaire."}
          </div>
            )}
      </div>
      {selectedRapport && (
        <div className="rapportDetail">
          <div className="title-rapport-detail">
          <h1>Rapport du {new Date(selectedRapport.date).toLocaleDateString()}</h1>
          <h2>Détails du Rapport de Fin de Journée</h2>
          </div>
          <div className="team-rapport-detail">
          <h3>
            Chef de chantier: {selectedRapport.chefAssignes.nom}{' '}
            {selectedRapport.chefAssignes.prenom}
          </h3>
          <br />
          <h4>Équipe sur place:</h4>
          <ul>
            {selectedRapport.teamMembers.map((membre) => (
              <li key={membre._id}>
                {membre.nom} {membre.prenom}
              </li>
            ))}
          </ul>
          </div>
          <div className="tache-rapport-detail">
          <h4>Tâches traitées:</h4>
          <ul>
            {selectedRapport.tachesDay.map((tache) => (
              <Fragment key={tache.tacheIdDay._id}>
                <li>{tache.tacheIdDay.nomTache}</li>
                <li>
                  Pourcentage de réalisation :{' '}
                  {tache.tacheIdDay.pourcentageRealisation} %
                </li>
              </Fragment>
            ))}
          </ul>
          </div>
          <div className="questions-rapport-detail">
          <h4>Questionnaire:</h4>
          <ul>
            {selectedRapport.questionsReponses.map((qr, index) => (
              <li key={index}>
                <p>Question: {qr.question}</p>
                <p>Réponse: {qr.reponse ? 'Oui' : 'Non'}</p>
                {qr.optionsDetaillees && (
                <p>Options détaillées: {qr.optionsDetaillees}</p>
                )}
                <p>Commentaire: {qr.commentaire}</p>
              </li>
            ))}
          </ul>
          </div>
          <div className="commentaires-rapport-detail">
          <h4>Commentaires Généraux:</h4>
          <p>{selectedRapport.commentaireGeneralDay}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default AffaireRapports
