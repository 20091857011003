import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import './createFile.scss'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import InsertDriveFile from '@mui/icons-material/InsertDriveFile'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import axios from 'axios'
import { fileToKey } from '../../../../utils/storage'
import { useFirebase } from '../../../../context/Firebase'
import Close from '@mui/icons-material/Close'

const CreateFile = ({ affaireId, currentFolderId, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [status, setStatus] = useState('IN_PROGRESS')
  const [access, setAccess] = useState(['ADMIN'])
  const [expirationDate, setExpirationDate] = useState(null)
  const [file, setFile] = useState(undefined)
  const { storage } = useFirebase()

  const handleClose = () => {
    setOpen(!open); // ou setOpen(!open); selon votre logique précédente
    setName('');
    setStatus('IN_PROGRESS'); // Remettre toujours le statut à "IN_PROGRESS"
    setExpirationDate(null);
    setFile(undefined);
    setAccess(['ADMIN']);
  }

  const onDrop = useCallback(files => {
    if (!name?.length || (file && file.name === name)) {
      setName(files[0].name)
    }
    setFile(Object.assign(files[0], {
      preview: URL.createObjectURL(files[0])
    }))
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  })

  const handleAccessChange = (event) => {
    const newAccess = event.target.value;
    // Assurez-vous que ADMIN est toujours dans les valeurs sélectionnées
    if (!newAccess.includes('ADMIN')) {
      setAccess(['ADMIN', ...newAccess]);
    } else {
      setAccess(newAccess);
    }
  };

  // creer une tache si expirationDate est renseigné
  const createTaskForFileExpiration = async (affaireId, taskName, expDate) => {
    const newTacheDocument = {
      affaireId: affaireId,
      nomTache: taskName,
      dateEcheance: expDate,
      dateDepart: expDate,
      duration: 1,
      typeTache: 'Document',
    };

    const taskResponse = await axios.post('/taches', newTacheDocument);
    const newTacheId = taskResponse.data._id;
    const affaireResponse = await axios.get(`/affaires/${affaireId}`);
    const currentAffaire = affaireResponse.data;
    currentAffaire.taches.push(newTacheId);
    await axios.put(`/affaires/${affaireId}`, currentAffaire);
    return newTacheId;
  };
  
  return <>
    <Button variant='contained' onClick={handleClose} color='action'>
      Ajouter un fichier
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault()
          setIsLoading(true)
          const fileUrl = await fileToKey(file, storage)

          let tacheId = null;
          if (expirationDate) {
            // Attendre la création de la tâche et récupérer son ID
            tacheId = await createTaskForFileExpiration(affaireId, name || file.name, expirationDate);
          }

          axios.post('/files', {
            name: name || file.name,
            url: fileUrl,
            type: file.type,
            folder: false,
            expirationDate,
            access,
            status: status || undefined,
            parent: currentFolderId,
            affaire: affaireId,
            tacheId: tacheId
          }).then((res) => {
            callback(res.data)
            handleClose()
          }).catch(err => {
            console.log(err)
            alert('An error occured please try again')
          }).finally(() => {
            setIsLoading(false)
          })
        }
      }}
      fullWidth={true}
    >
      <DialogTitle>Ajouter un fichier</DialogTitle>
      <Close onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
      <DialogContent className="form">
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          label="Nom du fichier*"
          fullWidth
          variant="outlined"
          value={name}
          onChange={e => setName(e.currentTarget.value)}
        />
        <div {...getRootProps()} className={classNames('dropzone', file ? 'success' : '')}>
          <input id='file' name='file' {...getInputProps()} />
          {file
            ? file.type.includes('image')
              ? <img src={file.preview} />
              : <div className="preview">
                  <InsertDriveFile sx={{ fontSize: 40 }} />
                  <CheckCircleOutline sx={{ fontSize: 40 }} />
                </div>
            : <p>Cliquer ici pour uploader votre fichier</p>
          }
        </div>
        <div className="info">
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={status}
              onChange={e => setStatus(e.target.value)}
              input={<OutlinedInput label="Status" />}
            >
              <MenuItem value='WAITING'>En attente</MenuItem>
              <MenuItem value='IN_PROGRESS'>En cours</MenuItem>
              <MenuItem value='VALIDATED'>Validé</MenuItem>
              <MenuItem value='CONFIRMED'>Confirmé</MenuItem>
              <MenuItem value='ARCHIVED'>Archivé</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 4 }}>
            <DatePicker
              label="Date d'expiration"
              value={expirationDate}
              onChange={v => setExpirationDate(v)}
            />
          </FormControl>
        </div>
        <div className="info">
          <FormControl fullWidth margin="normal">
            <InputLabel id="access-label">Accès</InputLabel>
            <Select
              labelId="access-label"
              multiple
              value={access}
              onChange={handleAccessChange}
              input={<OutlinedInput label="Accès" />}
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="ADMIN" disabled>
                Admin
              </MenuItem>
              <MenuItem value="OUVRIER_TERRAIN">
                Ouvrier Terrain
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={isLoading}>{isLoading ? <CircularProgress /> : 'Ajouter le fichier'}</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default CreateFile
