import axios from 'axios'
import { createContext, useState } from 'react'

const localStorageItemUser = 'visionBuildTeamUser'
const localStorageItemJWT = 'visionBuildTeamJWT'

const storedUser = JSON.parse(localStorage.getItem(localStorageItemUser)) || null
const storedJWT = localStorage.getItem(localStorageItemJWT) || null

const INITIAL_STATE = {
  utilisateur: storedUser,
  updateUser: () => {},
  logout: () => {}
}

export const AuthContext = createContext(INITIAL_STATE)
axios.defaults.headers.common.Authorization = storedJWT

export const AuthContextProvider = ({ children }) => {
  const [utilisateur, setUser] = useState(JSON.parse(localStorage.getItem(localStorageItemUser)) || null)

  const updateUser = (newUser, jwt) => {
    axios.defaults.headers.common.Authorization = jwt
    setUser(newUser)
    localStorage.setItem(localStorageItemUser, JSON.stringify(newUser))
    if (jwt) {
      localStorage.setItem(localStorageItemJWT, jwt)
    }
  }

  const logout = () => {
    axios.defaults.headers.common.Authorization = null
    localStorage.removeItem(localStorageItemUser)
    localStorage.removeItem(localStorageItemJWT)
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        utilisateur,
        updateUser,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
