import { createContext, useContext, useMemo } from 'react'

import { initializeApp } from 'firebase/app'
// import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
// import { getAnalytics } from 'firebase/analytics'

const initialValues = {}

const FirebaseContext = createContext(initialValues)

// See: https://firebase.google.com/docs/web/learn-more#config-object

const initApp = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  }

  // Initialize Firebase
  /* const app = */initializeApp(firebaseConfig)

  // if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  //   getAnalytics(app)
  // }

  // Initialize Firebase Authentication and get a reference to the service
  // const auth = getAuth(app)

  // if (process.env.REACT_APP_FIREBASE_EMULATOR_AUTH) {
  //   connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_EMULATOR_AUTH)
  // }

  const storage = getStorage()
  if (process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_DOMAIN && process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT) {
    connectStorageEmulator(storage, process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_DOMAIN, parseInt(process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT))
  }

  return { /* auth, */ storage }
}

const FirebaseProvider = ({ children }) => {
  const app = useMemo(initApp, [])

  return (
    <FirebaseContext.Provider value={app}>
      {children}
    </FirebaseContext.Provider>
  )
}

const useFirebase = () => {
  return useContext(FirebaseContext)
}

export { FirebaseProvider, useFirebase }
