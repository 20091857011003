import './ligneNomAffaire.scss'

const LigneNomAffaire = ({ affaire, onAffaireSelect }) => {
  // Remonter l'information
  const handleClick = () => {
    onAffaireSelect(affaire._id)
  }
  return (

        <div className="tacheInfo" onClick={handleClick}>
          <div className="nomTache">
            {affaire.nomAffaire}
          </div>
        </div>
  )
}

export default LigneNomAffaire
