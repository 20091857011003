import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const exportToPDF = (userActivities, entreprisedata, utilisateur, selectedWeek, startDate, endDate) => {
  const doc = new jsPDF({
    orientation: 'landscape',
  });

  // En-tête avec les informations utilisateur et entreprise
  // Ajouter les libellés "Compagnon:" et "Société:"
  doc.text("Compagnon:", 14, 15);
  doc.text("Société:", doc.internal.pageSize.width - 80, 15);

  // Ajouter les informations de l'utilisateur et de l'entreprise sous les libellés
  doc.setFontSize(11);
  doc.text(`${utilisateur.prenom} ${utilisateur.nom}`, 14, 22); // Ajustez légèrement la position y pour aligner sous le libellé
  doc.text(`${entreprisedata.nom}`, doc.internal.pageSize.width - 80, 22);
  doc.text(`${entreprisedata.adresse}`, doc.internal.pageSize.width - 80, 29); // Assurez-vous que l'adresse est sur la ligne suivante

  // Définition de la période
  const periodString = `Période : semaine ${selectedWeek} du ${new Date(startDate).toLocaleDateString()} au ${new Date(endDate).toLocaleDateString()}`;
  doc.setFontSize(10);
  doc.text(periodString, 14, 30);

  // Préparation des données pour le tableau
  const tableColumn = ["Jour", "Chantier", "Heures Travaillées", "Zone"];
  const days = generateDateRange(startDate, endDate);
  const activityMap = prepareActivityMap(userActivities, days);

  // Création des lignes pour le tableau
  const tableRows = [];
  days.forEach(day => {
    const dateString = day.toLocaleDateString();
    const activities = activityMap[dateString];
    if (activities.length === 0) {
      tableRows.push([dateString, "Aucune affectation présente ce jour-là", "", ""]);
    } else {
      // Ajoute les activités du même jour dans des lignes séparées mais conserve la date sur chaque ligne
      activities.forEach((activity, index) => {
        const hours = Math.floor(activity.heures);
        const minutes = Math.round((activity.heures % 1) * 60).toString().padStart(2, '0');
        tableRows.push([
          index === 0 ? dateString : "", // Affiche la date uniquement pour la première activité
          activity.nomAffaire,
          `${hours}h ${minutes}m`, // Heures formatées avec deux chiffres pour les minutes
          activity.zone
        ]);
      });
    }
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 35,
    theme: 'grid',
  });

  // Ajout du total des heures en bas du tableau
  const totalHeures = calculateTotalHours(userActivities);
  doc.text(`Total des heures pour cette période: ${totalHeures}`, 14, doc.lastAutoTable.finalY + 10);

    // Section Commentaire
    const commentY = doc.lastAutoTable.finalY + 20;
    doc.setFontSize(10);
    doc.text("Commentaires:", 14, commentY);
    doc.setLineWidth(0.5);
    doc.rect(14, commentY + 3, doc.internal.pageSize.width - 28, 30); // Rectangle pour les commentaires
  
    // Zones de Visa
    const visaY = commentY + 40;
    doc.text("Visa Compagnon:", 14, visaY);
    doc.text("Nom et Visa Conducteur de Travaux:", 80, visaY);
    doc.text("Nom et Visa Direction:", 180, visaY);
  
  doc.save('timesheet.pdf');
};

// Génère une liste de tous les jours entre deux dates
const generateDateRange = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};

// Prépare une carte d'activités par date
const prepareActivityMap = (activities, days) => {
  const map = {};
  days.forEach(day => {
    map[day.toLocaleDateString()] = [];
  });
  activities.forEach(activity => {
    const dateString = new Date(activity.date).toLocaleDateString();
    if (map[dateString]) {
      map[dateString].push(activity);
    }
  });
  return map;
};

// Calcule le total des heures
const calculateTotalHours = (activities) => {
  const totalSeconds = activities.reduce((total, activity) => total + activity.heures * 3600, 0);
  const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
  return `${hours}h ${minutes}m`;
};

export default exportToPDF;
