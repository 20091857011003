import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import useFetch from '../../hooks/useFetch';
import './taches.scss';
import ElementId from "../../components/elements/elementId/ElementId";
import Modal from "../../components/modules/Modal/Modal";
import useModal from "../../components/modules/Modal/useModal";

const Taches = () => {
  const { utilisateur } = useContext(AuthContext);
  const { data: listeAFaire, loading ,reFetch} = useFetch(`/affaires/listes/afaire/affaires`);
  const [filtreAffaire, setFiltreAffaire] = useState('');
  const { isModalOpen, openModal, closeModal } = useModal();
  const [activeElementId, setActiveElementId] = useState(null);
  const navigate = useNavigate();


  const handleElementClick = (elementId) => {
    setActiveElementId(elementId);
    openModal(); // Utilisez la fonction du hook useModal pour ouvrir la modal
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  const affairesFiltrees = listeAFaire.filter(affaire => 
    affaire.nomAffaire.toLowerCase().includes(filtreAffaire.toLowerCase())
  );

  // Préparer le contenu de la modal avec le composant ElementId
  const modalContent = (
    <ElementId elementId={activeElementId} closeModal={closeModal} reFetch={reFetch}/>
  );

  const getEtatClass = (etat) => {
    switch (etat) {
      case 'Non Commencé':
        return 'etat-non-commence';
      case 'En Cours':
        return 'etat-en-cours';
      case 'Demande Informations Complementaire':
        return 'etat-demande-info';
      case 'Terminé':
        return 'etat-termine';
      default:
        return ''; // Ne pas appliquer de classe spécifique si aucun cas ne correspond
    }
  };

  return (
    <div className="taches">
      <div className="containtaches">
      <div className="header-taches">
        <h1>Les tâches À Faire</h1>
        <input
          type="text"
          placeholder="Filtrer par nom d'affaire"
          value={filtreAffaire}
          onChange={(e) => setFiltreAffaire(e.target.value)}
        />
      </div>
      <div className="container-taches">
        {affairesFiltrees.map(affaire => (
          <div key={affaire._id} className="affaire-details">
            <h2>
              {affaire.nomAffaire}
              <button onClick={() => navigate(`/affaires/${affaire._id}`)} className="aller-affaire-btn">Aller à l'affaire</button>
            </h2>
            {affaire.listes.every(liste => liste.elements.length === 0) ? (
              <p>Aucun élément à faire pour cette affaire.</p>
            ) : (
              affaire.listes.map(liste => (
                liste.elements.length > 0 && (
                  <div key={liste._id} className="liste-details">
                    <h3>Liste à faire :</h3>
                    {liste.elements.map(element => (
                      <div key={element._id} className="element-details" onClick={() => handleElementClick(element._id)}>
                        <div className="top-element-details">
                          <p className="nom-element">Nom: <span className="element-value">{element.nom}</span></p>
                          <p className={`etat-element ${getEtatClass(element.etatRealisation)}`}>Etat: {element.etatRealisation}</p>
                        </div>
                        {element.description && (
                          <div className="bottom-element-details">
                            <p>Description: <span className="element-value">{element.description}</span></p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )
              ))
            )}
          </div>
        ))}
      </div>
      <Modal
        title="Détail de l'élément"
        content={modalContent}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
    </div>
  );
}

export default Taches;