import { useState } from 'react'
import useFetch from '../../../../hooks/useFetch'
import './refAffaire.scss'

const RefAffaire = ({ tache, setTache, onAffaireSelect }) => {
  const { data: affaires } = useFetch('/affaires')
  const [showSelector, setShowSelector] = useState(false)
  const [showAll, setShowAll] = useState(false)

  const handleToggleSelector = () => setShowSelector(!showSelector)
  const handleToggleShowAll = () => setShowAll(!showAll)

  const filteredAffaires = affaires.filter(affaire =>
    showAll || ['création', 'en préparation', 'en cours'].includes(affaire.statut)
  ).sort((a, b) => a.nomAffaire.localeCompare(b.nomAffaire))

  const handleSelectAffaire = (event) => {
    const affaireId = event.target.value
    const affaire = affaires.find(a => a._id === affaireId)
    if (affaire) {
      onAffaireSelect({ id: affaire._id, nom: affaire.nomAffaire })
      setShowSelector(false) // Fermer le sélecteur après la sélection
    }
  }

  return (
      <div className="RefAffaire">
        <button onClick={handleToggleSelector}>Ajouter une réf affaire</button>
        {showSelector && (
          <>
            <label>
              <input
                type="checkbox"
                checked={showAll}
                onChange={handleToggleShowAll}
              />
              Afficher tous
            </label>
            <select onChange={handleSelectAffaire} value={tache.affaireId || ''}>
              <option value="">Sélectionnez une affaire</option>
              {filteredAffaires.map((affaire) => (
                <option key={affaire._id} value={affaire._id}>
                  {affaire.nomAffaire}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
  )
}

export default RefAffaire
