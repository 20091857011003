import { useState, useEffect, useContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'
import Topbar from './components/topbar/Topbar'
import SuperTopbar from './components/SuperTopbar/SuperTopbar'
import Home from './pages/Home/Home'
import Affaires from './pages/affaires/Affaires'
import AffaireId from './pages/affaireId/AffaireId'
import './App.scss'
import Taches from './pages/taches/Taches'
import Rapportsfdj from './pages/rapportsfdj/Rapportsfdj'
import Users from './pages/users/Users'
import Notifications from './pages/notifications/Notifications'
import Chat from './pages/chat/Chat'
import DetailAffaireId from './pages/detailAffaireId/DetailAffaireId'
import UsersId from './pages/usersId/UsersId'
import Reglages from './pages/reglages/Reglages'
import PreparationId from './pages/preparationId/PreparationId'
import AffairePlanningId from './pages/affairePlanningId/AffairePlanningId'
import RolesPermissions from './pages/RolesPermissions/RolesPermissions'
import PlanningPage from './pages/planningPage/PlanningPage'
import Login from './pages/Login/Login'
import Forgot from './pages/Forgot/Forgot'
import Reset from './pages/Reset/Reset'
import Messagerie from './pages/messagerie/Messagerie'
import Calandar from './pages/calandar/Calandar'
import Timesheet from './pages/timesheet/timesheet'
import JournalDeBord from './pages/journalDeBord/JournalDeBord'
import PlanningEntreprise from './pages/planningEntreprise/PlanningEntreprise'
import HomeNew from './pages/HomeNew/HomeNew'
import SuperAdmin from './pages/superadmin/superAdmin'

function App() {
  // Gestion du thème
  const { utilisateur } = useContext(AuthContext)
  const [theme, setTheme] = useState('green-theme')

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      setTheme(savedTheme)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  function ConditionalTopbar({ setTheme }) {
    const location = useLocation();
    const excludePaths = ['/login', '/forgot', '/reset'];

    // Ne pas afficher la Topbar si le chemin actuel est dans excludePaths
    if (excludePaths.includes(location.pathname)) {
      return null;
    }

    return utilisateur?.role === 'SUPERADMIN' ? <SuperTopbar setTheme={setTheme} /> : <Topbar setTheme={setTheme} />
  }


  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== 'production' || !process.env.REACT_APP_CRISP_ID) return
    if (utilisateur && utilisateur.role !== 'SUPERADMIN') {
      (window).$crisp = [];
      (window).$crisp.push(['do', 'chat:hide']);
      (window).$crisp.push(['on', 'message:received', () => { (window).$crisp.push(['do', 'chat:show']); (window).$crisp.push(['do', 'chat:open']) }]);
      (window).$crisp.push(['set', 'user:nickname', [`${utilisateur.prenom} ${utilisateur.nom}`]]);
      (window).$crisp.push(['set', 'user:email', [utilisateur.email]]);
      (window).$crisp.push(['set', 'conpany:name', [utilisateur.entreprise.nom]]);
      (window).$crisp.push(["set", "session:data", ["company_name", utilisateur.entreprise.nom]]);
      (window).CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;

      (function () {
        const d = document
        const s = d.createElement('script')

        s.src = 'https://client.crisp.chat/l.js'
        s.async = true
        d.getElementsByTagName('head')[0].appendChild(s)
      })()
    }
  }, [utilisateur])

  const ProtectedRoute = ({ children, superadmin }) => {
    const { utilisateur } = useContext(AuthContext)

    if (!utilisateur) {
      return <Navigate to="/login" />
    } else if (!utilisateur?.role === 'SUPERADMIN' && superadmin) {
      return <Navigate to="/" />
    } else if (utilisateur?.role === 'SUPERADMIN' && !superadmin) {
      return <Navigate to="/superadmin" />
    }
    return children
  }

  return (
    <BrowserRouter>
      <div className={`app ${theme}`}>
        <ConditionalTopbar setTheme={setTheme} />
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="forgot" element={<Forgot />} />
            <Route path="reset" element={<Reset />} />
            <Route
              index
              element={
                <ProtectedRoute>
                  <HomeNew />
                </ProtectedRoute>
              }
            />
            <Route path="/affaires" element={<ProtectedRoute><Affaires /></ProtectedRoute>} />
            <Route path="/timesheet" element={<ProtectedRoute><Timesheet /></ProtectedRoute>} />
            <Route path="/affaires/:id" element={<ProtectedRoute><AffaireId /></ProtectedRoute>} />
            <Route path="/affaires/detail/:id" element={<ProtectedRoute><DetailAffaireId /></ProtectedRoute>} />
            <Route path="/affaires/planning/:id" element={<ProtectedRoute><AffairePlanningId /></ProtectedRoute>} />
            <Route path="/affaires/preparation/:id" element={<ProtectedRoute><PreparationId /></ProtectedRoute>} />
            <Route path="/taches" element={<ProtectedRoute><Taches /></ProtectedRoute>} />
            <Route path="/rapportsfdj" element={<ProtectedRoute><Rapportsfdj /></ProtectedRoute>} />
            <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
            <Route path="/users/:id" element={<ProtectedRoute><UsersId /></ProtectedRoute>} />
            <Route path="/calendar/:id" element={<ProtectedRoute><Calandar /></ProtectedRoute>} />
            <Route path="/planning/" element={<ProtectedRoute><PlanningPage /></ProtectedRoute>} />
            <Route path="/planningentreprise/" element={<ProtectedRoute><PlanningEntreprise /></ProtectedRoute>} />
            <Route path="/rolespermissions" element={<ProtectedRoute><RolesPermissions /></ProtectedRoute>} />
            <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
            <Route path="/journal" element={<ProtectedRoute><JournalDeBord /></ProtectedRoute>} />
            <Route path="/chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
            <Route path="/messagerie" element={<ProtectedRoute><Messagerie /></ProtectedRoute>} />
            <Route path="/reglages" element={<ProtectedRoute><Reglages /></ProtectedRoute>} />
            <Route path="/superadmin" element={<ProtectedRoute superadmin><SuperAdmin /></ProtectedRoute>} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
