import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import './SuperTopbar.scss'
import {  useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'

const SuperTopBar = () => {
  const { utilisateur, logout } = useContext(AuthContext)
  const navigate = useNavigate()
  
  const handleLogout = () => {
    logout()
    // Efface l'utilisateur du local storage
    localStorage.removeItem('utilisateur')
    navigate('/login')
  }

  return (
    <div className="supertopbar-container">
      <div className="supertopbar-title">Espace Super Admin</div>
      {utilisateur
        ? <PowerSettingsNewIcon onClick={handleLogout} className="img-top-bar" />
        : <div>Non connecté</div>
      }
    </div>
  )
}

export default SuperTopBar
