import Sidebar from '../../components/sidebar/Sidebar'
import TopBarPage from '../../components/modules/topBarPage/TopBarPage'
import { affaireDetailMenu } from '../../variables/sidebar/topMenu'
import { useParams } from 'react-router-dom'

import './detailAffaireId.scss'
import axios from 'axios'
import AffaireForm from '../../components/affaireForm/AffaireForm'
import useFetch from '../../hooks/useFetch'
import useSnackbar from '../../hooks/useSnackbar'
import { Snackbar } from '@mui/material'

const DetailAffaireId = () => {
  const { id } = useParams()
  const { data: affaire, loading, error, reFetch } = useFetch(`/affaires/${id}`)
  const { open, message, showSnackbar, hideSnackbar } = useSnackbar()
  const menu = affaireDetailMenu(id)

  const handleUpdateAffaire = async (updatedAffaireData) => {
    try {
      await axios.put(`/affaires/${id}`, updatedAffaireData)
      showSnackbar('Modification Affaire Réussis')
      await reFetch() // Recharger les données de l'utilisateur après la mise à jour
    } catch (err) {
      console.error('Erreur lors de la mise à jour de Affaire', err)
      showSnackbar('Erreur lors de la Modification')
    }
  }
  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur : {error.message}</div>

  console.log(affaire)
  return (
    <div className="detailAffaireId">
      <div className="container">
        <div className="sidebar">
          <Sidebar menu={menu} />
        </div>
        <div className="conatainerdetailAffaireId">
          <div className="containertop">
            <TopBarPage affaire={affaire} />
          </div>
          <AffaireForm affaireData={affaire} onSubmit={handleUpdateAffaire} isEdit={true} />
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hideSnackbar}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  )
}

export default DetailAffaireId
