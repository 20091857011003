import { useContext } from 'react'
import HomePageDeReception from '../../components/messagerie/pageDeReception/homePageDeReception/HomePageDeReception'

import './chat.scss'
import { AuthContext } from '../../context/AuthContext'
import useFetch from '../../hooks/useFetch'

const Chat = () => {
  const { utilisateur } = useContext(AuthContext)
  const { data: fullMessages, reFetch: reFetchMessages } = useFetch(`/utilisateurs/messagerie/fullmessages/${utilisateur._id}`)

  return (
    <div className="messagerie">
      <div className="messageriecontainer">
        <div className="top-messagerie">
          <h1>Messagerie</h1>
        </div>
        <div className="containermessagerie">
          <HomePageDeReception fullMessages={fullMessages} reFetch={reFetchMessages}/>
        </div>
      </div>
    </div>
  )
}

export default Chat
