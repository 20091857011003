import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import useFetch from '../../../hooks/useFetch';
import GenericList from '../../modules/genericList/GenericList';
import { tacheColumns } from '../../modules/genericList/configGenericList';
import './tacheAPrendre.scss';
import { useFirebase } from '../../../context/Firebase';
import { fileToKey } from '../../../utils/storage';
import ModalZindex from '../../modules/ModalZindex/ModalZindex';
import useModal from '../../modules/Modal/useModal';

const TacheAPrendre = ({ affaireId }) => {
  const { isModalOpen, openModal, closeModal } = useModal();
  const [tache, setTache] = useState({ nomTache: '', description: '', img: null, typeTache: 'A Prendre' });
  const { storage } = useFirebase();
  const [editingTacheId, setEditingTacheId] = useState(null);
  const { data: tachesLoaded, loading, error } = useFetch(`/taches/byAffaire/type/${affaireId}?typeTache=A%20Prendre`);
  const [taches, setTaches] = useState([]);
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    setTaches(tachesLoaded);
  }, [tachesLoaded]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    console.log('Début de handleSubmit');
  
    try {
      let imgURL = tache.img instanceof File ? await fileToKey(tache.img, storage, { path: 'seb' }) : tache.img || '';
      let formData = { ...tache, statut: false, affaireId, typeTache: 'A Prendre', img: imgURL };
  
      console.log('FormData préparé pour l\'envoi:', formData);
  
      if (editingTacheId) {
        console.log('Mise à jour d\'une tâche existante avec formData:', formData);
        // Vérifiez que tous les champs nécessaires sont présents
        console.log('FormData pour PUT:', formData);
        const updateResponse = await axios.put(`/taches/materielAPrendre/${editingTacheId}`, formData);
        console.log('Réponse de la mise à jour:', updateResponse.data);
        
        setTaches((currentTaches) =>
          currentTaches.map((t) => (t._id === editingTacheId ? { ...formData, _id: editingTacheId } : t))
        );
      } else {
        console.log('Tentative de création de la nouvelle tâche', formData);
        const createResponse = await axios.post('/taches', formData);
        console.log('Réponse de la création:', createResponse.data);
  
        if (createResponse.data && createResponse.data._id) {
          setTaches((currentTaches) => [...currentTaches, { ...formData, _id: createResponse.data._id }]);
          
          // Ajout de l'ID de la tâche à l'affaire
          const affaireResponse = await axios.get(`/affaires/${affaireId}`);
          console.log('Réponse de récupération de l\'affaire:', affaireResponse.data);
  
          if (affaireResponse.data) {
            let currentAffaire = affaireResponse.data;
            if (!currentAffaire.taches.includes(createResponse.data._id)) {
              currentAffaire.taches.push(createResponse.data._id);
  
              if (currentAffaire.taches.length === 1) {
                currentAffaire.statut = 'en préparation';
              }
  
              const updateAffaireResponse = await axios.put(`/affaires/${affaireId}`, currentAffaire);
              console.log('Réponse de la mise à jour de l\'affaire avec la nouvelle tâche:', updateAffaireResponse.data);
            }
          }
        }
      }
  
      resetForm();
    } catch (err) {
      console.error('Erreur lors de l\'enregistrement', err);
    }
  };
  
  const resetForm = () => {
    console.log('Réinitialisation du formulaire');
    setTache({ nomTache: '', description: '', img: null, typeTache: 'A Prendre' });
    setImagePreview(null);
    setEditingTacheId(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'img' && files[0]) {
      setImagePreview(URL.createObjectURL(files[0]));
      setTache({ ...tache, [name]: files[0] });
    } else {
      setTache({ ...tache, [name]: value });
    }
  };

  const handleEditClick = (tacheItem) => {
    setEditingTacheId(tacheItem._id);
    setImagePreview(tacheItem.img);
    setTache({ ...tacheItem, img: null }); // Assume img is reset here to avoid sending URL to file input
  };

  const handleDeleteImage = () => {
    setTache({ ...tache, img: null });
    setImagePreview(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleDeleteClick = async (tacheItem) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) {
      try {
        await axios.delete(`/taches/${tacheItem._id}`)
        setTaches(currentTaches => currentTaches.filter(t => t._id !== tacheItem._id))
      } catch (err) {
        console.error('Erreur lors de la suppression', err)
      }
    }
  }

  // Actions pour GenericList
  const tacheActions = [
    {
      type: 'button',
      text: 'Modifier',
      handler: handleEditClick
    },
    {
      type: 'button',
      text: 'X',
      handler: handleDeleteClick
    }
  ]

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur: {error.message}</div>

  return (
    <div className="TacheAPrendre">
      <div className="titleandformtap">
        <h3>A prendre avant le départ chantier:</h3>
        <form onSubmit={handleSubmit} className="tache-formtap">
          <div className="tache-formtaptop">
            <input
              type="text"
              name="nomTache"
              value={tache.nomTache}
              onChange={handleChange}
              placeholder="Nom de la tâche"
              className="input-nomtap"
            />
            <input
              type="text"
              name="description"
              value={tache.description}
              onChange={handleChange}
              placeholder="Description"
              className="input-descriptiontap"
            />
          </div>
          <div className="tache-formtapbottom">
  {imagePreview ? (
    <div>
      <button type="button" className='btn-apercus-modif-supp' onClick={() => openModal()}>Apercevoir / Modifier / Supprimer le document</button>
      <ModalZindex
        title="Aperçu du fichier"
        isOpen={isModalOpen}
        onClose={closeModal}
        content={
          <div>
            <img src={imagePreview} alt="Aperçu du fichier" style={{ width: '100%' }} />
            {/* Bouton pour supprimer le fichier */}
            <button onClick={handleDeleteImage}>Supprimer</button>
          </div>
        }
      />
    </div>
  ) : (
    <input
      type="file"
      name="img"
      ref={fileInputRef}
      onChange={handleChange}
      className="input-filetap"
    />
  )}
  <button type="submit" className="btn-submittap">
    {editingTacheId ? 'Modifier' : 'Envoyer'}
  </button>
</div>
        </form>
      </div>
      <div className="tableTacheAPrendre">
      <GenericList
        data={taches}
        columns={tacheColumns}
        actions={tacheActions}

         />
      </div>
    </div>
  )
}
export default TacheAPrendre
