import Sidebar from '../../components/sidebar/Sidebar'
import { preparationIdMenu } from '../../variables/sidebar/topMenu'
import { useLocation } from 'react-router-dom'

import './preparationId.scss'
import TacheAPrendre from '../../components/tachePlanning/tacheAPrendre/TacheAPrendre'
import TacheAFaire from '../../components/archive/tacheAFaire/TacheAFaire'
import Planning from '../../components/planning/Planning'

const PreparationId = () => {
  const location = useLocation()
  const affairId = location.pathname.split('/')[3]

  console.log(affairId)

  return (
    <div className="preparation">
      <div className="containerpreparation">
        <Sidebar menu={preparationIdMenu} />
        <div className="preparationcontainer">
          PreparationId
          <div className="toppreparation">
            <TacheAPrendre affaireId={affairId} />
            <TacheAFaire affaireId={affairId} />
          </div>
          <div className="planningpreparation">
            <Planning affaireId={affairId} />
          </div>
          <div className="planningpreparation2">
            <Planning affaireId={affairId} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreparationId
