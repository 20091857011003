import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function getFileExtension(fileName) {
  const splitName = fileName.split('.');
  return splitName[splitName.length - 1];
}

async function uploadFile(file, storage, options) {
  const prevPath = options?.path ? (options?.path[options?.path.length - 1] === '/' ? options?.path : `${options?.path}/`) : '';
  const fileKey = `${prevPath}${uuidv4()}.${getFileExtension(file.name)}`;
  const storageRef = ref(storage, fileKey);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}

async function fileToKey(file, storage, options) {
  if (!file) {
    return undefined;
  } else if (typeof file === 'string' && file === 'REMOVE') {
    return null;
  } else if (file instanceof File) {
    return await uploadFile(file, storage, options);
  } else {
    return undefined;
  }
}

async function fileToData(file, storage, options) {
  return {
    key: !file ? undefined : typeof file === 'string' && file === 'REMOVE' ? null : file?.length ? await uploadFile(file[0], storage, options) : undefined,
    name: file && file[0] && typeof file !== 'string' ? file[0].name : ''
  };
}

async function filesToData(files, storage) {
  return files
    ? await Promise.all(
      Array.from(files).map(async (file) => ({
        key: await uploadFile(file, storage),
        name: file.name,
      }))
    )
    : [];
}

export { getFileExtension, uploadFile, fileToKey, fileToData, filesToData };
