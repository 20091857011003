import { useContext, useState } from 'react'
import BoiteDeMessageEnvoyer from '../../boiteDeMessageEnvoyer/BoiteDeMessageEnvoyer'
import ContainReceptionMessagerie from '../../boiteDeReception/ContainReceptionMessagerie'
import SideBarMessagerie from '../sideBarMessagerie/SideBarMessagerie'
import './homePageDeReception.scss'
import BoiteLectureMessage from '../../boiteLectureMessage/BoiteLectureMessage'
import BoiteDeReponseMessage from '../../boiteDeReponseMessage/BoiteDeReponseMessage'
import { AuthContext } from '../../../../context/AuthContext'

const HomePageDeReception = ({ fullMessages, reFetch }) => {
  const { utilisateur } = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState('boiteReception')
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [isReplying, setIsReplying] = useState(false)
  console.log(utilisateur)

  // trie des commentaires
  const commentsReception = {
    commentsLu: fullMessages.commentsLu || [],
    commentsNonLu: fullMessages.commentsNonLu || []
  }

  // Pour gérer le message sélectionné
  const handleSelectMessage = (message) => {
    console.log('Message sélectionné:', message)
    setSelectedMessage(message.commentaireDetails)
  }

  // Pour gérer le clic sur le bouton "Répondre"
  const handleReplyClick = () => {
    setIsReplying(true)
  }

  // Pour revenir de `BoiteDeReponseMessage` à l'état normal
  const handleCloseReply = () => {
    setIsReplying(false)
    setSelectedMessage(null)
  }
  // Définir resetSelectedMessage pour réinitialiser le message sélectionné
  const resetSelectedMessage = () => {
    setSelectedMessage(null)
  }

  console.log('fullMessages:', fullMessages)
  console.log('tachesReception:', commentsReception)

  return (
    <div className="HomePageDeReception">
      <div className="side-home-pdr">
        <SideBarMessagerie
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          resetSelectedMessage={resetSelectedMessage}
        />
      </div>
      <div className="contain-home-pdr">
        <div className="top-home-pdr">action</div>
        <div className="middle-parent-pdr">
          <div className="middle-home-pdr">
            {isReplying
              ? (
              <BoiteDeReponseMessage
                onClose={handleCloseReply}
                commentaireReponse={selectedMessage}
                reFetch={reFetch}
              />
                )
              : selectedMessage
                ? (
              <BoiteLectureMessage
                commentaire={selectedMessage}
                reFetch={reFetch}
                onReply={handleReplyClick}
              />
                  )
                : (
              <>
                {activeTab === 'boiteReception' && (
                  <ContainReceptionMessagerie
                    comments={commentsReception}
                    onSelectMessage={handleSelectMessage}
                  />
                )}
                {activeTab === 'messageEnvoyer' && <BoiteDeMessageEnvoyer />}
              </>
                  )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageDeReception
