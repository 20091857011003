import { useState } from 'react'

const useSnackbar = () => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const showSnackbar = (msg) => {
    setMessage(msg)
    setOpen(true)
  }

  const hideSnackbar = () => {
    setOpen(false)
    setMessage('')
  }

  return { open, message, showSnackbar, hideSnackbar }
}

export default useSnackbar
