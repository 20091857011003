import { useState } from 'react'
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import fr from 'date-fns/locale/fr'
import './ajoutDateEcheance.scss'

registerLocale('fr', fr)
setDefaultLocale('fr')

const AjoutDateEcheance = ({ tache, setTache }) => {
  const [showDatePicker, setShowDatePicker] = useState(false)

  const handleDateChange = (date) => {
    setTache({ ...tache, dateEcheance: date })
    setShowDatePicker(false)
  }

  const handleRemoveDate = () => {
    setTache({ ...tache, dateEcheance: null })
    setShowDatePicker(false)
  }

  const formattedDate =
    tache.dateEcheance && tache.dateEcheance.toLocaleDateString('fr-FR')

  return (
    <div>
      {showDatePicker
        ? (
        <div className="reactDatePicker-ftb">
          <ReactDatePicker
            selected={tache.dateEcheance}
            onChange={(date) => handleDateChange(date)}
            locale="fr"
            dateFormat="dd/MM/yyyy"
            placeholderText="Date d'échéance"
            className="datePicker-ftb"
          />
          <button onClick={handleRemoveDate} className="remove-date-btn">
            x
          </button>
        </div>
          )
        : (
        <button onClick={() => setShowDatePicker(true)}>
          {formattedDate || "Ajouter une date d'échéance"}
        </button>
          )}
    </div>
  )
}

export default AjoutDateEcheance
