import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getWeek, isWeekend, parseISO, isValid ,getISOWeek} from 'date-fns'; // fusionné
import { fr } from 'date-fns/locale';
import './planningJour.scss';

const PlanningJour = ({ selectedDate }) => {
    const [taches, setTaches] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tachesParJour, setTachesParJour] = useState({});
    const [selectedWeek, setSelectedWeek] = useState(getWeek(selectedDate));
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());

    const fetchTaches = async () => {
        setIsLoading(true);
        const currentYear = format(selectedDate, 'yyyy');
        const selectedMonth = selectedDate.getMonth();
        try {
          const { data } = await axios.get(`/affaires/tacheschantier/month/${currentYear}/${selectedMonth + 1}`);
          const tachesOrganisees = {};
          console.log("data", data)
          data.forEach(affaire => {
            affaire.tachesChantier.forEach(tache => {
              const interval = eachDayOfInterval({
                start: new Date(tache.dateDepart),
                end: new Date(tache.dateEcheance)
              }).filter(day => !isWeekend(day));
      
              interval.forEach(day => {
                const dayStr = format(day, 'yyyy-MM-dd');
                if (!tachesOrganisees[dayStr]) {
                  tachesOrganisees[dayStr] = [];
                }
      
                // Assumer que tache.affectations est déjà structuré correctement par le serveur
                const affectationsDuJour = tache.affectations.filter(affectation =>
                  format(parseISO(affectation.date), 'yyyy-MM-dd') === dayStr
                );
      
                tachesOrganisees[dayStr].push({
                  ...tache,
                  nomAffaire: affaire.nomAffaire,
                  refAffaire: affaire.refAffaire,
                  affaireId: affaire._id,
                  ville: affaire.ville,
                  affectationsDuJour, // Utiliser directement car format attendu
                });
              });
            });
          });
      
          setTachesParJour(tachesOrganisees);
        } catch (error) {
          console.error("Erreur lors du chargement des tâches", error);
        }
      };

      useEffect(() => {
        fetchTaches();
      }, [selectedDate]); 

    //fonction pour trier les taches par jour
    useEffect(() => {
        if (Object.keys(tachesParJour).length > 0) {
          setTaches(tachesParJour[format(selectedDate, 'yyyy-MM-dd')] || []);
          setIsLoading(false);
        }
      }, [tachesParJour, selectedDate]);


    return (
        <div className='PlanningJour'>
        <h2>Planning du {format(selectedDate, 'EEEE dd MMMM yyyy', { locale: fr })}</h2>
        {isLoading ? (
                <p>Chargement...</p>
            ) : taches.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Affaire</th>
                            <th>Tâche</th>
                            <th>Ville</th>
                            <th>Employé(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taches.map((tache ,index) => {
                            const dateString = format(selectedDate, 'yyyy-MM-dd');
                            const affectationsPourJour = tache.affectations.find(affectation => 
                                format(parseISO(affectation.date), 'yyyy-MM-dd') === dateString
                            );

                            const personnesAffectees = affectationsPourJour
                                ? [...affectationsPourJour.chefs, ...affectationsPourJour.ouvriers].map(p => `${p.utilisateur.prenom.charAt(0)}${p.utilisateur.nom.slice(0, 2)}`).join(", ")
                                : "Personne affectée";

                            return (
                                <tr key={tache._id}>
                                    <td>{tache.nomAffaire}</td>
                                    <td>{tache.nomTache}</td>
                                    <td>{tache.ville}</td>
                                    <td>{personnesAffectees}</td>
                                </tr>
                            );
                        }
                        )}
                    </tbody>
                </table>
            ) : (
                <p>Aucune tâche à réaliser ce jour-là.</p>
            )}
        </div>
    );
};

export default PlanningJour;
