import axios from 'axios'
import { useState } from 'react'
import './forgot.scss'
import { NavLink } from 'react-router-dom'

import Logo from '../../assets/images/logo.jpg'

const Forgot = () => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState() // LOADING | SENT

  const handleClick = async (e) => {
    if (!email?.length) {
      return alert("Merci d'indiquer votre email")
    }
    setStatus('LOADING')
    try {
      await axios.post('/auth/forgot', { email })
    } catch (e) {}
    setStatus('SENT')
  }

  return (
    <div className="login">
      <div className="lContainer">
        <img src={Logo} alt="logo" />
        <input
          type="email"
          placeholder="email"
          id="email"
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
          className="lInput"
        />
        <button disabled={!!status} onClick={handleClick} className="lButton">
          Email de récupération
        </button>
        <NavLink to="/login">
          Revenir à la page de login
        </NavLink>
        {status === 'SENT' ? <span>{`Nous avons envoyé un email sur ${email} pour vous permettre de réinitialiser votre mot de passe`}</span> : null}
      </div>
    </div>
  )
}

export default Forgot
