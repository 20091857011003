import { useState, useRef, useEffect } from 'react'
import { Select, MenuItem } from '@mui/material'
import LigneMois from './LigneMois'
import LigneJour from './LigneJour'
import LigneTache from './LigneTache'
import LigneNom from './LigneNom'
import './planning.scss'

const Planning = ({ toutesLesTaches, onTacheSelect }) => {
  const dayWidth = 30
  const [typeTacheFiltre, setTypeTacheFiltre] = useState('Toutes les tâches')
  const contentScrollRef = useRef(null)
  const aScrollRef = useRef(null)
  const [taches, setTaches] = useState([])

  useEffect(() => {
    const typesInclus = ['Chantier', 'Observation','Document','Bureau', 'Administratif', 'Technique', 'sous traitant']
    const tachesFiltrees = toutesLesTaches.filter(tache =>
      typesInclus.includes(tache.typeTache)
    )
    setTaches(tachesFiltrees)
  }, [toutesLesTaches])

  console.log(taches)

  const tachesFiltrees = taches.filter(
    (tache) => typeTacheFiltre === 'Toutes les tâches' || tache.typeTache === typeTacheFiltre
  )

  // Calculer la plage de dates pour toutes les tâches
  const getRangeOfDates = (taches) => {
  // Trouver la date de début la plus ancienne et la date de fin la plus récente parmi toutes les tâches
    const startDate = new Date(Math.min(...taches.map(t => new Date(t.dateDepart).getTime())))
    const endDate = new Date(Math.max(...taches.map(t => new Date(t.dateEcheance).getTime())))

    return { startDate, endDate }
  }

  // Déterminer les mois à afficher dans le planning
  const getMonthsToDisplay = (taches) => {
    const monthNames = {
      janvier: 0,
      février: 1,
      mars: 2,
      avril: 3,
      mai: 4,
      juin: 5,
      juillet: 6,
      août: 7,
      septembre: 8,
      octobre: 9,
      novembre: 10,
      décembre: 11
    }
    const months = new Set() // Ensemble pour stocker les mois et années uniques
    let latestEndDate = new Date(0) // Date initiale pour trouver la date de fin la plus éloignée

    taches.forEach((tache) => {
      const start = new Date(tache.dateDepart)
      const end = new Date(tache.dateEcheance)

      // Mettre à jour la date de fin la plus éloignée si nécessaire
      if (end > latestEndDate) {
        latestEndDate = end
      }

      // eslint-disable-next-line no-unmodified-loop-condition
      while (start <= end) {
      // Ajouter le mois et l'année de la date de début à l'ensemble
        months.add(start.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }))
        start.setMonth(start.getMonth() + 1)
      }
    })

    // Ajouter le mois et l'année de la date de fin la plus éloignée
    months.add(latestEndDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }))

    // Convertir l'ensemble des mois en tableau, trier les dates et les renvoyer
    return Array.from(months).sort((a, b) => {
      const [monthA, yearA] = a.split(' ')
      const [monthB, yearB] = b.split(' ')
      const dateA = new Date(yearA, monthNames[monthA])
      const dateB = new Date(yearB, monthNames[monthB])
      return dateA - dateB
    })
  }

  // Obtenir la plage de dates et les mois à afficher
  const { startDate, endDate } = getRangeOfDates(tachesFiltrees)
  const monthsToDisplay = getMonthsToDisplay(tachesFiltrees)

  // trie les taches par dateDepart puis par dateEcheance
  const tachesFiltreesEtTrie = tachesFiltrees.sort((a, b) => {
    if (a.dateDepart && b.dateDepart) {
      const dateDepartA = new Date(a.dateDepart)
      const dateDepartB = new Date(b.dateDepart)
      if (dateDepartA.getTime() === dateDepartB.getTime()) {
        // Si les dates de départ sont identiques, trier par dateEcheance
        return new Date(a.dateEcheance) - new Date(b.dateEcheance)
      }
      return dateDepartA - dateDepartB
    }
    // Ensuite, trier les tâches sans dateDepart par createdAt
    if (!a.dateDepart && !b.dateDepart) {
      return new Date(a.createdAt) - new Date(b.createdAt)
    }
    // Prioriser les tâches avec dateDepart
    return a.dateDepart ? -1 : 1
  })

  console.log(taches)

  // Logique pour le scroll horizontal

  const syncScroll = (sourceRef, targetRef) => () => {
    const sourceElement = sourceRef.current
    const targetElement = targetRef.current

    if (sourceElement && targetElement) {
      targetElement.scrollTop = sourceElement.scrollTop
    }
  }

  useEffect(() => {
    const contentScroll = contentScrollRef.current
    const aScroll = aScrollRef.current

    if (contentScroll && aScroll) {
      contentScroll.addEventListener('scroll', syncScroll(contentScrollRef, aScrollRef))
      aScroll.addEventListener('scroll', syncScroll(aScrollRef, contentScrollRef))
    }

    return () => {
      if (contentScroll && aScroll) {
        contentScroll.removeEventListener('scroll', syncScroll(contentScrollRef, aScrollRef))
        aScroll.removeEventListener('scroll', syncScroll(aScrollRef, contentScrollRef))
      }
    }
  }, [])
  console.log(monthsToDisplay)

  return (
    <div className="planningContainer-tachespl">
      <div className="leftplanningContainer-tachespl">
      <Select
        className="selectleftplanningContainer-tachespl"
        value={typeTacheFiltre}
        onChange={(e) => setTypeTacheFiltre(e.target.value)}
        label="Type de tâche"
      >
        <MenuItem value="Toutes les tâches">Toutes les tâches</MenuItem>
        <MenuItem value="Chantier">Chantier</MenuItem>
        <MenuItem value="Observation">Observations</MenuItem>
        <MenuItem value="Document">Documents</MenuItem>
      </Select>
        <div className="a-tachespl" ref={aScrollRef}>
          {tachesFiltreesEtTrie.map((tache) => (
            <LigneNom key={tache._id} tache={tache} />
          ))}
        </div>
      </div>

      <div className="headerScroll-tachespl">
        <div className="headerheaderScroll-tachespl">
          {/* <LigneAnnee annee={startDate.getFullYear()} /> */}
          <LigneMois months={monthsToDisplay} dayWidth={dayWidth} />
          <LigneJour months={monthsToDisplay} dayWidth={dayWidth} />
        </div>
        <div className="contentheaderScroll-tachespl" ref={contentScrollRef}>
          {tachesFiltreesEtTrie.map((tache) => (
            <LigneTache
              key={tache._id}
              tache={tache}
              startDate={startDate}
              endDate={endDate}
              dayWidth={dayWidth}
              months={monthsToDisplay}
              onTacheSelect={onTacheSelect}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Planning
