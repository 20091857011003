
import { useParams, useNavigate,useSearchParams } from 'react-router-dom';
import "./affaireId.scss";
import useFetch from "../../hooks/useFetch";
import { Rnd } from 'react-rnd' 
import axios from 'axios';
import { useEffect, useRef, useState } from "react";
import AffairePresentation from "../../components/Affaire/AffairePresentation/AffairePresentation";
import Affaireassignation from "../../components/Affaire/affaireassignation/Affaireassignation";
import AffaireRapports from "../../components/Affaire/affaireRapports/AffaireRapports";
import AffaireDocuments from "../../components/Affaire/affaireDocuments/AffaireDocuments";
import TacheProgression from "../../components/tachePlanning/tacheProgression/TacheProgression";
import GraphiqueAffaireId from "../../components/widget/graphiqueAffaireId/GraphiqueAffaireId";
import TachesSuivisParAffaire from "../../components/tachessuivis/tachesSuivisParAffaire/TachesSuivisParAffaire";
import AffaireResume from "../../components/Affaire/affaireResume/AffaireResume";
import AffaireRapportsConduc from "../../components/Affaire/affaireRapportsConduc/AffaireRapportsConduc";
import AffichageTachePlanningNew from "../../components/planningTachesNew/AffichageTachePlanningNew/AffichageTachePlanningNew";
import { useLocation } from "react-router-dom";
import ChatAffaireId from "../../components/chat/ChatAffaireId/ChatAffaireId";
import PlanningEntreprise from "../planningEntreprise/PlanningEntreprise";
import AffaireFormOnglet from '../../components/affaireFormOnglet/AffaireFormOnglet';

const AffaireId = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const { data: affairedata, reFetch: refetchaffaire } = useFetch(
    `/affaires/${id}`
  );
  const { data: tachesdata, reFetch: refetchtaches } = useFetch(
    `/taches/byAffaire/${id}`
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const moreOptionsRef = useRef(null);
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const docId = searchParams.get('docId');
  const [affaires, setAffaires] = useState([]);
  const [selectedAffaireId, setSelectedAffaireId] = useState(id);

  //select choix de l'affaire 
  useEffect(() => {
    const fetchAffaires = async () => {
      try {
        const { data } = await axios.get('/affaires');
        const filteredData = data.filter(affaire =>
          ['création', 'en préparation', 'en cours', 'terminé', 'archivé', 'a entrer au planning', 'en attente', 'en pause'].includes(affaire.statut)
        );
        setAffaires(filteredData);
      } catch (error) {
        console.error('Erreur lors du chargement des affaires', error);
      }
    };

    fetchAffaires();
  }, []);

  useEffect(() => {
    // Ceci met à jour `selectedAffaireId` chaque fois que l'ID dans l'URL change
    setSelectedAffaireId(id);
  }, [id]);


  const handleAffaireChange = (event) => {
    const newAffaireId = event.target.value;
    if (newAffaireId) {
      window.location.href = `/affaires/${newAffaireId}`;
    }
  };

  //Modale
  const [formToShow, setFormToShow] = useState(null);
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [hauteurMax, setHauteurMax] = useState("500px");

    // Ajuster la hauteur max de la fenêtre
    useEffect(() => {
      const ajusterHauteur = () => {
        // Exemple : définir la hauteur max à 70% de la hauteur de la fenêtre
        const nouvelleHauteur = window.innerHeight * 0.7
        setHauteurMax(`${nouvelleHauteur}`)
      }
      // Ajuster la hauteur lors du montage du composant
      ajusterHauteur()
      // Ajuster la hauteur à chaque redimensionnement de la fenêtre
      window.addEventListener('resize', ajusterHauteur)
      // Nettoyer l'écouteur d'événement lors du démontage du composant
      return () => window.removeEventListener('resize', ajusterHauteur)
    }, []) // Le tableau vide signifie que cet effet ne s'exécute qu'au montage
  
    // Déterminez la taille initiale en fonction du formulaire à afficher
    const getWindowSize = () => {
      switch (formToShow) {
        case 'chatAffaire':
          return { width: '700px', height: '900px' }
        case 'PlanningEntreprise':
          return { width: '90%', height: '95%' }
        default:
          return { width: '320px', height: '200px' } // Taille par défaut
      }
    }
  
    // Déterminez la taille initiale en fonction du formulaire à afficher...
    const initialSize = getWindowSize()
  
    // Obtenir le titre du formulaire en fonction du formulaire à afficher
    const getTitle = () => {
      switch (formToShow) {
        case 'chatAffaire':
          return "Chat Affaire";
        case 'PlanningEntreprise':
          return "Planning de l'Entreprise";
        default:
          return "Fenêtre";
      }
    }

  // Gestionnaire pour mettre à jour la position après le déplacement
  const onDragStop = (e, d) => {
    setPosition({ x: d.x, y: d.y })
  }
    //Ferme le modale 
    const handleCloseForm = () => {
      setFormToShow(null)
    }
    const handleFormChange = (formType) => {
      setFormToShow(formType)
      handleCloseDialog()
    }

  useEffect(() => {
    const taskToDelay = searchParams.get('taskToDelay')
    const delayDate = searchParams.get('delayDate')
    console.log('query', taskToDelay, delayDate)

    if (taskToDelay && delayDate){
      axios.put(`elements/${taskToDelay}`, delayDate === 'FINISH' ? { etatRealisation: 'Terminé' } : { reminderDate: delayDate })
      setSearchParams({})
    }

  }, [])

  // Ferme le menu si on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        moreOptionsRef.current &&
        !moreOptionsRef.current.contains(event.target)
      ) {
        setShowMore(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreOptionsRef]);

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setShowMore(false); // Ferme le menu déroulant lorsqu'un onglet est sélectionné
  };

  const tabs = [
    {
      name: "Tâche Planning",
      component: (
        <AffichageTachePlanningNew affaireId={id} affaireData={affairedata} />
      ),
    },
    {
      name: "Documents",
      component: <AffaireDocuments affaireId={id} affairedata={affairedata} docId={docId} />,
    },
    {
      name: "Modifiactaion Affaire",
      component: <AffaireFormOnglet affaire={affairedata} reFetch={refetchaffaire}/>, },
    //{ name: "Accueil",component: ( <AffairePresentationaffaireData={affairedata} reFetch={refetchaffaire} /> ),},
    // { name: "Assignation et role", component: <Affaireassignation affairedata={affairedata} />, },
    {
      name: "Rapport fin de Journée",
      component: <AffaireRapports affaireId={id} />,
    },
     //{ name: "Rapport de Passage",component: <AffaireRapportsConduc affaireId={id} />,},
    {
      name: "Taches Suivis Avancement",
      component: (
        <TacheProgression taches={tachesdata} reFetch={refetchtaches} affaireId={id}/>
      ),
    },
    {
      name: "Résumer Affaire",
      component: <AffaireResume affaire={affairedata} taches={tachesdata} />,
    },
    {
      name: "Chat",
      component: <ChatAffaireId affaireId={id} />,
    },
    {
      name: "Board Affaire",
      component: (
        <div className="oglet-dasch-affair-id">
          <GraphiqueAffaireId affaireId={id} />{" "}
          <TachesSuivisParAffaire affaireId={id} />
        </div>
      ),
    },
  ];

  return (
    <div className="affaireId">
      <div className="containerAffaireId">
        <div className="topcontainerAffaireId">
          <div className="custom-tabs">
            {tabs.slice(0, 3).map((tab, index) => (
              <div
                key={index}
                className={`tab ${selectedTab === index ? "active" : ""}`}
                onClick={() => handleTabChange(index)}
              >
                {tab.name}
              </div>
            ))}
            <div
              className="tab more-options"
              onClick={() => setShowMore(!showMore)}
            >
              ...
            </div>
            {showMore && (
              <div className="more-options-menu show" ref={moreOptionsRef}>
                {tabs.slice(3).map((tab, index) => (
                  <div
                    key={index + 3}
                    className="tab" 
                    onClick={() => handleTabChange(index + 3)}
                  >
                    {tab.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="titleAffairePlanningId">
            <div className="btn-group-titleAffairePlanningId">
              <button
                className="btn-btn-primary"
                onClick={() => setFormToShow("PlanningEntreprise")}
              >
                Planning
              </button>
              <button
                className="btn-btn-primary"
                onClick={() => setFormToShow("chatAffaire")}
              >
                Chat
              </button>
            </div>
            VOUS ETES SUR LA FEUILLE DE :  
            <div class="select-wrapper">
              <select onChange={handleAffaireChange} value={selectedAffaireId} className="select-affaire">
                <option value="">Sélectionner une affaire</option>
                {affaires.map((affaire) => (
                  <option key={affaire._id} value={affaire._id}>
                    {affaire.nomAffaire}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="test">{tabs[selectedTab].component}</div>
      </div>
      {formToShow && (
        <Rnd
        size={{ width: initialSize.width, height: initialSize.height }}
        position={position}
        onDragStop={onDragStop}
        dragHandleClassName="form-header"
        style={{ zIndex: 1000 }}
      >
                <div className="form-container" style={{ width: '100%', height: '100%' }}>
                <div className="form-header">
                  <span className="form-title">{getTitle()}</span>
                  <button className="close-button" onClick={handleCloseForm}>×</button>
                </div>
                <div className="form-body" style={{ width: '100%', height: '100%' }}>
                        {formToShow === 'chatAffaire' && (
                            <ChatAffaireId affaireId={id} />
                        )}
                        {formToShow === 'PlanningEntreprise' && (
                            <PlanningEntreprise/>
                        )}
                    </div>
                </div>
                </Rnd>
            )}
    </div>
  )
}

  export default AffaireId;
