import React, { useState, useEffect } from 'react';
import './planningEntreprise.scss';
import axios from 'axios';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getWeek, isWeekend, parseISO, isValid ,getISOWeek} from 'date-fns'; // fusionné
import { fr , } from 'date-fns/locale';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, circularProgressClasses } from '@mui/material';
import { Rnd } from 'react-rnd';
import AffectationUtilisateur from '../../components/planningEntreprise/affectationUtilisateur/AffectationUtilisateur';
import TacheFormPlanning from '../../components/tachePlanning/tacheFormPlanning/TacheFormPlanning';
import TacheCreationForm from '../../components/planningEntreprise/TacheCreationForm/TacheCreationForm';
import AffichageTachePlanningNew from '../../components/planningTachesNew/AffichageTachePlanningNew/AffichageTachePlanningNew';

const PlanningEntreprise = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [tachesParJour, setTachesParJour] = useState({});
  const currentYear = new Date().getFullYear(); // Obtenir l'année courante
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTache, setSelectedTache] = useState(null);
  const [hauteurMax, setHauteurMax] = useState('500px')
  const [position, setPosition] = useState({ x: 100, y: 100 })
  const [formToShow, setFormToShow] = useState(null)
  const [weekFilter, setWeekFilter] = useState('');
  const [dayFilter, setDayFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Ajuster la hauteur max de la fenêtre
  useEffect(() => {
    const ajusterHauteur = () => {
      //définir la hauteur max à 70% de la hauteur de la fenêtre
      const nouvelleHauteur = window.innerHeight * 0.7
      setHauteurMax(`${nouvelleHauteur}`)
    }
    // Ajuster la hauteur lors du montage du composant
    ajusterHauteur()
    // Ajuster la hauteur à chaque redimensionnement de la fenêtre
    window.addEventListener('resize', ajusterHauteur)
    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => window.removeEventListener('resize', ajusterHauteur)
  }, [])

  // modale
  // Déterminez la taille initiale en fonction du formulaire à afficher
  const getWindowSize = () => {
    switch (formToShow) {
      case 'Affectation':
        return { width: '500px', height: '750px' }
        case 'TacheForm':
          return { width: '500px', height: '500px' }
        case 'TacheCreationForm':
          return { width: '500px', height: '500px' }
        case 'PlanningAffaireNew':
          return { width: '90%', height: '70%' }
      default:
        return { width: '320px', height: '200px' } // Taille par défaut
    }
  }

  // Déterminez la taille initiale en fonction du formulaire à afficher...
  const initialSize = getWindowSize()
  // Obtenir le titre du formulaire en fonction du formulaire à afficher
  const getTitle = () => {
    switch (formToShow) {
      case 'Affectation':
        return "Affectation de Tâche";
      case 'TacheForm':
        return "Formulaire de Tâche";
      case 'TacheCreationForm':
        return "Création de Tâche";
      case 'PlanningAffaireNew':
        return "Planning de l'Affaire";
      default:
        return "Fenêtre";
    }
  }
    // Gestionnaire pour mettre à jour la position après le déplacement
    const onDragStop = (e, d) => {
        setPosition({ x: d.x, y: d.y })
      }
    
      // dialogue
      const handleRowClick = (tache, dateString) => {
        setSelectedTache({tache, dateString});
        setOpenDialog(true);
      };

      const handleDialogClose = () => {
        setOpenDialog(false)
      }

      const handleFormChange = (formType) => {
        setFormToShow(formType)
        handleDialogClose()
      }
    
      const handleCloseForm = () => {
        setFormToShow(null)
      }

        // Ajoutez cette fonction pour rafraîchir les tâches après une affectation
         const onAffectationComplete = () => {
            fetchTaches();
            handleCloseForm(); // Fermer le formulaire si nécessaire
        };

      // Fonction pour naviguer vers une affaire
      const goToAffaire = (affaireId) => {
        window.location.href = `/affaires/${affaireId}`; // Ou utiliser votre méthode de navigation
      };

      // Fonction pour gérer les affectations directes
      const handleDirectAffectation = (tache, dateString, event) => {
        event.stopPropagation();
        setSelectedTache({ tache, dateString });
        handleFormChange('Affectation');
        setOpenDialog(false);
      };
      
    
      // Fonction pour récupérer les tâches du serveur
      const fetchTaches = async () => {
        try {
          const { data } = await axios.get(`/affaires/tacheschantier/month/${currentYear}/${selectedMonth + 1}`);
          const tachesOrganisees = {};
          console.log("data", data)
          data.forEach(affaire => {
            affaire.tachesChantier.forEach(tache => {
              const interval = eachDayOfInterval({
                start: new Date(tache.dateDepart),
                end: new Date(tache.dateEcheance)
              }).filter(day => !isWeekend(day));
      
              interval.forEach(day => {
                const dayStr = format(day, 'yyyy-MM-dd');
                if (!tachesOrganisees[dayStr]) {
                  tachesOrganisees[dayStr] = [];
                }
      
                // Assumer que tache.affectations est déjà structuré correctement par le serveur
                const affectationsDuJour = tache.affectations.filter(affectation =>
                  format(parseISO(affectation.date), 'yyyy-MM-dd') === dayStr
                );
      
                tachesOrganisees[dayStr].push({
                  ...tache,
                  nomAffaire: affaire.nomAffaire,
                  refAffaire: affaire.refAffaire,
                  affaireId: affaire._id,
                  ville: affaire.ville,
                  affectationsDuJour, // Utiliser directement car format attendu
                });
              });
            });
          });
      
          setTachesParJour(tachesOrganisees);
        } catch (error) {
          console.error("Erreur lors du chargement des tâches", error);
        }
      };
      // Utilisez useEffect pour appeler fetchTaches lors du chargement du composant et à chaque changement des dépendances
      useEffect(() => {
        fetchTaches();
      }, [selectedMonth, currentYear]); // Dépendances de l'effet
    
  // Générer tous les jours du mois sélectionné
  const startDate = startOfMonth(new Date(currentYear, selectedMonth));
  const endDate = endOfMonth(startDate);
  const daysOfMonth = eachDayOfInterval({ start: startDate, end: endDate })
    .filter(day => !isWeekend(day)); // Exclure les weekends

  // Fonction pour convertir les secondes en heures et minutes
    function secondsToHoursMinutes(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.round((seconds % 3600) / 60);
      return `${hours}h${minutes.toString().padStart(2, '0')}`;
    }

      //pour le filtrage 

      const handleWeekFilterChange = (event) => {
        setWeekFilter(event.target.value);
      };
      
      const handleDayFilterChange = (event) => {
        setDayFilter(event.target.value);
      };
      
      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
      };

      // Fonction pour réinitialiser les filtres
      const resetFilters = () => {
        setWeekFilter('');
        setDayFilter('');
        setSearchTerm('');
      };

      // Ajustez le filtrage pour inclure toutes les données
      const daysOfMonthFiltered = daysOfMonth.filter(day => {
        const weekNumber = getWeek(day, { weekStartsOn: 1, locale: fr }).toString();
        const dayOfWeek = format(day, 'EEEE', { locale: fr }).toLowerCase();
        const dayStr = format(day, 'yyyy-MM-dd');
        const tachesDuJour = tachesParJour[dayStr] || [];

        if (weekFilter && weekFilter !== weekNumber) {
          return false;
        }

        if (dayFilter && dayFilter !== dayOfWeek) {
          return false;
        }

        // Élargir la recherche pour inclure d'autres champs
        if (searchTerm) {
          return tachesDuJour.some(tache =>
            tache.nomTache.toLowerCase().includes(searchTerm) ||
            tache.nomAffaire.toLowerCase().includes(searchTerm) ||
            tache.refAffaire.toLowerCase().includes(searchTerm) ||
            tache.ville.toLowerCase().includes(searchTerm) ||
            (tache.description && tache.description.toLowerCase().includes(searchTerm))
          );
        }

        return true;
      });

      // Fonction pour obtenir les numéros de semaine uniques
      const getWeekNumbers = daysOfMonth => {
        const weekNumbers = new Set();
        daysOfMonth.forEach(day => {
          weekNumbers.add(getWeek(day, { weekStartsOn: 1, locale: fr }));
        });
        return Array.from(weekNumbers);
      };

      // Fonction pour générer une liste de jours de la semaine
      const dayNames = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi']; // Exclure samedi et dimanche si vous voulez

      // Fonction pour obtenir le dernier jour de chaque type pour chaque semaine
      const getLastDaysOfTypeEachWeek = (daysOfMonthFiltered) => {
        const lastDays = {}; // Stocke le dernier jour de chaque type pour chaque semaine
      
        daysOfMonthFiltered.forEach(day => {
          const weekNumber = getISOWeek(day, { weekStartsOn: 1, locale: fr });
          const dayOfWeek = format(day, 'EEEE', { locale: fr }).toLowerCase();
      
          if (!lastDays[weekNumber]) {
            lastDays[weekNumber] = {};
          }
      
          lastDays[weekNumber][dayOfWeek] = day;
        });
      
        return lastDays;
      };
      
      const lastDaysOfTypeEachWeek = getLastDaysOfTypeEachWeek(daysOfMonthFiltered);

      console.log("selectedTache", selectedTache)
    return (
        <div className="PlanningEntreprise">
          <div className="month-tabs-PlanningEntreprise">
            {months.map((month, index) => (
              <button
                key={index}
                className={`month-tab-PlanningEntreprise ${selectedMonth === index ? 'active' : ''}`}
                onClick={() => setSelectedMonth(index)}
              >
                {month}
              </button>
            ))}
          </div>
          <div className="filters-PlanningEntreprise">
            <input
              type="text"
              placeholder="Rechercher une tâche / affaire"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <select value={weekFilter} onChange={handleWeekFilterChange}>
              <option value="">Toutes les semaines</option>
              {getWeekNumbers(daysOfMonth).map(weekNumber => (
                <option key={weekNumber} value={weekNumber}>
                  Semaine {weekNumber}
                </option>
              ))}
            </select>
            <select value={dayFilter} onChange={handleDayFilterChange}>
              <option value="">Tous les jours</option>
              {dayNames.map(dayName => (
                <option key={dayName} value={dayName}>
                  {dayName.charAt(0).toUpperCase() + dayName.slice(1)}
                </option>
              ))}
            </select>
            <button onClick={resetFilters}>Réinitialiser les filtres</button>
          </div>
          <div className="title-PlanningEntreprise">
             <div className="btn-ajout-tache-PlanningEntreprise">
                <button onClick={() => handleFormChange('TacheCreationForm')}>+</button>
                <span>Ajouter une Tache / Observation</span>
            </div>
          <h2>{months[selectedMonth]} {new Date().getFullYear()}</h2>
          <div className="droite-title-PlanningEntreprise"></div>
          </div>
          <div className="month-content-PlanningEntreprise">
        
            <table className='table-PlanningEntreprise'>
              <thead className='head-PlanningEntreprise'>
                <tr>
                  {/*<th>S</th>*/}
                  <th>Jour</th>
                  <th>N°</th>
                  <th>Chantier</th>
                  <th>Ref</th>
                  <th>Lieu</th>
                  <th>Description de la tâche</th>
                  <th>Nom</th>
                  <th>Observations</th>
                  <th>H</th>
                </tr>
              </thead>
                <tbody>
                  {daysOfMonthFiltered.map((day, index) => {
                    const dateString = format(day, 'yyyy-MM-dd');
                    const tachesDuJour = tachesParJour[dateString] || [];
                    const dayOfWeek = format(day, 'EEEE', { locale: fr }).toLowerCase();
                    const weekNumber = getISOWeek(day, { weekStartsOn: 1, locale: fr });
                    const dayOfWeekClass = format(day, 'EEEE', { locale: fr }).toLowerCase();
                    // Vérifie si c'est le dernier jour de ce type pour la semaine
                    const isLastDayOfTypeForWeek = day === lastDaysOfTypeEachWeek[weekNumber][dayOfWeek];
              
                    // Filtrer les tâches par type
                    const tachesChantier = tachesDuJour.filter(tache => tache.typeTache === 'Chantier');
                    const tachesObservation = tachesDuJour.filter(tache => tache.typeTache === 'Observation');

                    if (tachesChantier.length > 0) {
                      return tachesChantier.map((tache, indexTache) => {
                        const affectationsPourJour = tache.affectationsDuJour?.find(a => format(parseISO(a.date), 'yyyy-MM-dd') === dateString);
                        const personnesAffectees = affectationsPourJour
                          ? [...affectationsPourJour.chefs, ...affectationsPourJour.ouvriers].map(p => `${p.utilisateur.prenom.charAt(0)}${p.utilisateur.nom.slice(0, 2)}`).join(", ")
                          : "Personne affectée";

                      // Préparer la valeur pour la colonne "Observations" basée sur l'affaireId et le jour
                      let observationsPourJour = tachesObservation
                        .filter(observation => observation.affaireId === tache.affaireId)
                        .map(observation => observation.nomTache)
                        .join(", "); // Cette ligne concatène tous les noms d'observation pour le même chantier le même jour
                        // Déterminer si cette tâche est la dernière tâche du jour
                        const isLastTaskOfDay = indexTache === tachesChantier.length - 1;
                        
                        // Appliquer la classe "dernier-jour" seulement si c'est la dernière tâche du dernier jour de ce type pour la semaine
                        const shouldApplyLastDayClass = isLastDayOfTypeForWeek && isLastTaskOfDay;
                        
                        const classes = `tr-PlanningEntreprise ${dayOfWeek} ${shouldApplyLastDayClass ? "dernier-jour" : ""}`;

                      return (
                      <tr className={`tr-PlanningEntreprise ${classes}`} key={dateString + "-" + indexTache} onClick={() => handleRowClick(tache, dateString)} style={{ cursor: 'pointer' }}>
                        {/* <td>{getWeek(day, { weekStartsOn: 1, locale: fr })}</td>*/}
                        <td>{format(day, 'EEEE', { locale: fr })}</td>
                        <td>{format(day, 'd')}</td>
                        <td>{tache.nomAffaire}</td>
                        <td>{tache.refAffaire}</td>
                        <td>{tache.ville}</td>
                        <td>{tache.nomTache}</td>
                        <td onClick={(event) => handleDirectAffectation(tache, dateString, event)}>{personnesAffectees}</td>
                        <td>{observationsPourJour}</td>
                        <td>{
                        affectationsPourJour && affectationsPourJour.chefs.length > 0
                          ? secondsToHoursMinutes(affectationsPourJour.chefs[0].heures * 3600) // Si heures est déjà en heures, multipliez par 3600 pour convertir en secondes
                          : "00:00"
                      }</td>
                      </tr>
                    );
                  });
                } else {
                  return (
                    <tr className={`tr-PlanningEntreprise pas-de-taches  ${dayOfWeekClass} `} key={"empty-" + dateString}>
                      {/*<td>{getWeek(day, { weekStartsOn: 1, locale: fr })}</td>*/}
                      <td>{format(day, 'EEEE', { locale: fr })}</td>
                      <td>{format(day, 'd')}</td>
                      <td colSpan="7">Pas de tâches planifiées</td>
                    </tr>
                  );
                }
              })}
              </tbody>
            </table>
          </div>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Options pour la tâche :</DialogTitle>
        <DialogContent>
            {selectedTache && (
                <div>
                  <p>
                    {`${selectedTache.tache.nomTache} prévus du 
                    ${format(parseISO(selectedTache.tache.dateDepart), 'dd-MM', { locale: fr })} 
                    au 
                    ${format(parseISO(selectedTache.tache.dateEcheance), 'dd-MM', { locale: fr })}`}
                  </p>
                </div>
            )}
        </DialogContent>
        <DialogActions>
        <Button onClick={() => goToAffaire(selectedTache?.tache?.affaireId)}>Voir l'affaire</Button>
        <Button onClick={() =>handleFormChange('PlanningAffaireNew')}>Afficher le plannind de l'affaire</Button>
        <Button onClick={() => handleFormChange('TacheForm')}>Modifier la tâche</Button>
        <Button onClick={() => handleFormChange('Affectation')}>Affecter une équipe</Button>
        <Button onClick={handleDialogClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

      {formToShow && (
                <Rnd
                size={{ width: initialSize.width, height: initialSize.height }}
                position={position}
                onDragStop={onDragStop}
                dragHandleClassName="form-header"
                style={{ zIndex: 1000 }}
              >
                        <div className="form-container" style={{ width: '100%', height: '100%' }}>
                        <div className="form-header">
                          <span className="form-title">{getTitle()}</span>
                          <button className="close-button" onClick={handleCloseForm}>×</button>
                        </div>
                        <div className="form-body" style={{ width: '100%', height: '100%' }}>
                            {formToShow === 'Affectation' && (
                                <AffectationUtilisateur
                                    affaireId={selectedTache?.tache?.affaireId}
                                    selectedTache={selectedTache?.tache}
                                    date={selectedTache?.dateString}
                                    onAffectationComplete={onAffectationComplete}
                                />
                              )}
                            {formToShow === 'TacheForm' && (
                            <TacheFormPlanning affaireId={selectedTache?.tache?.affaireId} selectedTache={selectedTache?.tache} refreshTaches={onAffectationComplete} onFormSubmit={handleCloseForm}/>
                              )}
                            {formToShow === 'TacheCreationForm' && (
                            <TacheCreationForm onAffectationComplete={onAffectationComplete} handleCloseForm={handleCloseForm} />
                              )}
                            {formToShow === 'PlanningAffaireNew' && (
                            <AffichageTachePlanningNew affaireId={selectedTache?.tache?.affaireId}  handleCloseForm={handleCloseForm} />
                              )}
                    </div>
                </div>
                </Rnd>
            )}
    </div>
  );
}

export default PlanningEntreprise;
