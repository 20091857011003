import './boiteDeMessageEnvoyer.scss'

const BoiteDeMessageEnvoyer = ({ tachesEnvoye }) => {
  console.log(tachesEnvoye)
  return (
    <div>BoiteDeMessageEnvoyer</div>
  )
}

export default BoiteDeMessageEnvoyer
