import React from 'react'
import './btnUrgent.scss'

const BtnUrgent = ({ tache, setTache }) => {
  const handleChange = (e) => {
    setTache({ ...tache, priorite: e.target.checked ? 'Haute' : 'Moyenne' })
  }

  return (
    <div className="BtnUrgent">
      <label>
        <input
          type="checkbox"
          checked={tache.priorite === 'Haute'}
          onChange={handleChange}
        />
        Urgent
      </label>
    </div>
  )
}

export default BtnUrgent
