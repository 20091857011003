import EntrepriseForm from '../../components/entrepriseForm/EntrepriseForm'
import { Snackbar } from '@mui/material'
import { homeMenu } from '../../variables/sidebar/topMenu'
import useFetch from '../../hooks/useFetch'
import axios from 'axios'
import useSnackbar from '../../hooks/useSnackbar'

import './reglages.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'

const Reglages = () => {
  const { utilisateur } = useContext(AuthContext)
  const entrepriseId = utilisateur.entreprise.id
  const {
    data: entreprise,
    loading,
    error,
    reFetch
  } = useFetch(`/entreprises/${entrepriseId}`)
  const { open, message, showSnackbar, hideSnackbar } = useSnackbar()

  const handleFormSubmit = async (updatedEntreprise) => {
    // Logique pour mettre à jour l'utilisateur via une requête PUT
    try {
      await axios.put(`/entreprises/${entrepriseId}`, updatedEntreprise)
      showSnackbar('Modification Informations Générale Réussis')
      await reFetch() // Recharger les données de l'utilisateur après la mise à jour
    } catch (err) {
      console.error(
        'Erreur lors de la mise à jour de Informations Générale ',
        err
      )
      showSnackbar('Erreur lors de la Modification')
    }
  }
  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur : {error.message}</div>

  return (
    <div className="reglagescontainer">
      <div className="container-reglages">
              <div className="sidebar">
          <Sidebar menu={homeMenu} />
        </div>

      <div className="entrepriseformcontainer">
      <h2>Réglages :</h2>
        <EntrepriseForm
          entrepriseData={entreprise}
          onSubmit={handleFormSubmit}
          isEdit={true}
        />
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hideSnackbar}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      </div>
    </div>
  )
}

export default Reglages
