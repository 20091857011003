import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const ModalEnvoyerMessage = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="form-tache-board-modal"
      aria-describedby="form-tache-board-modal-description"
    >
      <Box sx={style}>
      {children}
      </Box>
    </Modal>
  )
}

export default ModalEnvoyerMessage
