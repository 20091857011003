import { useState, useRef, useEffect } from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import LigneMois from '../planning/LigneMois'
import LigneJour from '../planning/LigneJour'
import LigneTacheAffaire from './LigneTacheAffaire'
import LigneNomAffaire from './LigneNomAffaire'
import './planningAffaire.scss'

const PlanningAffaire = ({ toutesLesAffaire, onAffaireSelect }) => {
  const dayWidth = 30
  const [typeAffaireFiltre, setTypeAffaireFiltre] = useState(['en préparation', 'en cours'])
  const contentScrollRef = useRef(null)
  const aScrollRef = useRef(null)

  // Gestionnaire de changement pour le Select
  const handleChange = (event) => {
    const { value } = event.target
    // Si "tous" est sélectionné, réinitialiser le filtre pour afficher toutes les affaires
    if (value.includes('tous')) {
      setTypeAffaireFiltre(['tous'])
    } else {
      // Sinon, appliquer le filtre basé sur les valeurs sélectionnées
      setTypeAffaireFiltre(typeof value === 'string' ? value.split(',') : value)
    }
  }

  // Filtrer les affaires en fonction des statuts sélectionnés ou montrer toutes les affaires si "tous" est sélectionné
  const affairesFiltrees = typeAffaireFiltre.includes('tous')
    ? toutesLesAffaire
    : toutesLesAffaire.filter(affaire =>
      typeAffaireFiltre.includes(affaire.statut)
    )

  const affairesAvecDates = affairesFiltrees.map(affaire => {
    let affaireStartDate, affaireEndDate
    switch (affaire.etatDate) {
      case 'date non modifier':
        affaireStartDate = new Date(affaire.dateDebutReelle)
        affaireEndDate = new Date(affaire.dateFinReelle)
        break
      case 'date estimatif':
        affaireStartDate = new Date(affaire.dateEstimatifDepart)
        affaireEndDate = new Date(affaire.dateEstimatifFin)
        break
      case 'a valider':
        affaireStartDate = new Date(affaire.modificationAValiderDepart)
        affaireEndDate = new Date(affaire.modificationAValiderFin)
        break
      default:
        affaireStartDate = null
        affaireEndDate = null
    }
    return { ...affaire, affaireStartDate, affaireEndDate }
  })

  // Calculer la plage de dates pour toutes les tâches
  // const getRangeOfDates = (affaires) => {
  //   const datesDebut = affaires
  //     .filter(a => a.affaireStartDate)
  //     .map(a => new Date(a.affaireStartDate).getTime())
  //   const datesFin = affaires
  //     .filter(a => a.affaierEndDate)
  //     .map(a => new Date(a.affaierEndDate).getTime())

  //   if (datesDebut.length === 0 || datesFin.length === 0) {
  //     return { startDate: null, endDate: null }
  //   }

  //   const startDate = new Date(Math.min(...datesDebut))
  //   const endDate = new Date(Math.max(...datesFin))

  //   return { startDate, endDate }
  // }

  // Déterminer les mois à afficher dans le planning
  const getMonthsToDisplay = (affairesAvecDates) => {
    const monthNames = {
      janvier: 0,
      février: 1,
      mars: 2,
      avril: 3,
      mai: 4,
      juin: 5,
      juillet: 6,
      août: 7,
      septembre: 8,
      octobre: 9,
      novembre: 10,
      décembre: 11
    }
    const months = new Set() // Ensemble pour stocker les mois et années uniques
    let latestEndDate = new Date(0) // Date initiale pour trouver la date de fin la plus éloignée

    affairesAvecDates.forEach((affaire) => {
      const start = affaire.affaireStartDate ? new Date(affaire.affaireStartDate) : new Date()
      const end = affaire.affaireEndDate ? new Date(affaire.affaireEndDate) : new Date()

      if (end > latestEndDate) {
        latestEndDate = end
      }

      // eslint-disable-next-line no-unmodified-loop-condition
      while (start <= end) {
        months.add(start.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }))
        start.setMonth(start.getMonth() + 1)
      }
    })

    // Ajouter le mois et l'année de la date de fin la plus éloignée
    months.add(latestEndDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }))

    // Convertir l'ensemble des mois en tableau, trier les dates et les renvoyer
    return Array.from(months).sort((a, b) => {
      const [monthA, yearA] = a.split(' ')
      const [monthB, yearB] = b.split(' ')
      const dateA = new Date(yearA, monthNames[monthA])
      const dateB = new Date(yearB, monthNames[monthB])
      return dateA - dateB
    })
  }

  // Obtenir la plage de dates et les mois à afficher
  // const { startDate, endDate } = getRangeOfDates(affairesAvecDates)
  const monthsToDisplay = getMonthsToDisplay(affairesAvecDates)

  // Trier les affaires qui ont une 'affaireStartDate' valide
  const affairesAvecDateDebut = affairesAvecDates
    .filter(a => a.affaireStartDate && !isNaN(new Date(a.affaireStartDate).getTime()))
    .sort((a, b) => new Date(a.affaireStartDate) - new Date(b.affaireStartDate))

  // Trier les affaires qui n'ont pas de 'affaireStartDate' ou avec une date invalide par 'createdAt'
  const affairesSansDateDebut = affairesAvecDates
    .filter(a => !a.affaireStartDate || isNaN(new Date(a.affaireStartDate).getTime()))
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

  // Combiner les deux listes triées
  const affairesAvecDatestrie = [...affairesAvecDateDebut, ...affairesSansDateDebut]

  // Logique pour le scroll horizontal

  const syncScroll = (sourceRef, targetRef) => () => {
    const sourceElement = sourceRef.current
    const targetElement = targetRef.current

    if (sourceElement && targetElement) {
      targetElement.scrollTop = sourceElement.scrollTop
    }
  }

  useEffect(() => {
    const contentScroll = contentScrollRef.current
    const aScroll = aScrollRef.current

    if (contentScroll && aScroll) {
      contentScroll.addEventListener('scroll', syncScroll(contentScrollRef, aScrollRef))
      aScroll.addEventListener('scroll', syncScroll(aScrollRef, contentScrollRef))
    }

    return () => {
      if (contentScroll && aScroll) {
        contentScroll.removeEventListener('scroll', syncScroll(contentScrollRef, aScrollRef))
        aScroll.removeEventListener('scroll', syncScroll(aScrollRef, contentScrollRef))
      }
    }
  }, [])

  console.log('9', affairesAvecDates)
  console.log('10', affairesAvecDatestrie)

  return (
        <div className="planningContainer">
          <div className="leftplanningContainer">
          <FormControl sx={{ width: 250, height: 50, '.MuiOutlinedInput-input': { height: 'auto' }, '.MuiSelect-select': { display: 'flex', alignItems: 'center', padding: '0 32px 0 14px' } }}>
          <InputLabel id="demo-multiple-chip-label">Statut</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={typeAffaireFiltre}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              height: '50px',
              '& .MuiSvgIcon-root': {
                height: '100%',
                maxHeight: '50px',
                top: 'calc(50% - 14px)'
              }
            }}
          >
            <MenuItem value="tous">Tous</MenuItem>
          <MenuItem value="création">Création</MenuItem>
          <MenuItem value="en préparation">En préparation</MenuItem>
          <MenuItem value="en cours">En cours</MenuItem>
          <MenuItem value="terminé">Terminé</MenuItem>
          <MenuItem value="archivé">Archivé</MenuItem>
          <MenuItem value="a entrer au planning">A entrer au planning</MenuItem>
          <MenuItem value="en attente">En attente</MenuItem>
          <MenuItem value="en pause">En pause</MenuItem>
        </Select>
        </FormControl>
            <div className="a" ref={aScrollRef}>
              {affairesAvecDatestrie.map((affaire) => (
                <LigneNomAffaire key={affaire._id} affaire={affaire} onAffaireSelect={onAffaireSelect}/>
              ))}
            </div>
          </div>

          <div className="headerScroll">
            <div className="headerheaderScroll">
              {/* <LigneAnnee annee={startDate.getFullYear()} /> */}
              <LigneMois months={monthsToDisplay} dayWidth={dayWidth} />
              <LigneJour months={monthsToDisplay} dayWidth={dayWidth} />
            </div>
            <div className="contentheaderScroll" ref={contentScrollRef}>
              {affairesAvecDatestrie.map((affaire) => (
                <LigneTacheAffaire
                  key={affaire._id}
                  affaire={affaire}
                  startDate={affaire.affaireStartDate}
                  endDate={affaire.affaireEndDate}
                  dayWidth={dayWidth}
                  months={monthsToDisplay}
                  onAffaireSelect={onAffaireSelect}
                />
              ))}
            </div>
          </div>
        </div>
  )
}

export default PlanningAffaire
