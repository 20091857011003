// partie 1
import BusinessIcon from '@mui/icons-material/Business'

// partie 2
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

// partie 3
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

// partie 4
import PermIdentityIcon from '@mui/icons-material/PermIdentity'

// partie 5
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

// partie 1
export const partInitialPart1 = {
  id: '1',
  img: <BusinessIcon className="img"/>,
  title: 'Générales',
  isLeft: false,
  isRight: false,
  titlePartInitial11: 'Client:',
  info1: 'client',
  titlePartInitial2: 'Affaire:',
  info2: 'nomAffaire',
  titlePartInitial3: 'Référence:',
  info3: 'refAffaire',
  titlePartInitial4: 'État:',
  info4: 'statut'
}

// Partie 2 - Notifications
export const partInitialPart2 = {
  id: '2',
  img: <NotificationsNoneIcon className="img"/>,
  title: 'Notifications',
  isLeft: false,
  isRight: false,
  titlePartInitial11: 'Non Lues:',
  info1: '0', // Supposons que 'unreadNotifications' est le nombre de notifications non lues.
  titlePartInitial2: 'Alertes:',
  info2: '0', // Supposons que 'alertCount' est le nombre d'alertes critiques.
  titlePartInitial3: 'Messages:',
  info3: 'O', // Supposons que 'messageCount' est le nombre de nouveaux messages.
  titlePartInitial4: 'Tâches:',
  info4: 'tasksDue' // Supposons que 'tasksDue' est le nombre de tâches dues aujourd'hui.
}

// Partie 3 - Planification et Dates
export const partInitialPart3 = {
  id: '3',
  img: <CalendarMonthIcon className="img"/>,
  title: 'Planification',
  isLeft: false,
  isRight: false,
  titlePartInitial11: 'Prochain Jalon:',
  info1: 'Aucune Tache Planifiée',
  titlePartInitial2: 'Échéance:',
  info2: '',
  titlePartInitial3: 'Réalisé:',
  info3: 'upcomingEvents',
  titlePartInitial4: 'Rendez-vous:',
  info4: 'appointments'
}

// Partie 4 - Équipe Affectée
export const partInitialPart4 = {
  id: '4',
  img: <PermIdentityIcon className="img"/>,
  title: 'Équipe',
  isLeft: false,
  isRight: false,
  titlePartInitial11: 'Chef de Projet:',
  info1: 'Aucun Chef ',
  titlePartInitial2: 'Effectif:',
  info2: 'O',
  titlePartInitial3: 'Suivis par:',
  info3: 'Aucun conducteur',
  titlePartInitial4: 'Disponibilité:',
  info4: 'availability'
}

// Partie 5 - Avancement
export const partInitialPart5 = {
  id: '5',
  img: <TrendingUpIcon className="img"/>,
  title: 'Avancement',
  isLeft: false,
  isRight: false,
  titlePartInitial11: 'Progrès:',
  info1: ' ', // Supposons que 'progressPercentage' est le pourcentage de progrès du projet.
  titlePartInitial2: 'Premier Jour Reel:',
  info2: 'Non commencé', // Supposons que 'performanceIndex' est un indice de performance du projet.
  titlePartInitial3: 'Premier Jour Prévus:',
  info3: 'riskCount',
  titlePartInitial4: 'Nbr de Taches en retard:',
  info4: 'O'
}
