import { useFirebase } from '../../../context/Firebase'
import { fileToKey } from '../../../utils/storage'
import './userForm.scss'

import { useState } from 'react'

const UserForm = ({ userData, onSubmit, isEdit }) => {
  const { storage } = useFirebase()
  const [user, setUser] = useState({
    nom: userData?.nom || '',
    prenom: userData?.prenom || '',
    email: userData?.email || '',
    password: userData?.password || '',
    role: userData?.role || '',
    admin: userData?.admin || false,
    // client: userData?.client || false,
    maitreOeuvre: userData?.maitreOeuvre || false,
    responsable: userData?.responsable || false,
    chefAssignes: userData?.chefAssignes || false,
    bureauAssignes: userData?.bureauAssignes || false,
    conducAssignes: userData?.conducAssignes || false,
    chiffrageAssignes: userData?.chiffrageAssignes || false,
    ouvrierTerrain: userData?.ouvrierTerrain || false,
    adresse: userData?.adresse || '',
    codePostal: userData?.codePostal || '',
    ville: userData?.ville || '',
    telephone: userData?.telephone || '',
    avatar: userData?.avatar || ''
  })

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target

    setUser({
      ...user,
      [name]: type === 'checkbox'
        ? checked
        : type === 'file'
          ? e.target.files
          : value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const avatarUrl = await fileToKey(user.avatar, storage, { path: 'seb' })
    onSubmit({
      ...user,
      avatar: avatarUrl
    })
  }

  return (
    <div className="userForm">
    <form onSubmit={handleSubmit}>

      <div className="info-deneral-utilisateur-form">
        <label >Nom:</label>
        <input type="text" name="nom" placeholder="Nom" value={user.nom} onChange={handleChange} />
        <label >Prenom:</label>
        <input type="text" name="prenom" placeholder="Prénom" value={user.prenom} onChange={handleChange} />
        <label >Email:</label>
        <input type="email" name="email" placeholder="Email" value={user.email} onChange={handleChange} />
        <label >Mot de Passe:</label>
        <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={user.password}
            onChange={handleChange}
            disabled={isEdit} // Désactiver le champ en mode édition
          />
      </div>
      <div className="role-utilisateur-form">
        <label htmlFor="role">Role:</label>
        <input type="checkbox" name="admin" checked={user.admin} onChange={handleChange} />
        <label htmlFor="admin">Admin</label>

        <input type="checkbox" name="responsable" checked={user.responsable} onChange={handleChange} />
        <label htmlFor="responsable">Résponsable</label>

        <input type="checkbox" name="maitreOeuvre" checked={user.maitreOeuvre} onChange={handleChange} />
        <label htmlFor="maitreOeuvre">{"Maître d'Oeuvre"}</label>

        <input type="checkbox" name="chiffrageAssignes" checked={user.chiffrageAssignes} onChange={handleChange} />
        <label htmlFor="chiffrageAssignes">Chiffreur(euse)</label>

        <input type="checkbox" name="conducAssignes" checked={user.conducAssignes} onChange={handleChange} />
        <label htmlFor="conducAssignes">Conducteur(ice) de Travaux</label>

        <input type="checkbox" name="bureauAssignes" checked={user.bureauAssignes} onChange={handleChange} />
        <label htmlFor="bureauAssignes">Administratif</label>

        <input type="checkbox" name="chefAssignes" checked={user.chefAssignes} onChange={handleChange} />
        <label htmlFor="chefAssignes">{"Chef d'equipe"}</label>

        <input type="checkbox" name="ouvrierTerrain" checked={user.ouvrierTerrain} onChange={handleChange} />
        <label htmlFor="ouvrierTerrain">Ouvrier Terrain</label>
      </div>
      <div className="adress-utilisateur-form">
        <label>Adresse:</label>
        <input type="text" name="adresse" placeholder="Adresse" value={user.adresse} onChange={handleChange} />
        <input type="text" name="codePostal" placeholder="Code Postal" value={user.codePostal} onChange={handleChange} />
        <input type="text" name="ville" placeholder="Ville" value={user.ville} onChange={handleChange} />
        </div>
        <label>Telephone:</label>
        <input type="tel" name="telephone" placeholder="Téléphone" value={user.telephone} onChange={handleChange} />
        {/* <input type="text" name="avatar" placeholder="Image" value={user.avatar} onChange={handleChange} /> */}
        <label>Avatar:</label>
        <input type="file" name="avatar" placeholder="Image" accept='image/*' onChange={handleChange} />

        <button type="submit">{isEdit ? 'Valider les modifications' : "Valider l'utilisateur"}</button>
    </form>
    </div>
  )
}

export default UserForm
