import Sidebar from "../../components/sidebar/Sidebar";
import SelectAndGoAffaire from "../../components/widget/SelectAndGoAffaire/SelectAndGoAffaire";
import AffichageWidgetRecap from "../../components/widgetRecap/AffichageWidgetRecap/AffichageWidgetRecap";
import { homeMenu } from "../../variables/sidebar/topMenu";
import "./homeNew.scss";

const HomeNew = () => {
  return (
    <div className="HomeNew">
        <div className="contain-HomeNew">
            <div className="sidebar">
                <Sidebar menu={homeMenu} />
            </div>
            <div className="widget-contain-HomeNew">
                <div className="selectandgo-home">
                <SelectAndGoAffaire />
                </div>
                <div className="affichage-recap-homeNew">
                    <AffichageWidgetRecap /> 
                </div>
            </div>
      </div>
    </div>
  );
};

export default HomeNew;
