import axios from 'axios'
import { useContext, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
// import { AuthContext } from "../../context/AuthContext";
import './login.scss'

import Logo from '../../assets/images/logo.jpg'

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined
  })

  const { updateUser } = useContext(AuthContext)

  const navigate = useNavigate()

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }

  const handleClick = async (e) => {
    setIsLoading(true)
    try {
      const res = await axios.post('/auth/login', credentials)
      const user = res.data.user

      if (user.role === 'SUPERADMIN' || (user.admin && (user.responsable || /*user.chefAssignes ||*/ user.bureauAssignes || user.conducAssignes))) {
        updateUser(res.data.user, res.data.jwt)
        navigate('/')
      } else {
        alert('Accès refusé : rôle non autorisé.')
      }
    } catch (err) {
      console.log(err)
      alert('Mot de passe ou email incorrect')
    }
    setIsLoading(false)
  }

  return (
    <div className="login">
      <div className="lContainer">
        <img src={Logo} alt="logo" />
        <input
          type="email"
          placeholder="email"
          id="email"
          onChange={handleChange}
          className="lInput"
        />
        <input
          type="password"
          placeholder="password"
          id="password"
          onChange={handleChange}
          className="lInput"
        />
        <button disabled={isLoading} onClick={handleClick} className="lButton">
          Login
        </button>
        <NavLink to="/forgot">
          Mot de passe oublié ?
        </NavLink>
      </div>
    </div>
  )
}

export default Login
