import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TacheFormPlanning from '../../tachePlanning/tacheFormPlanning/TacheFormPlanning';
import './tacheCreationForm.scss'; 

const TacheCreationForm = ({ onAffectationComplete, handleCloseForm }) => {
  const [selectedAffaireId, setSelectedAffaireId] = useState('');
  const [affaires, setAffaires] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchAffaires = async () => {
      try {
        const response = await axios.get('/affaires');
        const affairesFiltrees = response.data.filter(affaire => 
          ['création', 'en préparation', 'en cours', 'terminé', 'archivé', 'a entrer au planning', 'en attente', 'en pause'].includes(affaire.statut)
        );
        setAffaires(affairesFiltrees);
      } catch (error) {
        console.error('Erreur lors du chargement des affaires', error);
      }
    };

    fetchAffaires();
  }, []);

  const handleAffaireChange = (e) => {
    setSelectedAffaireId(e.target.value);
    setShowForm(true); // Affiche le formulaire lorsqu'une affaire est sélectionnée
  };

  return (
    <div className='TacheCreationForm'>
        <div className="top-TacheCreationForm">
            <h2>Création d'une nouvelle tâche</h2>
            <select onChange={handleAffaireChange} value={selectedAffaireId} className="select-affaire">
                <option value="">Sélectionner une affaire</option>
                {affaires.map((affaire) => (
                    <option key={affaire._id} value={affaire._id}>
                        {affaire.nomAffaire}
                    </option>
                ))}
            </select>
        </div>
        {showForm && selectedAffaireId && (
            <div className="formContainer-TacheCreationForm">
                <TacheFormPlanning
                    affaireId={selectedAffaireId}
                    refreshTaches={() => {}} // Fonction vide pour éviter les erreurs
                    onFormSubmit={() => {
                        handleCloseForm();
                        onAffectationComplete();
                        setShowForm(false); // Cache le formulaire après la soumission
                    }}
                />
            </div>
        )}
    </div>
);
}
export default TacheCreationForm;