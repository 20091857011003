import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import './card.scss'

const Card = ({ id, title, subtitle, image, description, onClick, onDetailClick, isAffairePage, additionalButtons = [] }) => {
  const cardClass = `card ${isAffairePage ? 'affaire-page' : ''}`
  // Gestionnaire pour cliquer sur la carte
  const handleCardClick = () => {
    if (onDetailClick) {
      onDetailClick(id)
    } else if (onClick) {
      onClick(id)
    }
  }

  return (
    <Tooltip title={description} arrow placement="right-start" followCursor>
      <div className={cardClass} onClick={handleCardClick}>
        <div className="containercard">
          <div className="imgcard">
            {typeof image === 'string'
              ? (
              <img src={`${process.env.PUBLIC_URL}/${image}`} alt={title} />
                )
              : (
                  React.cloneElement(image, { className: 'iconimgcard' })
                )}
          </div>
          <div className="info1">
            <h3>{title}</h3>
          </div>
          <div className="info2">
            <span>{subtitle}</span>
          </div>
          <div className="btn-valid-card">
          <button onClick={() => onClick(id)}>Accéder</button>
          {additionalButtons.map((button, index) => (
            <button key={index} onClick={() => button.onClick(id)}>
              {button.title}
            </button>

          ))}
              </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default Card
