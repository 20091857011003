import {useState} from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Divider } from '@mui/material'
import axios from 'axios'

export default function CreateEntreprise({ mcallback }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [isLoading, setIsLoading] = useState(false)

  return <>
    <Button variant="contained" onClick={handleClickOpen}>
      Nouvelle entreprise
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='sm'
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault()
          const data = Object.fromEntries((new FormData(event.currentTarget)).entries())
          setIsLoading(true)
          axios.post('/entreprises/withAdmin', data).then((res) => {
            mcallback(res.data)
            handleClose()
          }).catch(err => {
            console.log(err)
            alert('An error occured please try again')
          }).finally(() => {
            setIsLoading(false)
          })
        }
      }}
    >
      <DialogTitle>Nouvelle entreprise</DialogTitle>
      <DialogContent>
        <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}>Entreprise</div>
        <TextField autoFocus required id="company.name" name="company.name" label="Nom" fullWidth variant="outlined" margin="dense" />
        <TextField required type="email" id="company.email" name="company.email" label="Email" fullWidth variant="outlined" margin="dense" />
        <Divider style={{margin: '20px 0px'}} />
        <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}>Admin de l'entreprise</div>
        <TextField required type="email" id="admin.email" name="admin.email" label="Email" fullWidth variant="outlined" margin="dense" />
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
          <TextField required id="admin.firstName" name="admin.firstName" label="Prénom" fullWidth variant="outlined" margin="dense" />
          <TextField required id="admin.lastName" name="admin.lastName" label="Nom" fullWidth variant="outlined" margin="dense" />
        </div>
        <TextField inputProps={{ minLength: 6 }} required type="password" id="admin.password" name="admin.password" label="Mot de passe" fullWidth variant="outlined" margin="dense" />
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', margin: '0 12px 12px' }}>
        <Button disabled={isLoading} onClick={handleClose} color='error'>Annuler</Button>
        <Button disabled={isLoading} type="submit" color='primary' variant='outlined'>Créer</Button>
      </DialogActions>
    </Dialog>
  </>
}