import './boiteLectureMessage.scss'

const BoiteLectureMessage = ({ commentaire, reFetch, onReply }) => {
  console.log('223', commentaire)
  // Utilisez les propriétés du commentaire
  const { utilisateurId, createdAt, objetComment, commentaire: contenuCommentaire } = commentaire
  const formattedDate = new Date(createdAt).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' })
  const emetteur = utilisateurId ? `${utilisateurId.nom} ${utilisateurId.prenom} <${utilisateurId.email}>` : 'Chargement...'

  return (
        <div className="boiteLectureMessage">
            <div className="barreOptions">
                <button className="boutonRetour" onClick={reFetch}>Retour</button>
                <button className="boutonRepondre" onClick={onReply}>Répondre</button>
            </div>
            <div className="contenuMessage">
                <div className="enTeteMessage">
                    <div className="emetteur">
                        De: {emetteur}
                    </div>
                    <div className="dateEnvoi">{formattedDate}</div>
                </div>
                <div className="titreMessage">{objetComment}</div>
                <div className="corpsMessage">{contenuCommentaire}</div>
            </div>
        </div>
  )
}

export default BoiteLectureMessage
