import { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'
import './assignationTacheId.scss'

const AssignationTacheId = ({ tache }) => {
  const [users, setUsers] = useState([])
  const [selectedTeam, setSelectedTeam] = useState([])

  useEffect(() => {
    if (!tache) {
      return // Sortir de useEffect si tache est null
    }

    // Récupérer la liste des utilisateurs
    axios.get('/utilisateurs').then((response) => {
      setUsers(response.data)

      // Convertir les ID des utilisateurs assignés en objets pour le Select
      const initialTeam = tache.usersAssignes
        .map((id) => response.data.find((user) => user._id === id))
        .filter((user) => user) // Filtrer les utilisateurs non trouvés
        .map((user) => ({
          value: user._id,
          label: `${user.nom} ${user.prenom}`
        }))

      setSelectedTeam(initialTeam)
    })
  }, [tache])

  if (!tache) {
    return <div>Aucune tâche sélectionnée</div>
  }

  const handleTeamChange = (selectedOptions) => {
    setSelectedTeam(selectedOptions)
  }

  const assignTeam = async () => {
    try {
      const usersAssignesIds = selectedTeam.map((user) => user.value)
      await axios.put(`/taches/${tache._id}`, { usersAssignes: usersAssignesIds })
      alert('Affectation réussie')
    } catch (error) {
      console.error("Erreur lors de l'affectation de l'équipe", error)
    }
  }

  return (
    <div className="assignmentContainer">
      <div className="selectGroup">
        <h3>Assigner les utilisateurs à la tâche : {tache.nomTache}</h3>
        <Select
          options={users.map((user) => ({
            value: user._id,
            label: `${user.nom} ${user.prenom}`
          }))}
          onChange={handleTeamChange}
          value={selectedTeam}
          placeholder="Sélectionner les utilisateurs"
          isMulti
          closeMenuOnSelect={false}
        />
      </div>
      <button onClick={assignTeam}>{"Assigner l'équipe à la tâche"}</button>
    </div>
  )
}

export default AssignationTacheId
