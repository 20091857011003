import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import BusinessIcon from '@mui/icons-material/Business'
import HandymanIcon from '@mui/icons-material/Handyman'
import ListAltIcon from '@mui/icons-material/ListAlt'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
// affaire
// retour
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import DomainAddIcon from '@mui/icons-material/DomainAdd'
import BuildIcon from '@mui/icons-material/Build'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'

// afaire Id
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'

// taches
// add
import AddIcon from '@mui/icons-material/Add'
// modifier
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal'

// rapports
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import QueryStatsIcon from '@mui/icons-material/QueryStats'

// users
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import GroupIcon from '@mui/icons-material/Group'

// notications
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'

// chat
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import AddCommentIcon from '@mui/icons-material/AddComment'
import ClockIcon from '@mui/icons-material/Schedule'

// menu home page
export const homeMenu = [
  {
    id: '1',
    img: <PermIdentityIcon />,
    name: 'Users',
    link: '/users'
  },
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Affaires',
    link: '/affaires'
  },
  {
    id: '3',
    img: <HandymanIcon />,
    name: 'Tâches',
    link: '/Taches'
  },

  {
    id: '7',
    img: <ClockIcon />,
    name: "Feuilles d'heures",
    link: '/timesheet'
  },
  {
    id: '5',
    img: <NotificationsNoneIcon />,
    name: 'Notifications',
    link: '/notifications'
  },
  {
    id: '6',
    img: <ChatBubbleOutlineIcon />,
    name: 'Communication',
    link: '/messagerie'
  }
]

// menu Affaires page
export const affairesMenu = (openModal, filterByStatus, clearFilter) => [
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Affaires',
    link: '',
    action: clearFilter
  },
  {
    id: '3',
    img: <DomainAddIcon />,
    name: 'Creer Affaire',
    link: '',
    action: openModal
  },
  {
    id: '4',
    img: <BuildIcon />,
    name: 'En Préparation',
    link: '',
    action: () => filterByStatus('en préparation')
  },
  {
    id: '5',
    img: <PublishedWithChangesIcon />,
    name: 'En Cours',
    link: '',
    action: () => filterByStatus('en cours')
  },
  {
    id: '6',
    img: <FileDownloadDoneIcon />,
    name: 'Terminées',
    link: '',
    action: () => filterByStatus('terminé')
  }
]

// menu AffaireId page
export const affaireIdMenu = (affairId, handleGoBack) => [
  {
    id: '1',
    img: <ArrowBackIcon />,
    name: 'Retour',
    link: '',
    action: handleGoBack
  },
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Détails',
    link: `/affaires/${affairId}`,
    isActive: true
  },
  {
    id: '3',
    img: <CalendarMonthIcon />,
    name: 'Planning',
    link: `/affaires/planning/${affairId}`
  },
  {
    id: '4',
    img: <TrendingUpIcon />,
    name: 'Visite Chantier',
    link: ''
  },
  {
    id: '5',
    img: <HandymanIcon />,
    name: 'Tâches et Assignations ',
    link: `/affaires/preparation/${affairId}`
  },
  {
    id: '6',
    img: <AutoStoriesIcon />,
    name: "Livre d'Or",
    link: ''
  },
  {
    id: '7',
    img: <ListAltIcon />,
    name: 'Rapports',
    link: ''
  },
  {
    id: '8',
    img: <NotificationsNoneIcon />,
    name: 'Notifications',
    link: ''
  }
]

// menu AffairePlanningId page
export const affairePlanningIdMenu = (affairId, handleGoBack) => [
  {
    id: '1',
    img: <ArrowBackIcon />,
    name: 'Retour',
    link: '',
    action: handleGoBack
  },
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Détails',
    link: `/affaires/${affairId}`
  },
  {
    id: '3',
    img: <CalendarMonthIcon />,
    name: 'Planning',
    link: `/affaires/planning/${affairId}`,
    isActive: true
  },
  {
    id: '4',
    img: <TrendingUpIcon />,
    name: 'Visite Chantier',
    link: ''
  },
  {
    id: '5',
    img: <HandymanIcon />,
    name: 'Tâches et Assignations ',
    link: `/affaires/preparation/${affairId}`
  },
  {
    id: '6',
    img: <AutoStoriesIcon />,
    name: "Livre d'Or",
    link: ''
  },
  {
    id: '7',
    img: <ListAltIcon />,
    name: 'Rapports',
    link: ''
  },
  {
    id: '8',
    img: <NotificationsNoneIcon />,
    name: 'Notifications',
    link: ''
  }
]

// menu taches page
export const tachesMenu = [
  {
    id: '1',
    img: <HandymanIcon />,
    name: 'Accueil',
    link: '/'
  },
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Par Affaire',
    link: ''
  },
  {
    id: '3',
    img: <PermIdentityIcon />,
    name: 'Par Responsable',
    link: '/'
  },
  {
    id: '4',
    img: <AddIcon />,
    name: 'Ajouter une Tâche',
    link: ''
  },
  {
    id: '5',
    img: <AutoFixNormalIcon />,
    name: 'Modifier / Supprimer',
    link: ''
  }
]

// menu rapportsfdjMenu page
export const rapportsfdjMenu = [
  {
    id: '1',
    img: <ListAltIcon />,
    name: 'Tous les Rapports',
    link: '/'
  },
  {
    id: '2',
    img: <PlaylistAddIcon />,
    name: 'Créer un rapport',
    link: ''
  },
  {
    id: '3',
    img: <ListAltIcon />,
    name: 'Rapports du Jour',
    link: ''
  },
  {
    id: '4',
    img: <PlaylistAddCheckIcon />,
    name: 'Archive',
    link: '/'
  },
  {
    id: '5',
    img: <QueryStatsIcon />,
    name: 'Statistiques',
    link: '/'
  }
]

// menu users page
export const usersMenu = (openModal, clearFilter) => [
  {
    id: '1',
    img: <PermIdentityIcon />,
    name: 'Collaborateurs',
    link: '',
    action: clearFilter
  },
  {
    id: '2',
    img: <PersonAddAltIcon />,
    name: 'Créer un Utilisateur',
    link: '',
    action: openModal
  },
  {
    id: '3',
    img: <AdminPanelSettingsIcon />,
    name: 'Rôles & Permissions',
    link: ''
  },
  {
    id: '4',
    img: <RecordVoiceOverIcon />,
    name: 'Historique des Connexions',
    link: ''
  },
  {
    id: '5',
    img: <GroupIcon />,
    name: 'Vue générale',
    link: ''
  }
]

// menu UserId page
export const calandarMenu = [
  {
    id: '1',
    img: <ArrowBackIcon />,
    name: 'Retour',
    link: '/'
  },
  {
    id: '2',
    img: <PermIdentityIcon />,
    name: 'Test 1',
    link: ''
  },
  {
    id: '3',
    img: <PermIdentityIcon />,
    name: 'Test 2',
    link: ''
  }
]

// menu UserId page
export const userIdMenu = (userId) => [
  {
    id: '1',
    img: <ArrowBackIcon />,
    name: 'Retour',
    link: '/users'
  },
  {
    id: '2',
    img: <PermIdentityIcon />,
    name: 'Accueil',
    link: '/'
  },
  {
    id: '3',
    img: <PermIdentityIcon />,
    name: 'Accueil',
    link: '/'
  }
]

// menu RolesPermissions page
export const RolesPermissionsMenu = (userId) => [
  {
    id: '1',
    img: <ArrowBackIcon />,
    name: 'Retour',
    link: '/users'
  },
  {
    id: '2',
    img: <PermIdentityIcon />,
    name: 'ProjetX',
    link: '/'
  },
  {
    id: '3',
    img: <PermIdentityIcon />,
    name: 'ProjetX',
    link: '/'
  }
]

// menu notifications page
export const notificationsMenu = [
  {
    id: '1',
    img: <NotificationsNoneIcon />,
    name: 'Accueil',
    link: '/'
  },
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Par Affaire',
    link: ''
  },
  {
    id: '3',
    img: <PermIdentityIcon />,
    name: 'Par Responsable',
    link: '/'
  },
  {
    id: '4',
    img: <EditNotificationsIcon />,
    name: 'Ajouter une Notication',
    link: ''
  },
  {
    id: '5',
    img: <AutoFixNormalIcon />,
    name: 'Modifier / Supprimer',
    link: ''
  }
]

// menu chat page
export const chatMenu = [
  {
    id: '1',
    img: <ChatBubbleOutlineIcon />,
    name: 'Messagerie',
    link: '/chat'
  },
  {
    id: '2',
    img: <QuestionAnswerIcon />,
    name: 'Chats de Groupe',
    link: ''
  },
  {
    id: '3',
    img: <AddCommentIcon />,
    name: 'Créer un Nouveau Chat',
    link: ''
  },
  {
    id: '4',
    img: <PermIdentityIcon />,
    name: 'Répertoire des Contacts',
    link: ''
  }
]

export const messagerieMenu = [
  {
    id: '1',
    img: <ChatBubbleOutlineIcon />,
    name: 'Messagerie',
    link: '/messagerie'
  },
  {
    id: '2',
    img: <QuestionAnswerIcon />,
    name: 'Chats de Groupe',
    link: ''
  },
  {
    id: '3',
    img: <AddCommentIcon />,
    name: 'Créer un Nouveau Chat',
    link: ''
  },
  {
    id: '4',
    img: <PermIdentityIcon />,
    name: 'Répertoire des Contacts',
    link: ''
  }
]

// menu Detail Affaire Id page
export const affaireDetailMenu = (affairId) => [
  {
    id: '1',
    img: <ArrowBackIcon />,
    name: 'Retour',
    link: `/affaires/${affairId}`
  },
  {
    id: '2',
    img: <BusinessIcon />,
    name: 'Détails',
    link: `/affaires/${affairId}`,
    isActive: true
  },
  {
    id: '3',
    img: <CalendarMonthIcon />,
    name: 'Planning',
    link: `/affaires/planning/${affairId}`

  },
  {
    id: '4',
    img: <TrendingUpIcon />,
    name: 'Visite Chantier',
    link: ''
  },
  {
    id: '5',
    img: <HandymanIcon />,
    name: 'Tâches et Assignations ',
    link: `/affaires/preparation/${affairId}`
  },
  {
    id: '6',
    img: <AutoStoriesIcon />,
    name: "Livre d'Or",
    link: ''
  },
  {
    id: '7',
    img: <ListAltIcon />,
    name: 'Rapports',
    link: ''
  },
  {
    id: '8',
    img: <NotificationsNoneIcon />,
    name: 'Notifications',
    link: ''
  }
]

// menu planning page
export const planningMenu = [
  {
    id: '1',
    img: <PermIdentityIcon />,
    name: 'Accueil',
    link: '/'
  },
  {
    id: '2',
    img: <PermIdentityIcon />,
    name: 'Test 1',
    link: ''
  }
]

// menu preparationId page
export const preparationIdMenu = [
  {
    id: '1',
    img: <PermIdentityIcon />,
    name: 'ProjetX',
    link: '/'
  },
  {
    id: '2',
    img: <PermIdentityIcon />,
    name: 'ProjetX',
    link: ''
  }
]
