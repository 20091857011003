import { useState } from 'react'

import './affaireForm.scss'
import { fileToKey } from '../../utils/storage'
import { useFirebase } from '../../context/Firebase'

const AffaireForm = ({ affaireData, onSubmit, isEdit }) => {
  const { storage } = useFirebase()
  const [affaire, setAffaire] = useState({
    entrepriseUtilisatrice: affaireData?.entrepriseUtilisatrice || '',
    client: affaireData?.client || '',
    zone: affaireData?.zone || '',
    nomAffaire: affaireData?.nomAffaire || '',
    refAffaire: affaireData?.refAffaire || '',
    statut: affaireData?.statut || 'création',
    description: affaireData?.description || '',
    imgAffaire: affaireData?.imgAffaire || '',
    dateDebut: affaireData?.dateDebut || '',
    dateFin: affaireData?.dateFin || '',
    adresse: affaireData?.adresse || '',
    codePostal: affaireData?.codePostal || '',
    ville: affaireData?.ville || '',
    contactSurPlace: affaireData?.contactSurPlace || '',
    telContactSurPlace: affaireData?.telContactSurPlace || '',
    emailContactSurPlace: affaireData?.emailContactSurPlace || ''
  })
 
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === 'checkbox') {
        setAffaire(prevState => ({
            ...prevState,
            [name]: checked
        }));
    } else if (type === 'file') {
        // Assurez-vous d'utiliser files[0] pour obtenir le premier fichier si multiple n'est pas spécifié
        setAffaire(prevState => ({
            ...prevState,
            [name]: files[0]  // Stockez le fichier directement s'il est unique
        }));
    } else {
        setAffaire(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault()
    const imgUrl = await fileToKey(affaire.imgAffaire, storage, { path: 'seb' })
    console.log(affaire)
    onSubmit({
      ...affaire,
      imgAffaire: imgUrl
    })
  }

  return (
    <form onSubmit={handleSubmit} className="affaire-form">
      <div className="principal-affaire-form">
      <span>{"Nom de l'Affaire:"}</span>
      <input
        type="text"
        name="nomAffaire"
        placeholder="Nom de l'Affaire"
        value={affaire.nomAffaire}
        onChange={handleChange}
      />
      <span>Nom du Client:</span>
      <input
        type="text"
        name="client"
        placeholder="Nom du Client"
        value={affaire.client}
        onChange={handleChange}
      />
      <span>{"Réference de l'Affaire:"}</span>
      <input
        type="text"
        name="refAffaire"
        placeholder="Réference Affaire"
        value={affaire.refAffaire}
        onChange={handleChange}
      />
      <span>{"Déscription de l'affaire:"}</span>
      <input
        type="text"
        name="description"
        placeholder="Déscription de l'affaire"
        value={affaire.description}
        onChange={handleChange}
      />
      </div>
<div className="adress-affaire-form">
      <span>Adresse:</span>
      <input
        type="text"
        name="adresse"
        placeholder="adresse"
        value={affaire.adresse}
        onChange={handleChange}
      />
      <span>Code Postal:</span>
      <input
        type="text"
        name="codePostal"
        placeholder="Code Postal"
        value={affaire.codePostal}
        onChange={handleChange}
      />
      <span>Ville:</span>
      <input
        type="text"
        name="ville"
        placeholder="ville"
        value={affaire.ville}
        onChange={handleChange}
      />
      <span>Zone:</span>
      <input
        type="number"
        name="zone"
        placeholder="zone"
        min={1}
        max={5}
        value={affaire.zone}
        onChange={handleChange}
      />

      <label>Image Affaire:</label>
        <input type="file" name="imgAffaire" placeholder="Image" accept='image/*' onChange={handleChange} />

      </div>
      <div className="contact-sur-place-affaire-form">
      <span>Contact Sur place:</span>
            <input
        type="text"
        name="contactSurPlace"
        placeholder="Nom Prenom Contact Sur Place"
        value={affaire.contactSurPlace}
        onChange={handleChange}
      />

            <input
        type="tel"
        name="telContactSurPlace"
        placeholder="Numeros de Contact Sur Place"
        value={affaire.telContactSurPlace}
        onChange={handleChange}
      />
                  <input
        type="email"
        name="emailContactSurPlace"
        placeholder="Email Contact Sur Place"
        value={affaire.emailContactSurPlace}
        onChange={handleChange}
      />
      </div>

      <div className="date-affaire-form">
      <span>{"Etat de l'Affaire:"}</span>
      <select name="statut" value={affaire.statut} onChange={handleChange}>
        <option value="création">Création</option>
        <option value="en préparation">En préparation</option>
        <option value="en cours">En cours</option>
        <option value="terminé">Terminé</option>
        <option value="archivé">Archivé</option>
        <option value="Supprimer">Supprimer</option>
      </select>
      </div>
      <button type="submit">
        {isEdit ? 'Valider les modifications' : "Créer l'affaire"}
      </button>
    </form>
  )
}

export default AffaireForm
