import React from 'react'
import { Typography } from '@mui/material'
import './ligneTache.scss'

const LigneTache = ({
  key,
  tache,
  startDate,
  endDate,
  dayWidth,
  months,
  onTacheSelect
}) => {
  // Vérifier la validité des dates

  if (
    !(startDate instanceof Date && !isNaN(startDate)) ||
    !(endDate instanceof Date && !isNaN(endDate))
  ) {
    return (
      <Typography color="error">
        Données de date non valides pour la planification
      </Typography>
    )
  }
  // calcul du nombre de jour
  const monthNames = {
    janvier: 0,
    février: 1,
    mars: 2,
    avril: 3,
    mai: 4,
    juin: 5,
    juillet: 6,
    août: 7,
    septembre: 8,
    octobre: 9,
    novembre: 10,
    décembre: 11
  }

  const getDaysInMonth = (monthYear) => {
    const [month, year] = monthYear.split(' ')
    const monthIndex = monthNames[month.toLowerCase()]

    const date = new Date(year, monthIndex, 1)
    const days = []

    while (date.getMonth() === monthIndex) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }

    return days
  }

  const isWeekend = (date) => {
    const day = date.getDay()
    return day === 0 || day === 6 // Dimanche (0) ou Samedi (6)
  }
  // Calculer la position et la durée du surlignage

  const dateDebutTache = new Date(tache.dateDepart)
  const dateFinTache = new Date(tache.dateEcheance)

  // Vérifier que la tâche est dans la plage de dates
  if (dateFinTache < startDate || dateDebutTache > endDate) {
    return null // La tâche n'est pas dans la plage de dates du planning
  }

  // Calculez le nombre total de jours affichés sur le planning
  // const totalDays = (endDate - startDate) / (24 * 60 * 60 * 1000)

  // Calculez le décalage à partir de la date de début en pixels
  // const startDayOffset = (dateDebutTache - startDate) / (24 * 60 * 60 * 1000)
  // const leftOffset = startDayOffset * dayWidth // Convertissez en pixels

  // Calculez la durée de la tâche en pixels
  const durationInDays =
    (dateFinTache - dateDebutTache) / (24 * 60 * 60 * 1000)
  // const barWidth = durationInDays * dayWidth // Convertissez en pixels
  const isDayInTask = (date) => {
    const start = Date.UTC(dateDebutTache.getFullYear(), dateDebutTache.getMonth(), dateDebutTache.getDate());
    const end = Date.UTC(dateFinTache.getFullYear(), dateFinTache.getMonth(), dateFinTache.getDate());
    const currentDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  
    return currentDate >= start && currentDate <= end;
  }

  // Remonter l'information
  const handleClick = () => {
    onTacheSelect(tache._id)
  }

  // Calculez les segments pour chaque jour en fonction du pourcentage de réalisation
  const calculateWorkingDays = (start, end) => {
    let count = 0
    const currentDate = new Date(start)

    // eslint-disable-next-line no-unmodified-loop-condition
    while (currentDate <= end) {
      if (!isWeekend(currentDate)) {
        count++
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return count
  }

  const calculateSegmentColor = (date, tache) => {
    if (isWeekend(date)) return 'weekend'

    const dateDebutTache = new Date(tache.dateDepart)
    const dateFinTache = new Date(tache.dateEcheance)
    const totalWorkingDays = calculateWorkingDays(dateDebutTache, dateFinTache)

    // Appliquer une logique différente pour les tâches de moins de 4 jours
    if (totalWorkingDays <= 3) {
    // Utiliser la logique simplifiée pour les tâches courtes
      return calculateSegmentColorShortTask(date, tache)
    } else {
    // Utiliser la logique de tranche pour les tâches plus longues
      return calculateSegmentColorLongTask(date, tache, totalWorkingDays)
    }
  }

  const calculateSegmentColorShortTask = (date, tache) => {
    // const dayIndex = calculateWorkingDays(new Date(tache.dateDepart), date)
    const pourcentageRealisation = tache.pourcentageRealisation

    if (pourcentageRealisation >= 100) {
      return 'green' // Toute la tâche est verte à 100%
    } else if (pourcentageRealisation > 0) {
      return 'orange' // Toute tâche commencée mais non terminée est marquée comme en cours
    } else {
      return 'red' // Tâche non commencée
    }
  }

  const calculateSegmentColorLongTask = (date, tache, totalWorkingDays) => {
    const dayIndex = calculateWorkingDays(new Date(tache.dateDepart), date)
    const pourcentageRealisation = tache.pourcentageRealisation

    const trancheSize = totalWorkingDays / 5
    const trancheActuelle = Math.ceil(dayIndex / trancheSize)

    const tranchePourcentage = Math.ceil(pourcentageRealisation / 25)

    if (pourcentageRealisation >= 100) return 'green'
    if (pourcentageRealisation === 0) return 'red'

    if (trancheActuelle < tranchePourcentage) return 'green'
    if (trancheActuelle === tranchePourcentage) return 'orange'
    return 'red'
  }

  return (
    <div className="ligneTacheContainer-tachespl" onClick={handleClick}>
      <div className="barreTache-tachespl">
        {months.flatMap(monthYear => getDaysInMonth(monthYear)).map((date, index) => {
          const segmentColor = calculateSegmentColor(date, tache, durationInDays);
          const isInTask = isDayInTask(date);
          return (
            <div
              key={index}
              className={`jourTache-tachespl ${isInTask ? `highlight-tachespl ${segmentColor}` : ''} ${isWeekend(date) ? 'weekend-tachespl' : ''}`}
              style={{ width: `${dayWidth}px` }}
              // Ajout de l'attribut title pour les jours inclus dans la tâche
              title={isInTask ? tache.description : ''}
            />
          );
        })}
      </div>
    </div>
  )
}

export default LigneTache
