import { Card, CardMedia, CardContent, Typography } from '@mui/material'

const UserPresentation = ({ user }) => {
  const { nom, prenom, email, telephone, avatar, ...roles } = user

  const statuts = [
    { key: 'admin', label: 'Admin' },
    { key: 'chefAssignes', label: 'Chef de chantier' }
    // Ajoutez d'autres rôles ici selon le modèle ci-dessus
  ]

  const rolesActifs = statuts
    .filter(statut => roles[statut.key])
    .map(statut => statut.label)
    .join(' et ')

  const description = rolesActifs ? `${prenom} ${nom} est ${rolesActifs} dans la société.` : `${prenom} ${nom} travaille dans la société.`

  return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                height="140"
                image={avatar || '/default-profile.png'} // URL par défaut si avatar est vide
                alt={`${nom} ${prenom}`}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {prenom} {nom}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Email: {email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Téléphone: {telephone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
  )
}

export default UserPresentation
