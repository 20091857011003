import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

import PlanningAffaire from '../../components/planningAffaire/PlanningAffaire'
import useFetch from '../../hooks/useFetch'
import Affaireassignation from '../../components/Affaire/affaireassignation/Affaireassignation'
import axios from 'axios'
import './planningPage.scss'
import AffichageTachePlanningNew from '../../components/planningTachesNew/AffichageTachePlanningNew/AffichageTachePlanningNew'

const PlanningPage = () => {
  const { data: affairesdata, loading } = useFetch('/affaires')
  const [selectedAffaire, setSelectedAffaire] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [affairesFiltrees, setAffairesFiltrees] = useState([])
  // const [tachesChantier, setTachesChantier] = useState([])
  const [openDialog, setOpenDialog] = useState(false) // Pour contrôler l'ouverture/fermeture du dialogue
  const [showDetails, setShowDetails] = useState(false) // Pour contrôler l'affichage des détails

  useEffect(() => {
    if (affairesdata) {
      const statutsAutorises = ['création', 'en préparation', 'en cours', 'terminé', 'archivé', 'a entrer au planning', 'en attente', 'en pause'];
      const affairesFiltrees = affairesdata.filter(affaire => statutsAutorises.includes(affaire.statut));
      setAffairesFiltrees(affairesFiltrees);
    }
  }, [affairesdata]);

  useEffect(() => {
    if (selectedAffaire && selectedAffaire._id) {
      const fetchTaches = async () => {
        try {
          const response = await axios.get(`/affaires/tacheschantier/resume/${selectedAffaire._id}`)
          console.log('Tâches de chantier:', response.data.taches)
          // setTachesChantier(response.data.taches.filter(tache => tache.typeTache === 'Chantier'))
        } catch (error) {
          console.error('Erreur lors de la récupération des tâches de chantier:', error)
        }
      }

      fetchTaches()
    }
  }, [selectedAffaire])

 
  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  // boutton pour fermer les détails
  const handleCloseDetails = () => {
    setShowDetails(false) // Cache la partie des détails
  }

  // Lorsqu'une affaire est sélectionnée, ouvrez le dialogue
  const handleAffaireSelection = (affaireId) => {
    if (selectedAffaire && selectedAffaire._id === affaireId && showDetails) {
      // Si la même affaire est sélectionnée et que les détails sont déjà affichés,
      // on pourrait choisir de ne rien faire ou de fermer les détails et rouvrir la boîte de dialogue.
      // Cet exemple ferme les détails et rouvre la boîte de dialogue pour la même affaire.
      setShowDetails(false)
      setTimeout(() => setOpenDialog(true), 300) // Donne un peu de temps pour la transition
    } else {
      const affaireSelectionnee = affairesdata.find(affaire => affaire._id === affaireId)
      setSelectedAffaire(affaireSelectionnee)
      setShowDetails(false) // Assurez-vous de cacher les détails avant d'ouvrir la boîte de dialogue
      setOpenDialog(true)
    }
  }

  return (
    <div className="Planning">
            {/* Dialogue pour demander à l'utilisateur s'il souhaite voir les détails de l'affaire */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Voir les détails</DialogTitle>
        <DialogContent>
          {"Voulez-vous voir les détails de l'affaire"} {selectedAffaire?.nomAffaire} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Annuler</Button>
          <Button onClick={() => {
            setOpenDialog(false)
            setShowDetails(true) // Changez l'état pour montrer les détails
          }}>Voir les détails</Button>
        </DialogActions>
      </Dialog>

      <div className={`partieHautePlanning ${showDetails ? 'show' : 'hide'}`}>
      <div className="navPlanning">
        <div className="tabs">
          <div
            className={`tab ${selectedTab === 0 ? 'active' : ''}`}
            onClick={() => handleTabChange(0)}
          >
            Planning
          </div>
          <div
            className={`tab ${selectedTab === 1 ? 'active' : ''}`}
            onClick={() => handleTabChange(1)}
          >
            Assignation
          </div>
          <button className="closeButton" onClick={handleCloseDetails}>X</button>
        </div>
      </div>
        <div className="tabContentPlanning">
        {selectedTab === 0 && (
            <div className="ongletplanning4">
               {loading
                 ? (
                <p>Chargement en cours...</p>
                   )
                 : selectedAffaire
                   ? (
                <>
                  <h2>
                    {"Vous êtes sur le Planning de l'affaire"}{' '}
                    {selectedAffaire.nomAffaire}
                  </h2>
             <AffichageTachePlanningNew affaireId={selectedAffaire._id} />
             </>
                     )
                   : (
                <p>Cliquez sur une affaire pour afficher le planning</p>
                     )}
            </div>
        )}
        </div>
          {selectedTab === 1 && (
            <div className="ongletplanning3">
              {loading
                ? (
                <p>Chargement en cours...</p>
                  )
                : selectedAffaire
                  ? (
                <>
                  <h2>
                    {"Vous êtes entrain d'assigner l'affaire"}{' '}
                    {selectedAffaire.nomAffaire}
                  </h2>
                  <Affaireassignation affairedata={selectedAffaire} />
                </>
                    )
                  : (
                <p>Cliquez sur une affaire pour assigner</p>
                    )}
            </div>
          )}
      </div>
      <div className={`partiebasseplanning ${showDetails ? 'show' : 'fullHeight'}`}>
        <PlanningAffaire
          toutesLesAffaire={affairesFiltrees}
          onAffaireSelect={handleAffaireSelection}
        />
      </div>
    </div>
  )
}

export default PlanningPage
