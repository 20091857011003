// Users column
export const userColumns = [
  { key: 'nom', title: 'Nom', width: '20%' },
  { key: 'mail', title: 'Email', width: '30%' }
]

const viewUserProfile = (userId) => {
  // affiche le profil de l'utilisateur
}
const handleStatusChange = (e, itemId) => {}

const handleCheckboxChange = (checked, itemId) => {
  console.log(`Item ${itemId} is ${checked ? 'selected' : 'deselected'}`)
}
// Users action
export const userActions = [
  {
    type: 'button',
    text: 'Voir Profil',
    handler: (user) => viewUserProfile(user.id)
  },
  {
    type: 'select',
    options: ['admin', 'travaux', 'conduc'],
    handler: (event, user) => handleStatusChange(event.target.value, user.id)
  },
  {
    type: 'checkbox',
    handler: (checked, item) => handleCheckboxChange(checked, item.id)
  }
]

// Définition des colonnes pour les tâches
export const tacheColumns = [
  { key: 'nomTache', title: 'Nom de la Tâche', width: '40%' },
  { key: 'description', title: 'Description', width: '40%' }
]

// Définition des actions pour les tâches
