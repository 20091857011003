import './superAdmin.scss'
import useFetch from '../../hooks/useFetch'
import { format } from 'date-fns'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { AuthContext } from '../../context/AuthContext'
import { useContext, useEffect, useState } from 'react'
import CreateEntreprise from './createCompamy'
import axios from 'axios'

const superAdmin = () => {
  const { utilisateur } = useContext(AuthContext)

  const [entreprises, setEntreprises] = useState([])
  useEffect(() => {
    axios.get(`entreprises`).then((res) => {
      setEntreprises(res.data)
    })
  }, [])

  return <div className='superadmin-entreprises-container'>
    <div className='title'>
      <h1>Entreprises <span>({entreprises.length - 1})</span></h1>
      <CreateEntreprise mcallback={(data) => setEntreprises(prev => [...prev, data])} />
    </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{fontWeight: 600}}>Nom entreprise</TableCell>
            <TableCell align="left" sx={{fontWeight: 600}}>Adresse email</TableCell>
            <TableCell align="left" sx={{fontWeight: 600}}>Admin</TableCell>
            <TableCell align="left" sx={{fontWeight: 600}}>Nb affaires</TableCell>
            <TableCell align="left" sx={{fontWeight: 600}}>Nb utilisateurs</TableCell>
            <TableCell align="left" sx={{fontWeight: 600}}>Date de création</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entreprises.filter(entreprise => entreprise._id !== utilisateur.entreprise._id).map((entreprise) => (
            <TableRow
              key={entreprise._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{entreprise.nom}</TableCell>
              <TableCell align="left">{entreprise.mail}</TableCell>
              <TableCell align="left">{entreprise.utilisateurs.find(utilisateur => utilisateur.role === 'ADMIN') ? `${entreprise.utilisateurs.find(utilisateur => utilisateur.role === 'ADMIN')?.prenom} ${entreprise.utilisateurs.find(utilisateur => utilisateur.role === 'ADMIN')?.nom} (${entreprise.utilisateurs.find(utilisateur => utilisateur.role === 'ADMIN')?.email})` : '-'}</TableCell>
              <TableCell align="left">{entreprise.affaires.length}</TableCell>
              <TableCell align="left">{entreprise.utilisateurs.length}</TableCell>
              <TableCell align="left">{format(new Date(entreprise.createdAt), 'dd/MM/yyyy')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
}

export default superAdmin
