import TacheFormPlanning from '../tacheFormPlanning/TacheFormPlanning'
import Planning from '../../planning/Planning'
import './affichageTachePlanning.scss'
import { useState } from 'react'
import TacheAPrendre from '../tacheAPrendre/TacheAPrendre'
import TacheListCondencer from '../tacheListCondencer/TacheListCondencer'
import APrendreOneTacheId from '../aPrendreOneTacheId/APrendreOneTacheId'
import AssignationTacheId from '../assignationTacheId/AssignationTacheId'

const AffichageTachePlanning = ({ affaireId, affaireData, taches, reFetch }) => {
  const [tachesFiltrees, setTachesFiltrees] = useState([]) // Tâches après filtrage
  const [selectedTache, setSelectedTache] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)

  const handleEditClick = (tache) => {
    setSelectedTache(tache)
  }
  const handleTabChange = (index) => {
    setSelectedTab(index)
  }
  const updateFilteredTaches = (nouvellesTachesFiltrees) => {
    setTachesFiltrees(nouvellesTachesFiltrees)
  }

  const handleTacheSelection = (tacheId) => {
    const tacheSelectionnee = taches.find(tache => tache._id === tacheId)
    setSelectedTache(tacheSelectionnee)
  }

  console.log("tachesFiltrees", tachesFiltrees)
  console.log("taches", taches)
  return (
<div className="affichageTachePlanning">
  <div className="navaffichageTachePlanning">
    <div className="tabs">
      <div className={`tab ${selectedTab === 0 ? 'active' : ''}`} onClick={() => handleTabChange(0)}>Accueil</div>
      <div className={`tab ${selectedTab === 1 ? 'active' : ''}`} onClick={() => handleTabChange(1)}>À prendre</div>
      <div className={`tab ${selectedTab === 2 ? 'active' : ''}`} onClick={() => handleTabChange(2)}>Assignation</div>
      </div>
    </div>
    <div className="partieHaute">
    <div className="tabContent">
      {selectedTab === 0 && (
        <div className="onglet1">
          <div className="onglet1a">
            <TacheListCondencer taches={taches} onEdit={handleEditClick} onUpdateFilteredTaches={updateFilteredTaches} />
            </div>
            <div className="onglet1b">
            <TacheFormPlanning affaireId={affaireId} selectedTache={selectedTache} refreshTaches={reFetch} />
            </div>
          </div>
      )}
      {selectedTab === 1 && (
      <div className="onglet2">
        <div className="onglet2a">
      <TacheAPrendre affaireId={affaireId}/>
      </div>
      <div className="onglet2b">
        <APrendreOneTacheId tache={selectedTache} reFetch={reFetch} />
      </div>
      </div>
      )}
      <div className="onglet3">
      {selectedTab === 2 && <AssignationTacheId tache={selectedTache}/>}
      </div>
    </div>
  </div>

  <div className="partieBasse">
    <Planning mode="tache" toutesLesTaches={tachesFiltrees} onTacheSelect={handleTacheSelection}/>
  </div>
</div>

  )
}

export default AffichageTachePlanning
