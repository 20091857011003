import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthContextProvider } from './context/AuthContext'
import { FirebaseProvider } from './context/Firebase'
import moment from 'moment'
import 'moment/locale/fr'
import axios from 'axios'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import fr from 'date-fns/locale/fr'

moment.locale('fr')

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const userAgent = navigator.userAgent.toLowerCase()
const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)

const newIpad = /Macintosh/i.test(navigator.userAgent) && screen.width < 1500

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isTablet || newIpad) {
  window.location = `${process.env.REACT_APP_PHONE_URL}${location.pathname}${window.location.search}`
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2f4b52',
      light: '#99bbab',
      dark: '#618587',
      contrastText: '#f7f0cf'
    },
    action: {
      main: '#618587',
      light: '#2f4b52',
      dark: '#2f4b52',
      contrastText: '#f7f0cf'
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <FirebaseProvider>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthContextProvider>
    </FirebaseProvider>
  </React.StrictMode>
)
