import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { List, ListItem, ListItemText, TextField, Checkbox, ListItemSecondaryAction, FormControlLabel } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import './userList.scss'

const UserList = () => {
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState({
    admin: false,
    client: false
    // ... autres filtres booléens ...
  })
  const navigate = useNavigate()

  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/utilisateurs')
      setUsers(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs', error)
    }
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleFilterChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.checked })
  }

  const filteredUsers = users.filter(user =>
    user.nom.toLowerCase().includes(searchTerm.toLowerCase()) &&
        Object.keys(filter).every(key => !filter[key] || user[key])
  )

  const handleUserClick = (userId) => {
    navigate(`/users/${userId}`)
  }

  return (
        <div>
            <TextField
                label="Rechercher"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{ endAdornment: <SearchIcon /> }}
            />
            {/* Filtres */}
            {Object.keys(filter).map(key => (
                <FormControlLabel
                    key={key}
                    control={<Checkbox checked={filter[key]} onChange={handleFilterChange} name={key} />}
                    label={key}
                />
            ))}
            <List>
                {filteredUsers.map(user => (
                    <ListItem key={user._id} button onClick={() => handleUserClick(user._id)}>
                        <ListItemText primary={`${user.nom} ${user.prenom}`} secondary={user.email} />
                        <ListItemSecondaryAction>
                            {user.telephone}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </div>
  )
}

export default UserList
