import { useState } from 'react'
import './genericList.scss'

const GenericList = ({ data, columns, actions }) => {
  const [searchTerm, setSearchTerm] = useState('')

  // filtre
  const filterData = (data) => {
    if (!searchTerm) return data
    return data.filter((item) =>
      columns.some((column) =>
        item[column.key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    )
  }

  const filteredData = filterData(data)

  // actions
  const renderAction = (action, item) => {
    switch (action.type) {
      case 'button':
        return (
        <button onClick={() => action.handler(item)}>
          {action.text}
        </button>
        )
      case 'select':
        return (
          <select onChange={(e) => action.handler(e, item)}>
            {action.options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        )
      case 'checkbox':
        return (
              <input
                type="checkbox"
                onChange={(e) => action.handler(e.target.checked, item)}
              />
        )
      default:
        return null
    }
  }

  return (
    <div className="generic-list">
      <div className="search-container">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="header-row">
        {columns.map((col) => (
          <div key={col.key} className="column" style={{ width: col.width }}>
            {col.title}
          </div>
        ))}
        {actions && <div className="actions-column">Actions</div>}
      </div>
      {filteredData.map((item, index) => (
        <div key={index} className="data-row">
          {columns.map((col) => (
            <div key={col.key} className="column" style={{ width: col.width }}>
              {item[col.key]}
            </div>
          ))}
          {actions && (
           <div className="actions-column">
           {actions.map((action, idx) => (
             <div key={idx} className="action-item">
               {renderAction(action, item)}
             </div>
           ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default GenericList
