import Tooltip from '@mui/material/Tooltip';
import BusinessIcon from '@mui/icons-material/Business';
import './affaireRow.scss';
import AffaireSuivreSwitch from '../Affaire/affaireSuivreSwitch/AffaireSuivreSwitch';

const AffaireRow = ({ id, nomAffaire, ville, description, onDetailClick, imgAffaire, onClick, refAffaire, affaireData }) => {
  
  // Gestionnaire de clic pour la cellule contenant le switch et le bouton "Détails"
  // qui empêche la propagation de l'événement de clic au parent <tr>
  const handleSwitchClick = (e) => {
    e.stopPropagation();
  };

  return (
    <tr className="affaire-row" onClick={() => onClick(id)}>
      <Tooltip title={description} arrow placement="right-start" followCursor>
        <td className='img-affaire-row'>
          {imgAffaire ?
            <img src={imgAffaire} alt={nomAffaire} style={{ width: '100px', height: '55px' }} />
            : <BusinessIcon className='img-icon-affaire-row'/>
          }
        </td>
        <td className="detail-affaire-row">{nomAffaire}</td>
        <td className="detail-affaire-row">{ville.toUpperCase()}</td>
        <td className="detail-affaire-row">{refAffaire}</td>
      </Tooltip>
      <td className="btn-affaire-row" onClick={handleSwitchClick}>
        <div className="btn-container">
          <AffaireSuivreSwitch affaireData={affaireData} />
          <button onClick={(e) => { e.stopPropagation(); onDetailClick && onDetailClick(id); }}>Détails</button>
        </div>
      </td>
    </tr>
  );
};

export default AffaireRow;