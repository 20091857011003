import { useContext, cloneElement } from 'react'
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import SettingsIcon from '@mui/icons-material/Settings'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SupportIcon from '@mui/icons-material/Support'
import './sidebar.scss'
import { AuthContext } from '../../context/AuthContext'

const Sidebar = ({ menu, currentId, onMenuItemClick }) => {
  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()

  // animation
  const slideFromLeftVariants = {
    hidden: {
      opacity: 1
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07
      }
    }
  }
  const listItemVariants = {
    hidden: {
      x: '-5vw',
      opacity: 0
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 60,
        damping: 10
      }
    }
  }

  const handleItemClick = (item, event) => {
    if (item.action) {
      event.preventDefault()
      item.action() // Appelle directement la fonction action
    }
  }

  const support = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      (window).$crisp.push(['do', 'chat:show']);
      (window).$crisp.push(['do', 'chat:open'])
    } else {
      alert('Sorry, only available in production')
    }
  }

  // Pour Delogue
  const handleLogout = () => {
    logout()
    // Efface l'utilisateur du local storage
    localStorage.removeItem('utilisateur')
    navigate('/')
  }
  return (
    <div className="sidebar">
      <div className="search">
        <SearchIcon className="searchimg" />
        <input
          type="text"
          placeholder="Recherche..."
          className="custom-input"
        />
      </div>
      <div className="onglet">
        <motion.div
          className="navtop"
          initial="hidden"
          animate="visible"
          variants={slideFromLeftVariants}
        >
          <ul>
            {menu.map((item) => {
              const path = item.link.replace(':id', currentId || '')
              return (
                <Link
                  to={path}
                  key={item.id}
                  style={{ textDecoration: 'none' }}
                  onClick={(e) => handleItemClick(item, e)}
                >
                  <motion.li
                    variants={listItemVariants}
                    className={item.isActive ? 'active' : ''}
                  >
                    {cloneElement(item.img, { className: 'img' })}
                    <span>{item.name}</span>
                  </motion.li>
                </Link>
              )
            })}
          </ul>
        </motion.div>
        <div className="empty-slot"></div>
        <div className="navbottom">
          <ul>
            <li onClick={support}>
              <SupportIcon className="img" />
              <span>Assistance</span>
            </li>
            <li>
              <HelpOutlineIcon className="img" />
              <span>Info</span>
            </li>
            <Link to="/reglages" style={{ textDecoration: 'none' }}>
              <li>
                <SettingsIcon className="img" />
                <span>Réglages</span>
              </li>
            </Link>
            <li onClick={handleLogout}>
              <PowerSettingsNewIcon className="img" />
              <span>Déconexion</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
