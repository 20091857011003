import { useLocation } from 'react-router-dom'
import Card from '../../components/card/Card'
import Sidebar from '../../components/sidebar/Sidebar'
import { usersMenu } from '../../variables/sidebar/topMenu'
import './users.scss'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
// import GenericList from '../../components/modules/genericList/GenericList'
// import {
//   userColumns,
//   userActions
// } from '../../components/modules/genericList/configGenericList'
// import { useState } from 'react'
import { Snackbar } from '@mui/material'
import Modal from '../../components/modules/Modal/Modal'
import useModal from '../../components/modules/Modal/useModal'
import useSnackbar from '../../hooks/useSnackbar'
import UserForm from '../../components/User/userForm/UserForm'
import useFetch from '../../hooks/useFetch'
import axios from 'axios'
// import UserList from '../../components/User/userList/UserList'

const Users = () => {
  const location = useLocation()
  const { isModalOpen, openModal, closeModal } = useModal()
  const { open, message, showSnackbar, hideSnackbar } = useSnackbar()
  const path = location.pathname.split('/')[1]
  const { data: users, reFetch } = useFetch('/utilisateurs')
  // const [etatFiltre, setEtatFiltre] = useState(null)

  const handleCreateUser = async (newUserData) => {
    try {
      await axios.post('/utilisateurs', newUserData)
      await reFetch()
      closeModal()
      showSnackbar('Utilisateur créé avec succès')
    } catch (error) {
      console.error("Erreur lors de la création de l'Utilisateur", error)
      showSnackbar("Erreur lors de la création de l'Utilisateur")
    }
  }

  // render Card
  const handleCardClick = (id) => {
    window.location.href = `/${path}/${id}`
  }

  // clear les filtres users
  const clearFilter = () => {
    // setEtatFiltre(null)
  }

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' })
  }

  // const renderCardList = () => {
  //   return (
  //     users &&
  //     users.map((item) => (
  //       <Card
  //         key={item._id}
  //         id={item._id}
  //         title={item.nom}
  //         subtitle={item.prenom}
  //         image={
  //           item.avatar
  //             ? (
  //             <img src={item.avatar} alt={item.nom} />
  //               )
  //             : (
  //             <PermIdentityIcon />
  //               )
  //         }
  //         description={item.desc}
  //         onClick={handleCardClick}
  //       />
  //     ))
  //   )
  // }

  // laisse ca ici pour le moment
  const menu = usersMenu(openModal, clearFilter)
  return (
    <div className="users">
      <div className="container-users">
        <div className="sidebar">
          <Sidebar menu={menu} />
        </div>
        <div className="containerusers">
          <div className="left">
            <Modal
              title="Créer Nouvel Utilisateur"
              content={<UserForm onSubmit={handleCreateUser} isEdit={false} />}
              isOpen={isModalOpen}
              onClose={closeModal}
            />
            <div className="header">
              {[
                { id: 'responsable', label: 'Responsable' },
                { id: 'bureauAssignes', label: 'Bureau' },
                { id: 'chiffrageAssignes', label: 'Chiffrage' },
                { id: 'conducAssignes', label: 'Conducteur' },
                { id: 'chefAssignes', label: 'Chef' },
                { id: 'ouvrierTerrain', label: 'Ouvrier' }
              ].map(({ id, label }) => (
                <h3 key={id} onClick={() => scrollToSection(id)}>
                  {label}
                </h3>
              ))}
            </div>
            <div className="sectionrolePermissions">
              {[
                { id: 'responsable', label: 'Responsable' },
                { id: 'bureauAssignes', label: 'Bureau' },
                { id: 'chiffrageAssignes', label: 'Chiffrage' },
                { id: 'conducAssignes', label: 'Conducteur' },
                { id: 'chefAssignes', label: 'Chef' },
                { id: 'ouvrierTerrain', label: 'Ouvrier' }
              ].map(({ id, label }) => (
                <div id={id} key={id} className="roleSection">
                  <h2 className="status-section-h2">{label}</h2>
                  <div className="cardsContainer">
                    {users
                      .filter((user) => user[id]) // Remplacement de 'role' par 'id'
                      .map((user) => (
                        <Card
                          key={user._id}
                          title={user.nom}
                          subtitle={user.prenom}
                          image={
                            user.avatar
                              ? (
                              <img src={user.avatar} alt={user.nom} />
                                )
                              : (
                              <PermIdentityIcon />
                                )
                          }
                          description={user.desc}
                          onClick={() => handleCardClick(user._id)}
                        />
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={hideSnackbar}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  )
}

export default Users
