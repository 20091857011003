import React, { useEffect, useState } from 'react';
import { format, addDays, subDays } from 'date-fns';
import ExpiringFilesRecap from '../components/ExpiringFilesRecap';
import TachesNonCommence from '../components/TachesNonCommence';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ListAltIcon from '@mui/icons-material/ListAlt'
import HandymanIcon from '@mui/icons-material/Handyman'
import './affichageWidgetRecap.scss';
import PlanningJour from '../components/PlanningJour';

const AffichageWidgetRecap = () => {
    const [selectedDate, setSelectedDate] = useState(new Date()); // Gérer la date sélectionnée
    const [activeTab, setActiveTab] = useState('expiringFiles');
    const [animateChange, setAnimateChange] = useState(false);

    useEffect(() => {
        if (animateChange) {
          const timer = setTimeout(() => {
            setAnimateChange(false);
          }, 300); // Durée de l'animation en ms
          return () => clearTimeout(timer);
        }
      }, [animateChange]);

    const handleNextDay = () => {
        setSelectedDate(prev => addDays(prev, 1));
        setAnimateChange(true); // Activer l'animation
      };
      
      const handlePrevDay = () => {
        setSelectedDate(prev => subDays(prev, 1));
        setAnimateChange(true); // Activer l'animation
      };
  
    const formatDate = (date) => format(date, 'dd/MM/yy'); // Format de la date
  
    // Fonction pour rendre le contenu de l'onglet actif
    const renderTabContent = () => {
      switch (activeTab) {
        case 'expiringFiles':
            return <ExpiringFilesRecap date={selectedDate} />;
        case 'tab2':
          return <TachesNonCommence />;
        case 'tab3':
            return <PlanningJour selectedDate={selectedDate}/>;
        default:
          return <div>Select a tab</div>;
      }
    };
  
    return (
      <div className="AffichageWidgetRecap">
        <div className="date-selector-AffichageWidgetRecap">
            <button
            onClick={handlePrevDay}
            className={animateChange ? 'highlight-animate' : ''}
            >←</button>
            <span className={animateChange ? 'highlight-animate' : ''}>
            {formatDate(selectedDate)}
            </span>
            <button
            onClick={handleNextDay}
            className={animateChange ? 'highlight-animate' : ''}
            >→</button>
        </div>
        <div className="tabs-AffichageWidgetRecap">
        <button
                  className={activeTab === 'expiringFiles' ? 'active' : ''}
                  onClick={() => setActiveTab('expiringFiles')}
                >
                  <FolderOpenIcon />
                </button>
                <button
                  className={activeTab === 'tab2' ? 'active' : ''}
                  onClick={() => setActiveTab('tab2')}
                >
                  <HandymanIcon />
                </button>
                <button
                  className={activeTab === 'tab3' ? 'active' : ''}
                  onClick={() => setActiveTab('tab3')}
                >
                  <ListAltIcon />
                </button>
        </div>
        <div className="content-AffichageWidgetRecap">
          {renderTabContent()}
        </div>
      </div>
    );
  };
  
export default AffichageWidgetRecap